import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { find } from 'lodash';
import { LANGUAGES } from '@core/constants/constants';
import { TranslationService } from '@core/services/TranslationService';
import { StorageEnum } from '@core/constants/storage.enum';
import { StorageService } from '@core/services/StorageService';

@Component({
  selector: 'mb-language-menu',
  templateUrl: './language-menu.component.html',
  styleUrls: ['./language-menu.component.scss'],
})
export class LanguageMenuComponent implements OnInit {

  @Output() langChangeEvent = new EventEmitter<string>();

  public languages: ILanguages[];
  public selectedLang: ILanguages;

  constructor(
    private translationService: TranslationService,
    private storage: StorageService,
  ) {}

  ngOnInit(): void {
    this.translationService.setLanguagePreference();

    this.languages = [
      LANGUAGES.nb,
      LANGUAGES.en,
      LANGUAGES.se,
      LANGUAGES.dk
    ].map(lang => {
      const code = this.getLangugaeCode(lang);
      return {
        name: lang.name,
        base: lang.codes.base,
        code,
      };
    });

    this.selectedLang =
      find(this.languages, { code: this.translationService.getCurrentLanguage() }) ||
      find(this.languages, { base: this.translationService.getCurrentLanguage() });
  }

  changeLanguage(langauge: ILanguages) {
    this.selectedLang = langauge;
    this.translationService.setLanguage(this.selectedLang.code);
    this.langChangeEvent.emit(this.selectedLang.code);
  }

  getLangugaeCode(lang: {name: string; codes: any}) {
    const appInfo = this.storage.getItem(StorageEnum.AppInfo);
    return appInfo?.enableClientLocalication ? `${lang.codes.base}${appInfo?.clientLocalicationCode}` : lang.codes.base;
  }
}

export interface ILanguages {
  name: string;
  base: string;
  code: string;
}
