import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { CoreModule } from "@core/core.module";
import { ComponentsModule } from "@components/components.module";
import { ApplicationLayoutComponent } from "./layouts/application-layout/application-layout.component";
import { RoutingConfigurationModule } from "@config/routing";
import {
  TranslateModule,
  TranslateLoader,
  TranslateService,
} from "@ngx-translate/core";
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from "@angular/common/http";
import { HttpRequestInterceptor } from "@core/services/HttpRequestInterceptor";
import { PageModule } from "./pages/page.module";
import { TranslationLoader } from "@core/services/TranslationLoader";
import { RootLayoutComponent } from "./layouts/root-layout/root-layout.component";
import { MatPaginatorIntl } from "@angular/material/paginator";
import { PaginatorI18n } from "@config/paginator-intl";
import { MatMenuModule } from "@angular/material/menu";
import { PdfViewerModule } from 'ng2-pdf-viewer';

@NgModule({
  declarations: [AppComponent, ApplicationLayoutComponent, RootLayoutComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    PageModule,
    ComponentsModule,
    RoutingConfigurationModule,
    HttpClientModule,
    MatMenuModule,
    PdfViewerModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: function HttpLoaderFactory(http: HttpClient) {
          return new TranslationLoader(http);
        },
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptor,
      multi: true,
    },
    {
      provide: MatPaginatorIntl,
      deps: [TranslateService],
      useFactory: (translateService: TranslateService) =>
        new PaginatorI18n(translateService).getPaginatorIntl(),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
