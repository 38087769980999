import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { DebounceDirective } from '@core/directives/debounce.directive';
import { InputAutoFocusDirective } from '@core/directives/input-autofocus.directive';
import { TranslateModule } from '@ngx-translate/core';
import { NgOtpInputModule } from 'ng-otp-input';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { DateMonthPickerComponent } from './date-month-picker/date-month-picker.component';
import { DialogComponent } from './dialog/dialog.component';
import { FilePreviewModalComponent } from './file-preview-modal/file-preview-modal.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { FormInputComponent } from './form-input/form-input.component';
import { LanguageMenuComponent } from './language-menu/language-menu.component';
import { OTPInputComponent } from './otp-input/otp-input.component';
import { SpinnerComponent } from './spinner/spinner.component';

@NgModule({
  declarations: [
    OTPInputComponent,
    LanguageMenuComponent,
    SpinnerComponent,
    ChangePasswordFormComponent,
    FileUploadComponent,
    FormInputComponent,
    DateMonthPickerComponent,
    BreadcrumbComponent,
    DialogComponent,
    FilePreviewModalComponent,
    DebounceDirective,
    InputAutoFocusDirective,
  ],
  imports: [
    CoreModule,
    TranslateModule,
    NgOtpInputModule,
  ],
  exports: [
    OTPInputComponent,
    LanguageMenuComponent,
    SpinnerComponent,
    ChangePasswordFormComponent,
    FileUploadComponent,
    FormInputComponent,
    DateMonthPickerComponent,
    BreadcrumbComponent,
    DialogComponent,
    FilePreviewModalComponent,
    DebounceDirective,
    InputAutoFocusDirective,
  ],
})
export class ComponentsModule {
}
