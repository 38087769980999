import { Directive, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';

@Directive({
  selector: '[mbDebouncedInput]',
})
export class DebounceDirective implements OnInit, OnDestroy {
  private change = new Subject();
  @Output() debouncedChange = new EventEmitter();
  @Input() debounceTime: number;
  
  public debounceSubscription: Subscription;

  constructor() { }

  ngOnInit() {
    this.debounceSubscription = this.change.pipe(debounceTime(this.debounceTime))
      .pipe(distinctUntilChanged()).subscribe((event: any) => {
        this.debouncedChange.emit(event);
      });
  }

  @HostListener('keyup', ['$event'])
  changeEvent(event: any) {
      this.change.next(event.target.value);
  }

  ngOnDestroy() {
    this.debounceSubscription.unsubscribe();
  }
}
