<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Shiba Inu</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>staffing works!</p>

    <p>
      The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
      A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
      bred for hunting.
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions>
</mat-card>