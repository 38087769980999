<div mat-dialog-title class="text-right">
  <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
</div>
<div mat-dialog-content>
  <h3 class="text-center m-t-0">{{ 'PROFILE.GDPR.TITLE' | translate }}</h3>
  <p>{{ 'PROFILE.GDPR.INFO_MESSAGE' | translate }}</p>
  <p>{{ 'PROFILE.GDPR.INFO_MESSAGE_1' | translate }}</p>
  <p>{{ 'PROFILE.GDPR.INFO_MESSAGE_2' | translate }}</p>
  <div class="justify-content-center text-center">
    <button (click)="close()" mat-raised-button color="primary" class="modal-btn" type="button">
      {{ 'GENERAL.OK' | translate }}
    </button>
  </div>
</div>