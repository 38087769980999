import { Injectable } from '@angular/core';
import { CompetenceStatusEnum, ICompetence } from '../competence.model';

@Injectable()
export class CompetenceStructureService {

  constructor() { }

  showWarnIcon(competence: ICompetence) {
    return competence.iconColor === CompetenceStatusEnum.Yellow || competence.iconColor === CompetenceStatusEnum.Red;
  }

  showApprovedIcon(competence: ICompetence) {
    return competence.iconColor === CompetenceStatusEnum.Green;
  }
}
