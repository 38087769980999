<div class="login-register">
  <div class="login-register-box error-card text-center">
    <h1>404</h1>
    <h3 class="text-uppercase">{{'VALIDATION_MSG.PAGE_NOT_FOUND' | translate}}</h3>
    <a
      mat-raised-button
      color="primary"
      [routerLink]="['/']"
      class="btn btn-info btn-rounded waves-effect waves-light m-b-40"
      >{{'GENERAL.BACK_TO_HOME' | translate}}</a
    >
  </div>
</div>
