<div class="bg-white h-100">
  <div class="progressbar-wrapper b-b">
    <mat-progress-bar mode="determinate" value="50"></mat-progress-bar>
  </div>

  <div class="page-content p-20">
    <form [formGroup]="form">
      <mat-label> {{ 'GENERATOR.SELECT_DOCUMENT_TYPE' | translate }} </mat-label>
      <mat-radio-group class="message-radio-group" [formControl]="form.controls['messageType']">
        <div *ngFor="let type of messageTypes">
          <mat-radio-button class="message-radio-button" [value]="type.value">
            {{type.label | translate }}
          </mat-radio-button>
        </div>
      </mat-radio-group>

      <div class="submit-btn align-items-center justify-content-end">
        <button 
        mat-stroked-button
        color="primary"
        class="outline-btn"
        type="button"
        (click)="submit()">
          Next
        </button>
      </div>
    </form>
  </div>
</div>