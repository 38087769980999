import { MediaMatcher } from '@angular/cdk/layout';
import { AfterViewInit, ChangeDetectorRef, Component, HostListener, NgZone, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { Router, Event as NavigationEvent, NavigationEnd, ActivatedRoute } from '@angular/router';
import { StorageEnum } from '@core/constants/storage.enum';
import { SessionService } from '@core/services/auth/session.service';
import { ImageService } from '@core/services/ImageService';
import { StorageService } from '@core/services/StorageService';
import { TranslationService } from '@core/services/TranslationService';
import { filter, Subscription } from 'rxjs';
import { DashboardService } from '@modules/Dashboard/services/dashboard.service';
import { IPermissionModel, MODULEINFO, Modules } from '@core/models/modules.model';
import { ProfileService } from '@modules/Profile/services/profile.service';
import { MatDrawer } from '@angular/material/sidenav';
import { PermissionService } from '@core/services/PermissionService';
import { find } from 'lodash';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'mb-application-layout',
  templateUrl: './application-layout.component.html',
  styleUrls: ['./application-layout.component.scss'],
})

export class ApplicationLayoutComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('snav') snav: MatDrawer;
  @ViewChild(MatAccordion) accordion: MatAccordion;

  public profileMenuOption = [
    { name: 'MAIN_MENU.SETTINGS', icon: 'settings' },
    { name: 'MAIN_MENU.PROFILE', icon: 'account_box' },
    { name: 'GENERAL.SIGN_OUT', icon: 'exit_to_app' },
  ];

  public menus: IPermissionModel[];
  public collectorMenus: IPermissionModel[];

  public sidebarOpened = false;
  public isDashboardPage = true;

  public mobileQuery: MediaQueryList;
  public profileImage: SafeUrl;
  public currentPageTitle: string;
  public arrangementImg: string;
  public routeSubscription: Subscription;
  public arrangementProfileSubscription: Subscription;
  public userProfileImgSubscription: Subscription;
  public dashboardModuleSubscription: Subscription;
  public innerWidth = window.innerWidth;

  private _mobileQueryListener: () => void;
  private arrangementId = '';
  private mobileScreenWidth: number =  991;
  public colletctorId: number;
  public modules = Modules;

  constructor(
    private translationService: TranslationService,
    private sessionService: SessionService,
    private storage: StorageService,
    private imageService: ImageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dashboardService: DashboardService,
    private profileService: ProfileService,
    private location: Location,
    private permissionService: PermissionService,
    private ngZone: NgZone,

    public dialog: MatDialog,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 991px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: UIEvent) {
    const width = event.target as Window;
    if (width.innerWidth > this.mobileScreenWidth) { //
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.snav.open();
        }, 5);
      });
    }

    if (width.innerWidth < this.mobileScreenWidth && this.router.url !== '/dashboard') { 
      this.ngZone.runOutsideAngular(() => {
        setTimeout(() => {
          this.snav.close();
        }, 5);
      });
    }
  }

  ngOnInit() {
    this.currentPageTitle = this.activatedRoute.snapshot.firstChild?.data.title;
    this.isDashboardPage = this.router.url === '/dashboard';
    this.routeSubscription = this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
    )
    .subscribe((event: NavigationEvent) => {
      if (event instanceof NavigationEnd) {
        window.scrollTo(0, 0);

        this.isDashboardPage = event.url === '/dashboard';
        if (this.innerWidth > this.mobileScreenWidth && this.mobileQuery.matches) {
          this.snav.open();
        }

        if(this.innerWidth < this.mobileScreenWidth && !this.isDashboardPage) {
          this.snav.close();
        }
      }
      let route = this.getChild(this.activatedRoute);

      route.data.subscribe(data => {
        this.currentPageTitle = data.title;
      });
    });

    this.activatedRoute.queryParams.subscribe(route=>{
      this.colletctorId = route?.id ?? 0;
    })

    this.arrangementProfileSubscription = this.dashboardService.arrangementProfileImgSub.subscribe(data => {
      this.arrangementImg = data;
    });

    this.userProfileImgSubscription = this.profileService.userProfileImgSub.subscribe(data => {
      this.profileImage = data;
    })

    this.dashboardModuleSubscription = this.dashboardService.dashboardModuleSub.subscribe(data => {
      this.menus = data;
    });

    this.dashboardModuleSubscription = this.dashboardService.collectordashboardModuleSub.subscribe(data => {
      this.collectorMenus = data;
    });

    const user = this.storage.getItem(StorageEnum.User);
    this.profileImage = this.getImage(user.fileUrl);
    this.arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
  }

  ngAfterViewInit() {
    if (this.innerWidth > this.mobileScreenWidth && this.mobileQuery.matches) {
      this.snav.open();
    }

    if(this.innerWidth < this.mobileScreenWidth && !this.isDashboardPage) {
      this.snav.close();
    }
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    } 
  }

  navigateToModule(module: IPermissionModel) {
    this.sessionService.navigateToModule(module);
  }

  getModuleIcon(type: Modules) {
    return MODULEINFO.find(ele => ele.type === type)?.icon;
  }

  getImage(image?: string) {
    return this.imageService.loadImage(image);
  }

  getPageTitle(url: string) {
    this.currentPageTitle = url.substring(1);
  }

  onLanguageChange(langCode: string) {
    const userId = this.storage.getItem(StorageEnum.UserId);
    const arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    this.translationService.setLanguage(langCode);
    this.translationService.setTranslationLanguage(userId, arrangementId, langCode);
  }

  openProfilePage() {
    const user = this.storage.getItem(StorageEnum.User);
    this.router.navigate(['/user-profile', user.userId], { state: { profileInfo: user } });
  }

  goToCompetencePage() {
    this.router.navigate(['competence']);
  }

  goToDashboard() {
    this.router.navigate(['dashboard']);
  }

  goToPreviousPage() {
    this.accordion.closeAll()
    if (this.router.url.split('/')[2]) {
      this.location.back();
    } else {
      this.goToDashboard();
    }
  }

  logout() {
    this.sessionService.logout();
  }
  
  hasPermission(type:string) {
    return this.permissionService.hasPermission(type);
  }
  
  findArrangementById() {
    const arrangementList = this.storage.getItem(StorageEnum.ArrangementList);
    return find(arrangementList, ele => ele.arrangementId === this.arrangementId) || null;
  }

  isLinkVisible() {
    return this.findArrangementById()?.userRegisterArrangementLinkActive;
  }

  goToRegisterArrangementPage() {
    const redirectLink = this.findArrangementById()?.userRegisterArrangementLink;
    if (redirectLink) {
      window.open(redirectLink, '_self');
    }
  }

  getRouteId(menu: IPermissionModel){
    if(menu?.type) {
      this.colletctorId = menu.type;
    }
  }

  hasPermissionFlag(model: IPermissionModel) {
    switch(model.type) {
      case Modules.LASTYEARCOLLECTOR:
        return this.hasPermission('lastYearCollectorTabView');    
        break;
      case Modules.THISYEARCOLLECTOR:
        return this.hasPermission('thisYearCollectorTabView');    
        break;
      case Modules.SENDMESSAGECOLLECTOR:
        return this.hasPermission('sendMessageCollectorView');    
        break;
      case Modules.MEETINGPLACESCOLLECTOR:
        return this.hasPermission('mettingPlacesCollectorView');    
        break;
      default:
        return true;
        break;
    }
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
    this.arrangementProfileSubscription.unsubscribe();
    this.userProfileImgSubscription.unsubscribe();
    this.dashboardModuleSubscription.unsubscribe();
  }
}
