import { Injectable } from '@angular/core';
import { IServerResponse } from '@core/core.interface';
import { HttpService } from '@core/services/HttpService';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { IMailList, IMessageListParams, IMailCountList, IMailDetails, IDocumentReceiverList, IRoleList,  ICreateMessage, ReminderMessageTemplate } from '../message.model';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  public addClass = true;

  public selectedMail = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpService,
  ) { }

  createFormData(object: Object, form?: FormData, namespace?: string): FormData {
    const formData = form || new FormData();
    for (let property in object) {
      if (!object.hasOwnProperty(property) || !object[property]) {
        continue;
      }
      const formKey = namespace ? `${namespace}[${property}]` : property;
      if (object[property] instanceof Date) {
        formData.append(formKey, object[property].toISOString());
      } else if (typeof object[property] === 'object' && !(object[property] instanceof File)) {
        this.createFormData(object[property], formData, formKey);
      } else {
        formData.append(formKey, object[property]);
      }
    }
    return formData;
  }

  async getMailsByType(arrangementId: number, userId: number, model: IMessageListParams) {
    return await firstValueFrom(this.http.get(`Message/GetMessages/${arrangementId}/${userId}`, model)) as IServerResponse<IMailList[]>;
  }

  async getMessageTypeCount(arrangementId: number, userId: number) {
    return await firstValueFrom(this.http.get(`Message/GetMessageTypeCount/${arrangementId}/${userId}`)) as IServerResponse<IMailCountList[]>;
  }

  async getMailDetails(id: string) {
    return await firstValueFrom(this.http.get(`Message/GetSingleMessage/${id}`)) as IServerResponse<IMailDetails>;
  }

  async getSenderDetails(arrangementId: number) {
    return await firstValueFrom(this.http.get(`DocumentReceiver/GetSender/${arrangementId}`)) as IServerResponse<any>;
  }

  async getReceiverDetails(arrangementId: number) {
    return await firstValueFrom(this.http.get(`DocumentReceiver/GetReceiver/${arrangementId}`)) as IServerResponse<IDocumentReceiverList[]>;
  }

  async getSectionRole(arrangementId: number,selectionId: number) {
    return await firstValueFrom(this.http.get(`Role/GetSectionRole/${arrangementId}/${selectionId}`)) as IServerResponse<IRoleList[]>;
  }

  async createMessage(model:FormData, params: { arrangementId: number, typeId: number}) {
    return await firstValueFrom(this.http.post(`Message/CreateMessage/${params.arrangementId}/${params.typeId}`, model)) as IServerResponse<ICreateMessage>;
  }

  async getReminderMessageTemplate(arrangementId: number) {
    return await firstValueFrom(this.http.get(`Message/GetReminderMessageTemplate/${arrangementId}`)) as IServerResponse<ReminderMessageTemplate[]>;
  }

}
