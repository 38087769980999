import { Component } from '@angular/core';

@Component({
  selector: 'mb-course-create',
  templateUrl: './course-create.page.html',
  styleUrls: ['./course-create.page.scss'],
})
export class CourseCreatePageComponent {
 
  constructor() {
  }

}