<div class="contact bg-white" fxLayout="row wrap">
  <div fxFlex="100">
    <div fxLayout="row wrap" fxLayoutAlign="center center">
      <div fxFlex="100">
        <mat-form-field>
          <mat-icon matPrefix class="input-icon">search</mat-icon>
          <input
            matInput
            mbDebouncedInput
            [debounceTime]="debounceTime"
            (debouncedChange)="onContactSearch()"
            [(ngModel)]="searchText"
            placeholder="{{'GENERAL.SEARCH' | translate}}"
          />
          <button *ngIf="searchText" matSuffix mat-icon-button aria-label="Clear" (click)="resetSearchText()">
            <mat-icon>close</mat-icon>
          </button>
        </mat-form-field>
      </div>
    </div>
    <ng-container *ngIf="!loading; else loader">
      <ng-container *ngIf="contacts?.length; else noDataToShow">
        <ng-container *ngIf="!mobileQuery.matches">
          <mb-mobile-contact-view [contacts]="contacts" [pagination]="pagination"></mb-mobile-contact-view>
        </ng-container>
        <ng-container *ngIf="mobileQuery.matches">
          <mb-desktop-contact-view [contacts]="contacts" [pagination]="pagination"></mb-desktop-contact-view>
        </ng-container>

        <div class="pagination" *ngIf="pagination?.totalPages > 1">
          <mat-paginator
            [pageSizeOptions]="[5, 10, 25]"
            showFirstLastButtons
            (page)="handlePageEvent($event)"
            [pageSize]="pagination.pageSize"
            [length]="pagination.totalRecords"
            [pageIndex]="pagination.pageNumber"
            [hidePageSize]="!mobileQuery.matches"
          ></mat-paginator>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-template #noDataToShow>
  <h4 class="text-muted text-center">{{ 'GENERAL.NO_DATA' | translate }}</h4>
</ng-template>

<ng-template #loader>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>