import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})

export class HttpService {
  private host = `${environment.apiUrl}/api/${environment.apiVersion}/`;

  constructor(private http: HttpClient) {
  }

  get(url: string, params?: any) {
      return this.http.get(`${ this.host }${ url }`, { params });
  }

  post(url: string, data: any, params?: any) {
      return this.http.post(`${ this.host }${ url }`, data, { params });
  }

  delete(url: string, data: any, params: any) {
    const options = {
      params: params,
      body: data,
    };
    return this.http.delete(`${ this.host }${ url }`, options);
  }

  getFile(url: string, params?: any) {
    return this.http.get(`${ this.host }${ url }`, { params, responseType: 'blob' });
  }

  put(url: string, data: any, params?: any) {
    return this.http.put(`${ this.host }${ url }`, data, { params });
  }

  patch(url: string, data: any, params: any) {
    return this.http.patch(`${ this.host }${ url }`, data, { params });
  }
}
