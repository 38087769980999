import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CanDeactivateGuard } from '@core/guards/can-deactivate.guard';
import { AuthGuard, CanActivateChildGuard } from '@core/services/auth/auth.guard';
import { ApplicationLayoutComponent } from 'src/app/layouts/application-layout/application-layout.component';
import { RootLayoutComponent } from 'src/app/layouts/root-layout/root-layout.component';
import { CompetencePageComponent } from 'src/app/pages/competence/competence.page';
import { ContactsPageComponent } from 'src/app/pages/contacts/contacts.page';
import { DashboardPageComponent } from 'src/app/pages/dashboard/dashboard.page';
import { PageNotFoundComponent } from 'src/app/pages/page-not-found/page-not-found.page';
import { UserProfilePageComponent } from 'src/app/pages/user-profile/user-profile.page';
import { CompetenceSearchPageComponent } from 'src/app/pages/competence-search/competence-search.page';
import { CollectorSiteComponent } from 'src/app/pages/collector-site/collector-site.component';
import { CoursePageComponent } from 'src/app/pages/course-and-certificates/course/course.page';

const appRoutes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: 'authentication',
    loadChildren: () => import('../app/pages/authentication/authentication.module').then((m) => m.AuthenticationModule),
  },
  {
    path: '',
    canActivate: [AuthGuard],
    component: RootLayoutComponent,
    children: [
      {
        path: '',
        canActivateChild: [CanActivateChildGuard],
        component: ApplicationLayoutComponent,
        children: [
          { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
          {
            path: 'dashboard',
            component: DashboardPageComponent,
            data: {
              title: 'MAIN_MENU.DASHBOARD',
            },
          },
          {
            path: 'messages',
            canActivateChild: [CanActivateChildGuard],
            data: {
              title: 'MAIN_MENU.MESSAGES',
            },
            loadChildren: () => import('../app/pages/messages/messages.module').then((m) => m.MessagesModule),
          },
          {
            path: 'course',
            data: {
              title: 'MAIN_MENU.COURSE_CERTIFICATES',
            },
            canActivateChild: [CanActivateChildGuard],
            children: [
              { path: '', component: CoursePageComponent },
              {
                path: 'create-course',
                loadChildren: () => import('../app/pages/course-and-certificates/courses.module').then((m) => m.CoursesModule),
              }
            ]
          },
          {
            path: 'contacts',
            component: ContactsPageComponent,
            data: {
              title: 'MAIN_MENU.CONTACTS',
            },
          },
          {
            path: 'competence',
            component: CompetencePageComponent,
            data: {
              title: 'MAIN_MENU.COMPETENCE',
            },
          },
          {
            path: 'competence-search',
            component: CompetenceSearchPageComponent,
            data: {
              title: 'MAIN_MENU.COMPETENCE_SEARCH',
            },
          },
          {
            path: 'user-profile/:id',
            component: UserProfilePageComponent,
            data: {
              title: 'MAIN_MENU.PROFILE',
            }
          },
          {
            path: 'collector-site',
            component: CollectorSiteComponent,
            data: {
              title: 'MAIN_MENU.COLLECTOR_MENU',
            },
          },
        ],
      },
    ]
  },
  // This is hard corded as per requirement (Marcus Mobilise) in old site classic and PWA START //
  {
    path: 'mobile',
    redirectTo: '/authentication/login'
  },
  {
    path: 'mobileold',
    redirectTo: '/authentication/login'
  },
  {
    path: 'mobileold/default.aspx',
    redirectTo: '/authentication/login'
  },
  // This is hard corded as per requirement in old site classic and PWA END //

  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [
    RouterModule,
    
  ],
  providers: [
    CanDeactivateGuard,
  ],
})

export class RoutingConfigurationModule {
}
