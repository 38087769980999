import { email, notEmpty, prop, required } from "@rxweb/reactive-form-validators";

export class ProfileModel {

  constructor() { }

  @prop()
  name: string;

  @notEmpty()
  firstName: string;

  @notEmpty()
  lastName: string;

  @notEmpty()
  mobile: string;

  @notEmpty()
  @email()
  email: string;

  @notEmpty()
  address: string;

  @notEmpty()
  postalNumber: string;

  @notEmpty()
  postalArea: string;

  forumEmailNotification: boolean;
}

export class ProfileGroup {

  constructor() { }

  @required()
  arrangement: string;

  @required()
  categoryId: string;

  @required()
  sectionId: string;

  @prop()
  roleIds: Array<number>;

}

export interface IProfileGroupOption {
  id: number;
  arrangementId: number;
  externalId: number;
  internationalName: string;
  name: string;
  oxxGroupId: number;
  roles?: IProfileGroupOption[];
}

export interface IProfileGroupSection {
  groupResponse: IProfileGroupOption[];
  sectionMultiRoleMode: boolean;
}

export interface IProfileGroup {
  arrangementName: string;
  groups: IProfileGroupOption[];
}
