<mat-card class="p-0">
  <ng-container *ngFor="let item of userModuleDetails?.collectorPermissionReadDto; let i = index;">
    <ng-container *ngIf="item?.type === type">
      <iframe
        width="100%" 
        class="collector-iframe"
        [src]="item?.link + token?.accessToken | safe"
        name="'iframe'+i"
      >
      </iframe>
    </ng-container>
  </ng-container>
</mat-card>