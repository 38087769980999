import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AngularMaterialModule } from './lib/angular-material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ImageService } from '@core/services/ImageService';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { SnackbarService } from '@core/services/SnackbarService';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { PermissionService } from './services/PermissionService';
import {NgxPaginationModule} from 'ngx-pagination';
import { SafePipe } from './pipe/safe.pipe';

@NgModule({
  declarations: [
    SafePipe,
  ],
  imports: [
    HttpClientModule,
    AngularMaterialModule,
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    TranslateModule,
    NgxPaginationModule,
  ],
  providers: [
    ImageService,
    SnackbarService,
    PermissionService,
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { restoreFocus: false, hasBackdrop: true } },
  ],
  exports: [
    HttpClientModule,
    AngularMaterialModule,
    FlexLayoutModule,
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    RxReactiveFormsModule,
    TranslateModule,
    NgxPaginationModule,
    SafePipe
  ],
})

export class CoreModule {
}
