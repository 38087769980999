<mat-card>
  <mat-card-content>
    <div class="background-section bg-primary-dark">
      <img class="profile-img" [src]="profileImage" alt="profile-img">
      <ng-container *ngIf="!isCompetenceListAvailable">
        <button mat-fab mat-card-icon class="update-img-icon mat-button-no-color" (click)="onClick()" [disabled]="profileImgLoading">
          <mat-icon>photo_camera</mat-icon>
        </button>
        <mb-file-upload *ngIf="!isCompetenceListAvailable" #fileInput [acceptableFileType]="acceptableFileType" (fileChangeEvent)="getFiles($event)"></mb-file-upload>
      </ng-container>
    </div>
    
    <div class="profile-info">
      <ng-container *ngIf="!showProfileEditForm">
        <h2 class="text-center font-bold">{{userDetails.name}}
          <span *ngIf="!isCompetenceListAvailable" class="edit-icon" (click)="showProfileEditForm = true"><mat-icon class="cursor-pointer">edit</mat-icon></span>
        </h2>
        <div class="profile-details">
          <p>
            <mat-icon> phone</mat-icon>
            <a href="tel:{{userDetails?.mobile}}" class="link">{{userDetails.mobile}}</a>
          </p>
          <p>
            <mat-icon> email</mat-icon>
            <a href="mailto:{{userDetails?.email}}" class="link">{{userDetails.email || '—'}}</a>
          </p>
          <p>
            <mat-icon>home</mat-icon>
            <span class="link">{{userDetails.address || '—'}}</span>
          </p>
        </div>
      </ng-container>
      <ng-container *ngIf="showProfileEditForm">
        <div class="profile-info-form">
          <form [formGroup]="profileInfoForm" (ngSubmit)="onSubmit()">
            <div fxLayoutAlign="space-between center">
              <div class="input-field">
                <mat-icon class="mat-button-no-color">perm_identity</mat-icon>
                <mb-form-input
                  id="first-name"
                  class="w-100"
                  [inputFormControl]="profileInfoForm.get('firstName')"
                  matLabel="GENERAL.FIRSTNAME"
                  fieldWidth="8em"
                ></mb-form-input>
              </div>
              <div class="input-field">
                <mb-form-input
                  id="last-name"
                  class="w-100"
                  [inputFormControl]="profileInfoForm.get('lastName')"
                  matLabel="GENERAL.LASTNAME"
                  fieldWidth="8em"
                ></mb-form-input>
              </div>
            </div>
            <div class="input-field">
              <mat-icon class="mat-button-no-color">call</mat-icon>
              <mb-form-input
                id="mobile"
                class="w-100"
                [inputFormControl]="profileInfoForm.get('mobile')"
                matLabel="GENERAL.MOBILE"
              ></mb-form-input>
            </div>
            <div class="input-field">
              <mat-icon class="mat-button-no-color">mail_outline</mat-icon>
              <mb-form-input
                id="email"
                class="w-100"
                [inputFormControl]="profileInfoForm.get('email')"
                matLabel="GENERAL.EMAIL"
              ></mb-form-input>
            </div>
            <div class="input-field">
              <mat-icon class="mat-button-no-color">home</mat-icon>
              <mb-form-input
                id="address"
                class="w-100"
                [inputFormControl]="profileInfoForm.get('address')"
                matLabel="GENERAL.ADDRESS"
              ></mb-form-input>
            </div>
            <div fxLayout="row">
              <mat-icon></mat-icon>
              <div class="w-100" fxLayoutAlign="space-between center">
                <mb-form-input
                  id="postalNumber"
                  [inputFormControl]="profileInfoForm.get('postalNumber')"
                  matLabel="GENERAL.POSTALCODE"
                  fieldWidth="8em"
                ></mb-form-input>
                <mb-form-input
                  id="postalArea"
                  [inputFormControl]="profileInfoForm.get('postalArea')"
                  matLabel="GENERAL.CITY"
                  fieldWidth="8em"
                ></mb-form-input>
              </div>
            </div>
    
            <div class="text-center btn-action">
              <a mat-raised-button (click)="showProfileEditForm = false">{{ 'GENERAL.CANCEL' | translate }}</a>
              <button
                mat-raised-button
                color="primary"
                class="m-t-20 m-b-20 submitbtn"
                type="submit"
                [disabled]="profileInfoForm.invalid || loading"
              >
                <span>{{ 'GENERAL.STORE' | translate }}</span>
              </button>
            </div>
          </form>
        </div>
      </ng-container>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="p-0" *ngIf="!isCompetenceListAvailable">
  <mat-card-content [formGroup]="profileInfoForm">
    <div class="p-20 d-flex align-items-center justify-content-center">
      <p class="slider-label">Turn on e-mail notification for forum messages</p> 
      <mat-slide-toggle                   
      [formControl]="forumEmailNotification"
      class="slider-btn"                   
      >
      </mat-slide-toggle>
    </div>
  </mat-card-content>
</mat-card>
<mat-card class="p-0" *ngIf="!isCompetenceListAvailable">
  <mat-card-content>
    <div class="p-20 d-flex align-items-center justify-content-center">
      <button
      mat-stroked-button
      color="primary"
      class="outline-btn btn-block btn-lg m-t-20 m-b-20"
      type="submit"
      (click)="openGdprModal()"
      >
      {{ 'GENERAL.GDPR' | translate}}
      </button>
    </div>
  </mat-card-content>
</mat-card>