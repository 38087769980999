import { Component } from '@angular/core';

@Component({
  selector: 'mb-rejected-check-in',
  templateUrl: './rejected-checkin.component.html',
  styleUrls: ['./rejected-checkin.component.scss']
})
export class RejectedCheckInComponent {

}
