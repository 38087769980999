<div class="main-container">
<mat-toolbar class="topbar bg-primary" *ngIf="isDashboardPage">

  <button mat-icon-button (click)="snav.toggle()" class="sidebar-toggle menu-icon mobile-view" value="sidebarclosed">
    <mat-icon>menu</mat-icon>
  </button>

  <div class="navbar-header" fxLayoutAlign="center center">
    <a class="navbar-brand" fxLayout="row" [routerLink]="['/dashboard']">
      <ng-container *ngIf="!arrangementImg">
        <img *ngIf="mobileQuery.matches" src="assets/img/logo-icon.png" alt="logo" class="logo-large vm">
        <img *ngIf="!mobileQuery.matches" src="assets/img/logo-light-icon.png" alt="logo" class="logo vm img-circle">
      </ng-container>
      <ng-container *ngIf="arrangementImg">
        <img [src]="getImage(arrangementImg)" alt="logo" class="arrangement-logo">
      </ng-container>
    </a>
  </div>

  <mb-language-menu class="lang-section" (langChangeEvent)="onLanguageChange($event)"></mb-language-menu>
  <button mat-mini-fab [matMenuTriggerFor]="profile" class="account-section shadow-none bg-transparant">
    <img [src]="profileImage" class="account-icon vm img-circle" alt="profile-img">
  </button>
  <mat-menu #profile="matMenu">
    <button *ngIf="hasPermission('profileMenuView')" mat-menu-item (click)="openProfilePage()">
      <mat-icon class="mat-button-no-color">account_box</mat-icon>
      {{ 'MAIN_MENU.PROFILE' | translate }}
    </button>
    <button *ngIf="hasPermission('competanseMenuView')" mat-menu-item (click)="goToCompetencePage()">
      <mat-icon class="mat-button-no-color">verified</mat-icon>
      {{ 'MAIN_MENU.COMPETENCE' | translate }}
    </button>
    <button mat-menu-item *ngIf="isLinkVisible()" (click)="goToRegisterArrangementPage()">
      <mat-icon class="mat-button-no-color material-icons-outlined">workspaces</mat-icon>
      {{ 'MAIN_MENU.ARRANGEMENT' | translate }}
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon class="mat-button-no-color">exit_to_app</mat-icon>
      {{ 'MAIN_MENU.SIGN_OUT' | translate }}
    </button>
  </mat-menu>
</mat-toolbar>

<mat-toolbar class="topbar page bg-primary" *ngIf="!isDashboardPage">
  <mat-icon class="cursor-pointer" (click)="goToPreviousPage()">arrow_back_ios</mat-icon>
  <h2 class="page-title">{{ currentPageTitle | translate }}</h2>
</mat-toolbar>

<mat-sidenav-container class="sidenav-container" autosize="true">
  <mat-sidenav
    #snav
    id="snav"
    [mode]="mobileQuery.matches ? 'side' : 'over'"
    fixedTopGap="0"
    [opened]="mobileQuery.matches"
    [disableClose]="mobileQuery.matches"
    class="bg-primary-dark"
  >
    <mat-nav-list>
      <a mat-list-item class="m-b-15" (click)="mobileQuery.matches ? null : snav.close()" (click)="goToDashboard()">
        <span class="menu-action">
          <button mat-mini-fab class="bg-white menu-icon">
            <mat-icon class="text-dark vm">home</mat-icon>
          </button>
          <span class="text-primary-contrast m-l-15">{{ 'MAIN_MENU.DASHBOARD' | translate }}</span>
        </span>
      </a>
      <ng-container *ngIf="menus?.length">
        <ng-container *ngFor="let menu of menus">
          <a mat-list-item class="m-b-15" [routerLink]="menu.routeName" routerLinkActive="active" *ngIf="menu.view" (click)="mobileQuery.matches ? null : snav.close()" (click)="navigateToModule(menu)">
            <span class="menu-action">
              <button mat-mini-fab class="bg-white menu-icon">
                <mat-icon class="text-dark vm">{{getModuleIcon(menu.type)}}</mat-icon>
              </button>
              <span class="text-primary-contrast m-l-15 hide-overflow-text">{{ menu.name | translate }}</span>
            </span>
          </a>
        </ng-container>
      </ng-container>
      <ng-container>
        <mat-accordion #accordion="matAccordion" [multi]="true">
          <mat-expansion-panel class="bg-primary-dark nav-expansion" hideToggle="true" [class.mat-elevation-z0]="true">
            <mat-expansion-panel-header class="p-l-0 p-t-10 disable_ripple">
              <a mat-list-item class="m-b-15" [ngClass]="{'active': colletctorId > 0}">
                <span class="menu-action">
                  <button mat-mini-fab class="bg-white menu-icon">
                    <mat-icon class="text-dark vm collector-icon"></mat-icon>
                  </button>
                  <span class="text-primary-contrast m-l-15 hide-overflow-text">{{ 'MAIN_MENU.COLLECTOR_MENU' | translate }}</span>
                </span>
              </a>                
            </mat-expansion-panel-header>
            <ng-container *ngFor="let menu of collectorMenus">
              <a *ngIf="hasPermissionFlag(menu)" mat-list-item class="p-0" routerLinkActive="active"  [routerLink]="'collector-site'"[queryParams]="{id: menu.type}" (click)="mobileQuery.matches ? null : snav.close()" (click)="getRouteId(menu)">
                <span class="menu-action">
                  <span class="text-primary-contrast m-l-15 hide-overflow-text">{{ menu.name | translate }}</span>
                </span>
              </a>
            </ng-container>
          </mat-expansion-panel>
        </mat-accordion>
      </ng-container>
    </mat-nav-list>
  </mat-sidenav>

  <mat-sidenav-content>
    <!-- <mb-breadcrumb></mb-breadcrumb> -->
    <main class="h-100">
      <div class="page-container h-100">
        <div class="page-content application-container">
          <router-outlet></router-outlet>
        </div>
      </div>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
</div>