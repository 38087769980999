import { Injectable, OnDestroy } from '@angular/core';
import { ReactiveFormConfig } from '@rxweb/reactive-form-validators';
import { TranslateService } from '@ngx-translate/core';
import { SessionService } from './auth/session.service';
import { TranslationService } from './TranslationService';
import { StorageService } from './StorageService';
import { StorageEnum } from '@core/constants/storage.enum';
import { ProfileService } from '@modules/Profile/services/profile.service';
import { Subscription } from 'rxjs';
import { DateAdapter } from '@angular/material/core';
import { PermissionService } from './PermissionService'
import { IAppInfo } from '@core/models/user-response.model';
import { DefaultValuesEnum } from '@core/constants/default-values.enum';

@Injectable({
  providedIn: 'root',
})
export class BootstrapService implements OnDestroy {
  public langSubscription: Subscription;

  constructor(
    private translate: TranslateService,
    private translationService: TranslationService,
    private sessionService: SessionService,
    private storage: StorageService,
    private profileService: ProfileService,
    private dateAdapter: DateAdapter<Date>,
    private permissionService: PermissionService,
  ) {
  }

  async bootstrapData() {
    if (this.sessionService.isAuthenticated()) {
      this.setClientLanguage();
      await this.getUserDetails();
      this.permissionService.loadPermission();
      await this.setReactiveFormConfig();
      this.translationService.setLanguagePreference();
      this.langSubscription = this.translate.onLangChange.subscribe(async () => {
        this.dateAdapter.setLocale(this.translationService.getCurrentLanguage());
        await this.setReactiveFormConfig()
      });
    } else {
      this.sessionService.logout();
    }
  }

  async setClientLanguage() {
    const currentLanguage = this.translationService.getCurrentLanguage();

    try {
      const authData: IAppInfo | any = await this.permissionService.appInfo();
      this.storage.setItem(StorageEnum.AppInfo, authData);

      if(authData?.enableClientLocalication) {          
        const splitStr = currentLanguage.split(authData?.clientLocalicationCode)[0];
        this.storage.setItem(DefaultValuesEnum.Language, `${splitStr}${authData?.clientLocalicationCode}`);
      } else {
        this.storage.setItem(StorageEnum.AppInfo, {});
      }
    } catch (error) {
      console.log(error)
    }
  }

  private async getUserDetails() {
    const isUserDataExists = this.storage.getItem(StorageEnum.User);
    if (isUserDataExists) {
      return;
    }
    const id = this.storage.getItem(StorageEnum.UserId);
    const data = await this.profileService.getUserDetailById(id);
    this.storage.setItem(StorageEnum.User, data.result);
    // this.translationService.setLanguage(data.result.language_sname.toLowerCase());
  }

  async setReactiveFormConfig() {
    ReactiveFormConfig.set({
      validationMessage: {
        required: 'VALIDATION_MSG.REQUIRED',
        notEmpty: 'VALIDATION_MSG.NOT_EMPTY',
        numeric: 'VALIDATION_MSG.NUMERIC',
        range: await this.translate.get('VALIDATION_MSG.NOT_IN_RANGE', {
          value1: '{{1}}',
          value2: '{{2}}',
        }).toPromise().then((msg) => msg),
      },
    });
  }
  
  ngOnDestroy() {
    this.langSubscription.unsubscribe();
  }
}
