import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { IFileModel } from '@core/core.interface';
import { ImageService } from '@core/services/ImageService';

@Component({
  selector: 'mb-file-preview-modal',
  templateUrl: './file-preview-modal.component.html',
  styleUrls: ['./file-preview-modal.component.scss'],
})
export class FilePreviewModalComponent implements OnInit {

  public filePath: SafeUrl;

  constructor(
    private imageService: ImageService,
    private dialogRef: MatDialogRef<FilePreviewModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IFileModel,
  ) { }

  ngOnInit() {
    if (this.data?.contentType) {
      this.filePath = this.imageService.loadImage(this.data.fileDataUrl);
    }
  }

  downloadFile() {
    const downloadLink = document.createElement('a');

    downloadLink.href = this.data.fileDataUrl;
    downloadLink.download = this.data.fileName;
    downloadLink.click();
  }

  isImage() {
    return this.data?.contentType === 'image/jpeg';
  }

  isPdf() {
    return this.data?.contentType === 'application/pdf';
  }

  close() {
    this.dialogRef.close();
  }
}
