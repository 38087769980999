import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PermissionService } from '@core/services/PermissionService';
import { IProfileGroup, IProfileGroupOption } from '@modules/Profile/profile.model';
import { map } from 'lodash';
import { AddGroupModalComponent } from '../add-group-modal/add-group-modal.component';

@Component({
  selector: 'mb-profile-group',
  templateUrl: './profile-group.component.html',
  styleUrls: ['./profile-group.component.scss'],
})
export class ProfileGroupComponent {

  @Input() profileGroupInfo: IProfileGroup[];
  @Output() profileGroupEmitChanges = new EventEmitter();

  constructor(
    public dialog: MatDialog,
    private permissionService: PermissionService
  ) {}

  addNewGroup() {
    const dialogRef = this.dialog.open(AddGroupModalComponent, {
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res: boolean) => {
      if (res) {
        this.profileGroupEmitChanges.emit();
      }
    });
  }

  getRoles(roles: IProfileGroupOption[]) {
    return map(roles, role => role.name).toString();
  }

  hasPermission(type:string) {
    return this.permissionService.hasPermission(type);
  }

}
