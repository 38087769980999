export interface IArrangementList {
  arrangementId: number;
  endDate: string;
  name: string;
  links: Array<any>;
  userRegisterArrangementLink: string;
  userRegisterArrangementLinkActive: boolean;
}

export interface IUserModuleDetail {
  arrangementImage: string;
  email: string;
  fileUrl: string;
  firstName: string;
  fullName: string;
  language: string;
  lastName: string;
  userId: number;
  menuColorCode: string;
  permissionReadDto: IPermissionModel[];
  collectorPermissionReadDto: IPermissionModel[];
}

export interface IPermissionModel {
  counter: number;
  forumCount: number;
  icon: string;
  link: string;
  name: string;
  siteOption: number;
  type: number;
  view: boolean;
}

export enum Modules {
  NONE,
  CLASSIC,
  MYPROFILE,
  MESSAGE,
  INFORMATION,
  INVITATION,
  EVENT,
  EVENETCHECKIN,
  CONTACT,
  ELEARNING,
  APPLICATIONMANAGEMENT,
  AVVIKS,
  INVITATIONREADER,
  MATRIX,
  COLLECTOR,
  COMPETENCE,
  NEWMESSAGE,
  COURSECERTIFICATE,
  LASTYEARCOLLECTOR,
  THISYEARCOLLECTOR,
  SENDMESSAGECOLLECTOR,
  MEETINGPLACESCOLLECTOR,
}

export const MODULEINFO = [
  { routeName: 'classic', icon: 'desktop_windows', color: '#d1e7f9', type: Modules.CLASSIC },
  { routeName: 'profile', icon: 'account_circle', color: '#d1e7f9', type: Modules.MYPROFILE },
  { routeName: 'information', icon: 'description', color: '##ffa3a5', type: Modules.INFORMATION },
  { routeName: 'check-in', icon: 'task_alt', color: '#aeeaf0', type: Modules.EVENETCHECKIN },
  { routeName: 'deviation', icon: '', color: '#aeeaf0', type: Modules.AVVIKS },
  { routeName: 'messages', icon: 'message', color: '#d1e7f9', type: Modules.MESSAGE },
  { routeName: 'invitation-reader', icon: '', color: '#aeeaf0', type: Modules.INVITATIONREADER },
  { routeName: 'invitation', icon: 'insert_invitation', color: '#aeeaf0', type: Modules.INVITATION },
  { routeName: 'contacts', icon: 'contacts', color: '#efd2f9', type: Modules.CONTACT },
  { routeName: 'event', icon: 'history', color: '#efd2f9', type: Modules.EVENT },
  { routeName: 'e-learning', icon: 'school', color: '#fceab5', type: Modules.ELEARNING },
  { routeName: 'competence-search', icon: 'assignment_ind', color: '#fceab5', type: Modules.COMPETENCE },
  { routeName: 'messages', icon: 'forum', color: '#d1e7f9', type: Modules.NEWMESSAGE },
  { routeName: 'course', icon: 'file_present', color: '#d1e7f9', type: Modules.COURSECERTIFICATE },
];