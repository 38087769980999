<button mat-mini-fab [matMenuTriggerFor]="menu" class="shadow-none bg-transparant flag-btn">
  <img src="/assets/flag/{{ selectedLang?.base }}.png" class="flag" alt="flag">
</button>
<mat-menu #menu="matMenu">
  <button mat-menu-item *ngFor="let langauge of languages" (click)="changeLanguage(langauge)">
    <span class="align-items-center">
      <img src="/assets/flag/{{ langauge?.code }}.png" class="flag" alt="flag">
      <span>{{langauge.name | translate}}</span>
    </span>
  </button>
</mat-menu>