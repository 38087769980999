import { IPagination } from "@core/core.interface";
import { prop, required } from "@rxweb/reactive-form-validators";


export interface IMessageListParams extends IPagination {
  type: CourseListEnum;
}

export enum CourseListEnum {
  CourseCertificate = 21,
}
export enum DocumentType {
  All = -1,
  None = 0,
  Message,
  Article,
  Invitation,
  Diploma,
  Email,
  SMS,
  Elering,
  EleringTemplate,
  InvitationTemplate,
  CourseContainer,
  Course,
  CourseTemplate,
  MessageModuleTemplate ,
  Report,
  SingleReport,
  GroupeReport,
  ReportTemplate,
  FeedBackReport,
  CourseCertificate = 21,
}
export interface IMailCountList {
  type: number;
  count: number;
}

export interface IMailDetails {
  id: string,
  documentTitle: string,
  documentContent: string,
  created: string,
  createdBy: number,
  createdByName: string,
  arrangementId: number,
  isDeleted: true,
  isProcessed: true,
  dateToProcess: string,
  authorImage: string,
  documentType: any,
  fileUrl: string,
  isArchived: true,
  isRead: true,
  courseCertificatePdfTemplate: string;
  title: string;
}

 export class CourseTypeModel {
  @prop({ defaultValue: 21})
  @required()
  messageType: number;
}

export class CreateCourse {

  @prop()
  @required()
  title: string;

  @prop()
  @required()
  pagePaperSize: string;
  
}

export class IDynamicfieldsList {
  IDynamicfieldsList: IDynamicfield[];
}

export interface IDynamicfield{
  key: string;
  value: string;
  visible: boolean
}

export interface IDyncamicLabel {
  id: number;
  value: string;
}

export interface IPaperSizeRation {
  id: number;
  label: string;
  width: string; 
  height: string;
}

export enum DeletOption {
  None,
  SoftDelete,
  Harddelete
}
