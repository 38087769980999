import { Component, OnDestroy, OnInit } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { StorageEnum } from '@core/constants/storage.enum';
import { IUserModuleDetail } from '@core/models/modules.model';
import { IUserDetails } from '@core/models/user-response.model';
import { ImageService } from '@core/services/ImageService';
import { PermissionService } from '@core/services/PermissionService';
import { StorageService } from '@core/services/StorageService';
import { ICompetence } from '@modules/Competence/competence.model';
import { DashboardService } from '@modules/Dashboard/services/dashboard.service';
import { ThemeColorService } from '@modules/Dashboard/services/theme-color.service';
import { IProfileGroup } from '@modules/Profile/profile.model';
import { ProfileService } from '@modules/Profile/services/profile.service';
import { findIndex } from 'lodash';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mb-user-profile',
  templateUrl: './user-profile.page.html',
  styleUrls: ['./user-profile.page.scss'],
})
export class UserProfilePageComponent implements OnInit, OnDestroy {

  public userDetails: IUserDetails;
  public userId: number;
  public loading = false;
  public profileImage: SafeUrl;
  public routeSubscription: Subscription;
  public profileGroupInfo: IProfileGroup[];
  public competenceList: ICompetence[];
  public selectedCompetenceIndex: number;
  public userModuleDetails : IUserModuleDetail;

  constructor(
    private profileService: ProfileService,
    private route: ActivatedRoute,
    private imageService: ImageService,
    private storage: StorageService,
    private permissionService: PermissionService,
    public dashboardService: DashboardService,
    public themeColorService: ThemeColorService,
  ) {}

  async ngOnInit() {
    //Set Menu Details and color theme
    this.userModuleDetails = this.storage.getItem(StorageEnum.DashboardModuleDetails) || null;
    this.setModules();
    this.changeTheme();
    
    this.routeSubscription = this.route.params.subscribe( params => this.userId = params['id'] );
    if (history.state.profileInfo) {
      this.userDetails = history.state.profileInfo;
    } else {
      await this.getUserDetails();
    }
    this.competenceList = history.state?.userCompetenceList || [];
    this.selectedCompetenceIndex = history.state?.selectedCompetenceIndex;
    this.showProfileGroup();
    this.profileImage = this.imageService.loadImage(this.userDetails.fileUrl);
    this.getProfileGroupInfo();
  }

  showProfileGroup() {
    const loggedInUser = this.storage.getItem(StorageEnum.User);
    return this.userDetails.userId === loggedInUser?.userId;
  }

  setCompetence(competence: ICompetence) {
    const index = findIndex(this.competenceList, ele => ele.applicationCVLineId === competence.applicationCVLineId);
    if (index < 0 && competence?.applicationCVLineId) {
      this.competenceList.unshift(competence);
    } else if (index > -1) {
      this.competenceList[index] = competence;
    }
  }

  async getUserDetails() {
    try {
      this.loading = true;
      const data = await this.profileService.getUserDetailById(this.userId);
      this.userDetails = data.result;
    } catch (error) { }
    finally {
      this.loading = false;
    }
  }

  async getProfileGroupInfo() {
    if (this.competenceList?.length) {
      return;
    }
    try {
      this.loading = true;
      const data = await this.profileService.getProfileGroupInfo(this.userId);
      this.profileGroupInfo = data.result;
    } catch (error) { }
    finally {
      this.loading = false;
    }
  }

  setModules() {
    this.dashboardService.setDashboardModule(this.userModuleDetails?.permissionReadDto);
    this.dashboardService.setDashboardCollectorModule(this.userModuleDetails?.collectorPermissionReadDto);
  }

  changeTheme() {
    let themeColor: string;
    themeColor =  this.storage.getItem(StorageEnum.AppThemeColor);
    this.themeColorService.savePrimaryColor(themeColor);
  }

  
  hasPermission(type:string) {
    return this.permissionService.hasPermission(type);
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
