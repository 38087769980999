import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { get } from 'lodash';
import { IUserPermission, IPermission, IAppInfo } from '@core/models/user-response.model';
import { IServerResponse } from '@core/core.interface';
import { HttpService } from '@core/services/HttpService';
import { StorageService } from './StorageService';
import { StorageEnum } from '@core/constants/storage.enum';

@Injectable({providedIn: 'root'})
export class PermissionService {

  public permissions = new BehaviorSubject<IPermission<boolean>>(null);
  public isAdmin = new BehaviorSubject<boolean>(false);

  constructor(private http:HttpService,
    public storage: StorageService) {}

  async loadPermission(): Promise<IUserPermission> {
    const arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    const response = await this.getUserPermission(arrangementId);
    this.permissions.next(response.result.permissionReadDto);
    return response.result;
  }

  hasPermission(role: string) {
    return !!get(this.permissions.value, role);
  }

  getPermissions() {
    return this.permissions.value.permissionReadDto;
  }
  
  async getUserPermission(arrangementId: number) {
    return await firstValueFrom(this.http.get(`Permission/GetInitPermission/${arrangementId}`)) as  IServerResponse<IUserPermission>;
  }

  async appInfo() {
    return await firstValueFrom(this.http.get(`Info`)) as IServerResponse<IAppInfo>;
  }

}
