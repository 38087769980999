import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StorageEnum } from '@core/constants/storage.enum';
import { IServerResponse } from '@core/core.interface';
import { IArrangementList, IPermissionModel, IUserModuleDetail, MODULEINFO, Modules } from '@core/models/modules.model';
import { IUserDetails } from '@core/models/user-response.model';
import { SessionService } from '@core/services/auth/session.service';
import { HttpService } from '@core/services/HttpService';
import { StorageService } from '@core/services/StorageService';
import { DashboardService } from '@modules/Dashboard/services/dashboard.service';
import { ThemeColorService } from '@modules/Dashboard/services/theme-color.service';
import { find } from 'lodash';
import { firstValueFrom, map, Observable, startWith } from 'rxjs';

export interface User {
  name: string;
}

@Component({
  selector: 'mb-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPageComponent implements OnInit {

  public autoCompleteClosed = false;
  public currentUser: IUserDetails;

  public arrangementName = new FormControl(null);
  public selectedArrangementId: number;
  public arrangementList: IArrangementList[];
  public filteredOptions: Observable<User[]>;
  public userModuleDetails: IUserModuleDetail;
  public loading = false;
  public moduleLoading = false;
  themeColor: string;

  constructor(
    private storage: StorageService,
    private httpService: HttpService,
    private sessionService: SessionService,
    private dashboardService: DashboardService,
    public themeColorService: ThemeColorService,
  ) {
    this.themeColor = this.storage.getItem(StorageEnum.AppThemeColor);

    if(this.themeColor){
      this.themeColorService.savePrimaryColor(this.themeColor);
    }
}

  async ngOnInit() {
    this.themeColor = this.storage.getItem(StorageEnum.AppThemeColor);
    this.currentUser = this.storage.getItem(StorageEnum.User);

    this.arrangementList = this.storage.getItem(StorageEnum.ArrangementList) || [];
    this.userModuleDetails = this.storage.getItem(StorageEnum.DashboardModuleDetails) || null;
    if (!this.arrangementList.length) {
      await this.getArrangementList();
    }

    this.selectedArrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    if (this.selectedArrangementId) {
      const selectedArrangement = this.findArrangementById();
      this.arrangementName.patchValue(selectedArrangement.name);
      if (!this.userModuleDetails) {
        this.getUserModuleDetails(selectedArrangement.arrangementId);
      } else {
        this.dashboardService.setArrangementProfileImg(this.userModuleDetails.fileUrl);
        this.dashboardService.setDashboardModule(this.userModuleDetails.permissionReadDto);
        this.dashboardService.setDashboardCollectorModule(this.userModuleDetails.collectorPermissionReadDto);
      }
    }
    this.filteredOptions = this.arrangementName.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(value || '')
      }),
    );
  }

  private _filter(name: string): User[] {
    const filterValue = name.toLowerCase();

    return this.arrangementList.filter(option => option.name.toLowerCase().includes(filterValue));
  }

  getSelectedArrangementId(name: string) {
    return this.arrangementList?.find(ele => ele.name === name)?.arrangementId;
  }

  onAutocompleteDropdownOpen() {
    this.autoCompleteClosed = true;
    this.filteredOptions = this.arrangementName.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this._filter(value || '')
      }),
    );
  }

  async getArrangementList() {
    try {
      this.loading = true;
      const data = await firstValueFrom(this.httpService.get(`Arrangement/GetUserArrangement/${this.currentUser.userId}`)) as IServerResponse<IArrangementList[]>;
      this.arrangementList = data.result;
      this.storage.setItem(StorageEnum.ArrangementList, this.arrangementList);
    } catch (error) { }
    finally {
      this.loading = false;
    }
  }

  onArrangementSelect(value: number) {
    const storeArrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    if (storeArrangementId !== value) {
      this.selectedArrangementId = value;
      this.storage.setItem(StorageEnum.ArrangementId, value);
      this.storage.removeItem(StorageEnum.CompetenceSearchFilters);
      this.getUserModuleDetails(value);
    }
  }

  async getUserModuleDetails(arrangementId: number) {
    try {
      this.moduleLoading = true;
      const data = await firstValueFrom(this.httpService.get(`Permission/GetInitDashboardPermission/${arrangementId}`)) as IServerResponse<IUserModuleDetail>;
      this.userModuleDetails = data.result;
      this.changeTheme(this.userModuleDetails.menuColorCode);
      this.dashboardService.setArrangementProfileImg(this.userModuleDetails.fileUrl);
      this.dashboardService.setDashboardModule(this.userModuleDetails.permissionReadDto);
      this.dashboardService.setDashboardCollectorModule(this.userModuleDetails.collectorPermissionReadDto);
      this.storage.setItem(StorageEnum.DashboardModuleDetails, this.userModuleDetails);
    } catch (error) { }
    finally {
      this.moduleLoading = false;
    }
  }

  findArrangementById() {
    return find(this.arrangementList, ele => ele.arrangementId === this.selectedArrangementId) || null;
  }

  navigateToModule(module: IPermissionModel) {
    this.sessionService.navigateToModule(module);
  }

  getModuleBackgroundColor(type: Modules) {
    return MODULEINFO.find(ele => ele.type === type)?.color || '#aeeaf0';
  }

  getModuleIcon(type: Modules) {
    return MODULEINFO.find(ele => ele.type === type)?.icon;
  }

  changeTheme(primary: string) {
    this.themeColor = primary;
    this.storage.setItem(StorageEnum.AppThemeColor, this.themeColor);
    this.themeColorService.savePrimaryColor(this.themeColor);
  }
}
