import { MediaMatcher } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MatDrawer } from "@angular/material/sidenav";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageEnum } from "@core/constants/storage.enum";
import { IPagination } from "@core/core.interface";
import { StorageService } from "@core/services/StorageService";
import { DashboardService } from "@modules/Dashboard/services/dashboard.service";
import { ThemeColorService } from "@modules/Dashboard/services/theme-color.service";
import { CourseListEnum } from "@modules/Course/course.model";
import { mailbox } from "@modules/Course/services/categories";
import { MessageService } from "@modules/Message/services/message.service";
import { CourseService } from "@modules/Course/services/course.service";
import { IMailCountList, IMailList } from "@modules/Message/message.model";
import { forEach } from "lodash";
import { PermissionService } from "@core/services/PermissionService";
import { IUserModuleDetail } from "@core/models/modules.model";

@Component({
  selector: "mb-course",
  templateUrl: "./course.page.html",
  styleUrls: ["./course.page.scss"],
})
export class CoursePageComponent implements OnInit {
  @ViewChild("mailnav") mailnav: MatDrawer;

  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: 960px)`);

  sidePanelOpened = true;
  displayMode = "default";
  currentPage = 0;

  public mailboxes = mailbox;
  public pagination: IPagination = {
    pageNumber: 0,
    pageSize: 10,
  };
  public courseCertificateDetails = {
    selectedMessageType: CourseListEnum.CourseCertificate,
    selectedMessageId: null,
  };
  public defaultMailType = CourseListEnum.CourseCertificate;
  public mobileQuery: MediaQueryList;
  public selectedMail: any;
  public mailList: IMailList[];
  private mobileQueryListener: () => void;
  public mailCountList: IMailCountList[];
  public title: string;

  constructor(
    private storage: StorageService,
    private route: ActivatedRoute,
    public router: Router,
    public dialog: MatDialog,
    public media: MediaMatcher,
    public changeDetectorRef: ChangeDetectorRef,
    public themeColorService: ThemeColorService,
    public messageService: MessageService,
    public dashboardService: DashboardService,
    public courseService: CourseService,
    public permissionService: PermissionService,
  ) {
    this.selectedMail = null;
    this.title = this.mailboxes[0].name;
  }

  isOver(): boolean {
    return this.mediaMatcher.matches;
  }

  async ngOnInit() {
    this.changeTheme();
    this.mobileQuery = this.media.matchMedia("(min-width: 1100px)");
    this.mobileQueryListener = () => this.changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener("change", this.mobileQueryListener);

    if (!this.mobileQuery.matches) {
      this.courseService.addClass = false;
    }

    this.permissionService.loadPermission();
    const userModuleDetails = this.storage.getItem(StorageEnum.DashboardModuleDetails) || null;
    this.setModules(userModuleDetails);
    await this.getInvitationFileGenerator();
    this.goToSelectedCertificate();
  }

  goToCreateMessage() {
    this.router.navigate(["create-course/course-create-step-1"], {
      relativeTo: this.route,
    });
  }

  changeTheme() {
    let themeColor: string;
    themeColor = this.storage.getItem(StorageEnum.AppThemeColor);
    this.themeColorService.savePrimaryColor(themeColor);
  }

  async getInvitationFileGenerator() {
    const arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    const userId = this.storage.getItem(StorageEnum.UserId);
    const typeId = 21;
    await this.courseService
      .getInvitationFileGenerator(
        arrangementId,
        userId,
        typeId,
        this.pagination.pageNumber,
        this.pagination.pageSize
      )
      .then((res: any) => {
        this.mailList = res.result;
      });
  }
  
  pageChanged(pageNumber: number) {
    this.pagination.pageNumber = pageNumber - 1;
    this.getInvitationFileGenerator();
  }

  async getSelectedMailDetails(id) {
    if(this.mailList) {
      const model = {isTemplate: false};
      const data = await this.courseService.getCourseCertificate(id, model);
      this.selectedMail = data.result;
    // this.getMessageTypeCount();
    }
  }

  goToSelectedMail(model: IMailList, index: number) {
    this.courseCertificateDetails.selectedMessageId = model?.id;
    this.storage.setItem(StorageEnum.CourseCertificateDetails, this.courseCertificateDetails);
    const messageIdObj = this.storage.getItem(StorageEnum.CourseCertificateDetails)
    const messageId =  model?.id ?? messageIdObj.id;

    if(messageId){
      this.getSelectedMailDetails(messageId);
    }
  }

  goToSelectedCertificate(){
    const certificateIdObj = this.storage.getItem(StorageEnum.CourseCertificateDetails);
    const certificateId =  certificateIdObj?.selectedMessageId ? certificateIdObj.selectedMessageId : this.mailList[0]['id'];

    if(certificateId){
      this.getSelectedMailDetails(certificateId);
    }
  }

  async getMessageTypeCount() {
    const arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    const userId = this.storage.getItem(StorageEnum.UserId);
    const data = await this.messageService.getMessageTypeCount(arrangementId, userId);
    this.mailCountList = data.result;

    forEach(this.mailCountList, (item: IMailCountList, i: number) => {
      if (this.mailboxes && (this.mailboxes[i].type === item.type)) {
        this.mailboxes[i].count = item.count;
      }
    });
  }

  hasPermission(type:string) {
    return this.permissionService.hasPermission(type);
  }

  setModules(userModuleDetails: IUserModuleDetail) {
    this.dashboardService.setDashboardModule(
      userModuleDetails.permissionReadDto
    );
    this.dashboardService.setDashboardCollectorModule(
      userModuleDetails.collectorPermissionReadDto
    );
  }

}
