import { Injectable } from '@angular/core';
import { IPermissionModel } from '@core/models/modules.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {

  public arrangementProfileImgSub = new BehaviorSubject<string>(null);
  public dashboardModuleSub = new BehaviorSubject<any>(null);
  public collectordashboardModuleSub = new BehaviorSubject<any>(null);

  constructor(
  ) { }

  setArrangementProfileImg(data: string) {
    this.arrangementProfileImgSub.next(data);
  }

  setDashboardModule(data: IPermissionModel[]) {
    this.dashboardModuleSub.next(data);
  }

  setDashboardCollectorModule(data: IPermissionModel[]) {
    this.collectordashboardModuleSub.next(data);
  }
}
