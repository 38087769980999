<div *ngIf="data">
  <mat-dialog-content>
    <div class="dialog-action">
      <span class="material-icons" (click)="downloadFile()">
        file_download
      </span>
      <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
    </div>
    <div class="file-preview-section" *ngIf="filePath">
      <img *ngIf="isImage()" class="img" [src]="filePath" alt="image">
      <embed *ngIf="isPdf()" class="pdf-file" type="application/pdf" [src]="filePath">
    </div>
  </mat-dialog-content>
</div>
