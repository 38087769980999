import { IPagination } from "@core/core.interface";
import { prop, required } from "@rxweb/reactive-form-validators";

export interface IMailList {
  id: string;
  arrangementId: number;
  authorImage: string;
  created: string;
  createdBy: number;
  createdByName: string;
  dateToProcess: string;
  documentContent: string;
  documentTitle: string;
  documentType: DocumentType;
  fileUrl: string;
  isArchived: boolean;
  isDeleted: boolean;
  isProcessed: boolean;
  isRead: boolean;
}

export interface IMessageListParams extends IPagination {
  type: MailListEnum;
}

export enum MailListEnum {
  Inbox,
  Send,
  Outbox,
  Archieve,
  Template,
  FilterMessage
}
export enum DocumentType {
  All = -1,
  None = 0,
  Message,
  Article,
  Invitation,
  Diploma,
  Email,
  SMS,
  Elering,
  EleringTemplate,
  InvitationTemplate,
  CourseContainer,
  Course,
  CourseTemplate,
  MessageModuleTemplate ,
  Report,
  SingleReport,
  GroupeReport,
  ReportTemplate,
  FeedBackReport,
}
export interface IMailCountList {
  type: number;
  count: number;
}

export interface IMailDetails {
  id: string,
  documentTitle: string,
  documentContent: string,
  created: string,
  createdBy: number,
  createdByName: string,
  arrangementId: number,
  isDeleted: true,
  isProcessed: true,
  dateToProcess: string,
  authorImage: string,
  documentType: any,
  fileUrl: string,
  isArchived: true,
  isRead: true,
}

export enum ReceiverObjectType {
  None,
  Unknown,
  All,
  Section,
  Role,
  ReceiverGroup,
  User,
  AllClerks,
  AllManagers,
  AllNeverLoggedIn,
  AllWithDutyToday,
  AllWithoutDuties,
  Arrangement,
  AllWithDuties,
 }

 export class MessageTypeModel {
  @prop({ defaultValue: 1})
  @required()
  messageType: number;

  @prop()
  @required()
  documentSenderRequest: string;
}

export class MessageSenderSearchFilter {

  constructor() {}

  @prop({ defaultValue: []})
  filters: string[];

  @prop({ defaultValue: [] })
  group: string;

  @prop({ defaultValue: [] })
  documentReceiverRoleRequests: string;

  @prop({ defaultValue: -1 })
  arrangementId: number;

  @prop({ defaultValue: -1 })
  type: number;
}

export interface IDocumentReceiverList {
  count: number,
  id: number,
  image: string,
  title: string,
  type: number,
}

export interface IRoleList {
  arrangementId: number,
  externalId: number,
  id: number,
  internationalName: number,
  name: number,
  oxxGroupId: number,
}

export class CreateMessage {
  @prop()
  documentReceiverRequests: DocumentReceiverRequests[];

  @prop()
  documentSenderRequest: DocumentSenderRequest;

  @prop({defaultValue: []})
  documentReceiverRoleRequests: any;

  @prop()
  id: string;

  @prop()
  @required()
  title: string;

  @prop()
  @required()
  message: string;

  @prop()
  scheduleMessage: boolean;

  @prop()
  dateToProcess: Date;

  @prop({defaultValue: null})
  validFrom: Date;

  @prop({defaultValue: null})
  validTo: Date;

  @prop({defaultValue: false})
  emailNotification: boolean;

  @prop()
  emailNotificationTemplate: string;

  @prop()
  emailNotificationTitle: string;

  @prop()
  emailNotificationMessage: string;

  @prop({defaultValue: false})
  smsNotification: boolean;

  @prop()
  smsNotificationTemplate: string;

  @prop()
  smsNotificationMessage: string;

  // @prop()
  // enableReminder: string;
  
  @prop()
  openForum: boolean;

  @prop()
  reminderTemplate: string;

  @prop({defaultValue: false})
  eMailReminder: boolean;

  @prop({defaultValue: false})
  smsReminder: boolean;

  @prop({defaultValue: false})
  isTemplate: boolean;
}

export interface ICreateMessage{
  documentReceiverRequests: DocumentReceiverRequests[];
  documentSenderRequest: DocumentSenderRequest;
  documentReceiverRoleRequests: [];
  id: string;
  title: string;
  message: string;
  scheduleMessage: boolean;
  dateToProcess: Date;
  validFrom: Date;
  validTo: Date;
  emailNotification: boolean;
  emailNotificationTitle: string;
  emailNotificationMessage: string;
  smsNotification: boolean;
  smsNotificationMessage: string;
}

export interface DocumentReceiverRequests {
  id: number,
  type: number,
}

export interface DocumentSenderRequest {
  id: number,
  accessTo: number,
}

export interface ReminderMessageTemplate {
  templateId: number,
  typeId: number,
  templateName: string,
  title: string,
  contentText: string,
}

export enum TemplateType {
  emailTemplate = 2,
  smsTemplate,
}