import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { DesktopContactViewComponent } from './component/desktop-contact-view/desktop-contact-view.component';
import { MobileContactViewComponent } from './component/mobile-contact-view/mobile-contact-view.component';


@NgModule({
  declarations: [
    MobileContactViewComponent,
    DesktopContactViewComponent,
  ],
  imports: [
    CoreModule,
  ],
  exports: [
    MobileContactViewComponent,
    DesktopContactViewComponent,
  ],
})
export class ContactModule {
}
