import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogSetting } from '@components/components.interface';

@Component({
  selector: 'mb-confirmation-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {

  constructor(
    private dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogSetting,
  ) {
    dialogRef.disableClose = true;
  }

  onBtnClick(value: boolean) {
    this.dialogRef.close(value);
  }
}
