import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'mb-otp-input',
  templateUrl: './otp-input.component.html',
  styleUrls: ['./otp-input.component.scss'],
})
export class OTPInputComponent {

  @Output() otpValueChange = new EventEmitter<number>();

  public otpConfig = {
    length: 6,
    allowNumbersOnly: true,
  };

  constructor(
  ) {}

  onOtpChange(value: number) {
    this.otpValueChange.emit(value);
  }

}
