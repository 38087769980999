<div mat-dialog-title class="text-right">
  <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
</div>
<form [formGroup]="profileGroupForm" *ngIf="profileGroupForm" (ngSubmit)="onSubmit()">
  <div mat-dialog-content *ngIf="!isGroupAdded; else successTemplate">
    <h3 class="text-center m-t-0">{{ 'PROFILE.GROUP.NEW_GROUP' | translate }}</h3>
    <div class="form-field">
      <mat-form-field>
        <mat-label>{{ 'PROFILE.GROUP.CHOOSE_ARRANGEMENT' | translate }}</mat-label>
        <mat-select
          id="arrangement"
          ngDefaultControl
          [disabled]="!arrangementList?.length"
          [formControl]="profileGroupForm.get('arrangement')"
          (selectionChange)="onArrangementSelect($event.value)"
        >
          <mat-option *ngFor="let arrangement of arrangementList" [value]="arrangement.arrangementId">{{arrangement.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="profileGroupForm.get('arrangement').invalid">{{ 'VALIDATION_MSG.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="form-field">
      <mat-form-field>
        <mat-label>{{ 'GENERAL.CATEGORY' | translate }}</mat-label>
        <mat-select
          name="categories"
          ngDefaultControl
          [disabled]="!categories?.length"
          [formControl]="profileGroupForm.get('categoryId')"
          (selectionChange)="onCategorySelect($event)"
        >
          <mat-option *ngFor="let category of categories" [value]="category.id">{{category.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="profileGroupForm.get('categoryId').invalid">{{ 'VALIDATION_MSG.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="form-field">
      <mat-form-field>
        <mat-label>{{ 'GENERAL.GROUP' | translate }}</mat-label>
        <mat-select
          name="sectionId"
          ngDefaultControl
          [formControl]="profileGroupForm.get('sectionId')"
          (selectionChange)="onGroupSelect($event)"
          [disabled]="!groups?.length"
        >
          <mat-option *ngFor="let group of groups" [value]="group.id">{{group.name}}</mat-option>
        </mat-select>
        <mat-error *ngIf="profileGroupForm.get('sectionId').invalid">{{ 'VALIDATION_MSG.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
    </div>
    <div class="form-field">
      <mat-form-field>
        <mat-label>{{ 'GENERAL.ROLE' | translate }}</mat-label>
        <mat-select
          name="roleIds"
          ngDefaultControl
          *ngIf="!isMultipleRoleSelection"
          [formControl]="profileGroupForm.get('roleIds')"
          [disabled]="!roles?.length"
        >
          <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
        </mat-select>
        <mat-select
          name="isMultipleRoleSelection"
          ngDefaultControl
          multiple
          *ngIf="isMultipleRoleSelection"
          [formControl]="profileGroupForm.get('roleIds')"
          [disabled]="!roles?.length"
        >
          <mat-option *ngFor="let role of roles" [value]="role.id">{{role.name}}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  
  </div>
  <div mat-dialog-actions class="justify-content-center text-center">
    <button mat-raised-button color="primary" class="modal-btn" type="submit">
      {{ 'GENERAL.ADD_GROUP' | translate }}
    </button>
  </div>
</form>

<ng-template #successTemplate>
  <div class="text-center success-template">
    <mat-icon>done_outline</mat-icon>
    
    <h3 class="font-bold">{{ 'PROFILE.GROUP.THANK_YOU_MESSAGE' | translate }}</h3>
    <p>{{ 'PROFILE.GROUP.EMAIL_INFO_MESSAGE' | translate }}</p>
  </div>
</ng-template>