import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { StorageEnum } from "@core/constants/storage.enum";
import { IAuthResult, StorageAccessToken } from "@core/models/auth.model";
import { IPermissionModel, MODULEINFO, Modules } from "@core/models/modules.model";
import { interval, Subscription, switchMap } from "rxjs";
import { HttpService } from "../HttpService";
import { StorageService } from "../StorageService";

@Injectable({
  providedIn: 'root',
})

export class SessionService {

  private interval: Subscription;

  constructor(
    private router: Router,
    private storageService: StorageService,
    private http: HttpService,
  ) { }

  navigateToLoginPage() {
    this.router.navigate(['/authentication/login']);
  }

  navigateToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  navigateToSubscription() {
    this.router.navigate(['/subscription']);
  }

  setSession(authResult: IAuthResult) {
    const expiresAt = (authResult.expires_in || 100 * 1000) + new Date().getTime();
    const storageData = { ...authResult, ...{ expiresAt, sub: JSON.parse(window.atob(authResult.accessToken.split('.')[1])) } };
    this.storageService.setItem(StorageEnum.AccessToken, storageData);
    this.storageService.setItem(StorageEnum.UserId, +storageData.sub.uId);
    // this.getTokenSilently();
  }

  isAuthenticated() {
    const accessToken: StorageAccessToken = this.storageService.getItem(StorageEnum.AccessToken);
    return accessToken;
    // return accessToken && new Date().getTime() < accessToken.expiresAt;
  }

  getUserIdFromToken() {
    const sub = this.storageService.getItem(StorageEnum.AccessToken);
    return +sub.sub.uId;
  }

  getAccessToken() {
    const accessToken: StorageAccessToken = this.storageService.getItem(StorageEnum.AccessToken);
    return accessToken?.accessToken || '';
  }

  redirectIfNotAuthenticated(activateRouteSnapshot: ActivatedRouteSnapshot) {
    if (this.isAuthenticated()) {
      const userResponse: any = this.storageService.getItem(StorageEnum.User);
      if (activateRouteSnapshot.routeConfig.path.indexOf('users') > -1) {
        const next = userResponse.otherDetails.appMetaData.subscriptionDetails.numberOfLicenses > 1;
        if (!next) {
          this.navigateToDashboard();
        }
      }
      return true;
    } else {
      this.navigateToLoginPage();
    }
  }

  redirectIfAuthenticated() {
    if (this.isAuthenticated()) {
      this.navigateToDashboard();
    }
  }

  getTokenSilently() {
    this.interval?.unsubscribe();
    const accessToken: StorageAccessToken = this.storageService.getItem(StorageEnum.AccessToken);
    let remainingTime = (accessToken.expiresAt - new Date().getTime()) - (5 * 60 * 1000); // expiry - 5 minutes
    if (remainingTime < (30 * 1000)) {
      remainingTime = 30 * 1000; // 30 seconds interval
    }
    this.interval = interval(remainingTime)
      .pipe(
        switchMap(async () => this.http.post('user/use-refresh-token', null, { refreshToken: accessToken.refreshToken }).toPromise()),
      )
      .subscribe((result: string) => {
        const parseResult: StorageAccessToken = JSON.parse(result);
        if (parseResult.accessToken) {
          this.setSession(parseResult);
        }
      });
  }

  navigateToModule(module: IPermissionModel) {
    if (module.type !== Modules.CONTACT && 
      module.type !== Modules.COMPETENCE && 
      module.type !== Modules.NEWMESSAGE && 
      module.type !== Modules.COURSECERTIFICATE 
      ) {
      const accessToken = this.getAccessToken();
      const redirectLink = module.link + accessToken;
      window.open(redirectLink, '_self');
    } else {
      const route = MODULEINFO.find(ele => ele.type === module.type)?.routeName;
      this.router.navigate([`./${route}`]);
    }
  }

  logout() {
    this.storageService.clearAll();
    this.navigateToLoginPage();
  }
}