<div class="h-100">
  <div>

    <mat-form-field subscriptSizing="dynamic" class="arrangement-dropdown">
      <mat-select [(value)]="selectedArrangementId" (selectionChange)="onArrangementSelect($event.value)">
        <mat-option [value]="option.arrangementId" *ngFor="let option of arrangementList">{{ option.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <ng-container>
    <div class="module-section" *ngIf="userModuleDetails">
      <h2 class="m-0 font-bold">{{'GENERAL.WELCOME_BACK' | translate}}</h2>
      <div>{{userModuleDetails.firstName}} {{userModuleDetails.lastName}}</div>
    
      <div class="modules">
        <ng-container *ngFor="let module of userModuleDetails.permissionReadDto">
          <div class="menu" fxLayout="column wrap" fxLayoutAlign="center center" fxLayoutGap="1em" *ngIf="module.view">
            <button mat-fab mat-card-icon class="shadow-none module-btn" [ngStyle]="{ 'background-color': getModuleBackgroundColor(module.type) }" matTooltip="{{module.name | translate}}" (click)="navigateToModule(module)">
              <mat-icon>{{getModuleIcon(module.type)}}</mat-icon>
            </button>
            <span class="module-label">{{module.name | translate}}</span>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="columns-3">
      <!-- <div>
        <mb-rejected-check-in></mb-rejected-check-in>
      </div>
      <div>
        <mb-resources></mb-resources>
      </div>
      <div>
        <mb-staffing></mb-staffing>
      </div>
      <div>
        <mb-message-responses></mb-message-responses>
      </div> -->
    </div>
  </ng-container>
  <ng-template #loader>
    <mat-spinner></mat-spinner>
  </ng-template>
</div>
