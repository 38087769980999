import { Routes } from '@angular/router';
import { CourseCreateStepOnePageComponent } from './course-action/course-create-step1/course-create-step1.page';
import { CourseCreateStepTwoPageComponent } from './course-action/courses-create-step2/course-create-step2.page';

export const CourseRoutes: Routes = [
      {
        path: 'course-create-step-1',
        component: CourseCreateStepOnePageComponent,
        data: {
          title: 'MAIN_MENU.GENERATOR',
        },
      },
      {
        path: 'course-create-step-2',
        component: CourseCreateStepTwoPageComponent,
        data: {
          title: 'MAIN_MENU.GENERATOR',
        },
      },
];
