import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { MessageDetailsComponent } from './component/message-details/message-details.component';
import { MessageListComponent } from './component/message-list/message-list.component';

@NgModule({
  declarations: [
    MessageDetailsComponent,
    MessageListComponent,
  ],
  imports: [
    CoreModule,
  ],
  exports: [
    MessageDetailsComponent,
    MessageListComponent,
  ],
})
export class MessageModule {
}
