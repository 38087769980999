import { Injectable } from '@angular/core';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
  confirmBeforeExist?: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot,
  ) {
    if (component) {
      if (component.confirmBeforeExist
        && !(nextState.url.indexOf('/login') > -1)
        && (!(nextState.url.indexOf('/project/') > -1) || nextState.url.indexOf('/project/open') > -1 || nextState.url.indexOf('/project/deleted-projects') > -1)) {
        return component.confirmBeforeExist();
      }
      return component.canDeactivate ? component.canDeactivate() : true;
    }
  }

}
