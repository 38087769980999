import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components/components.module';
import { CoreModule } from '@core/core.module';
import { CompetenceAddEditModalComponent } from './component/competence-add-edit-modal/competence-add-edit-modal.component';
import { CompetenceListComponent } from './component/competence-list/competence-list.component';
import { CompetenceSearchFilterModalComponent } from './component/competence-search-filter-modal/competence-search-filter-modal.component';
import { CompetenceSearchListComponent } from './component/competence-search-list/competence-search-list.component';
import { CompetenceStructureService } from './services/competenceStructureService';

@NgModule({
  declarations: [
    CompetenceListComponent,
    CompetenceAddEditModalComponent,
    CompetenceSearchListComponent,
    CompetenceSearchFilterModalComponent,
  ],
  imports: [
    CoreModule,
    ComponentsModule,
  ],
  exports: [
    CompetenceListComponent,
    CompetenceAddEditModalComponent,
    CompetenceSearchListComponent,
    CompetenceSearchFilterModalComponent,
  ],
  providers: [
    CompetenceStructureService,
  ]
})

export class CompetenceModule {
}
