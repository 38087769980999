import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { StorageEnum } from '@core/constants/storage.enum';
import { IPagination } from '@core/core.interface';
import { IUserModuleDetail } from '@core/models/modules.model';
import { PermissionService } from '@core/services/PermissionService';
import { StorageService } from '@core/services/StorageService';
import { ICompetence, ICompetenceModel } from '@modules/Competence/competence.model';
import { CompetenceAddEditModalComponent } from '@modules/Competence/component/competence-add-edit-modal/competence-add-edit-modal.component';
import { CompetenceService } from '@modules/Competence/services/competence.service';
import { DashboardService } from '@modules/Dashboard/services/dashboard.service';
import { ThemeColorService } from '@modules/Dashboard/services/theme-color.service';

@Component({
  selector: 'mb-competence',
  templateUrl: './competence.page.html',
  styleUrls: ['./competence.page.scss'],
})
export class CompetencePageComponent implements OnInit {

  public pagination: IPagination = {
    pageNumber: 0,
    pageSize: 10,
  };
  public competences: ICompetenceModel[];
  public loading = false;
  public userModuleDetails: IUserModuleDetail;

  constructor(
    public dialog: MatDialog,
    private storage: StorageService,
    private competenceService: CompetenceService,
    private permissionService: PermissionService,
    public dashboardService: DashboardService,
    public themeColorService: ThemeColorService,
  ) { }

  ngOnInit() {
    //Set Menu Details and color theme
    this.userModuleDetails = this.storage.getItem(StorageEnum.DashboardModuleDetails) || null;
    this.setModules();
    this.changeTheme();

    this.getCompetence();
  }

  async getCompetence() {
    const params = {
      // sectionId: null,
      arrangementId: this.storage.getItem(StorageEnum.ArrangementId),
      userId: this.storage.getItem(StorageEnum.UserId),
      // arrangementId: null,
      pageNumber: this.pagination.pageNumber,
      pageSize: this.pagination.pageSize,
    };

    try {
      this.loading = true;
      const data = await this.competenceService.getCompetences(params);
      this.pagination = { ...this.pagination, ...data.pageInfo };
      this.competences = data.result;
    } catch (error) {}
    finally {
      this.loading = false;
    }
  }

  setCompetence(competence: ICompetence) {
    if(competence.arrangementId) {
      this.getCompetence();
    }
  }

  openCompetenceFormModal() {
    this.dialog.open(CompetenceAddEditModalComponent, {
      panelClass: 'competence-modal'
    });
  }

  hasPermission(type:string) {
    return this.permissionService.hasPermission(type);
  }
  
  setModules() {
    this.dashboardService.setDashboardModule(this.userModuleDetails?.permissionReadDto);
    this.dashboardService.setDashboardCollectorModule(this.userModuleDetails?.collectorPermissionReadDto);
  }

  changeTheme() {
    let themeColor: string;
    themeColor =  this.storage.getItem(StorageEnum.AppThemeColor);
    this.themeColorService.savePrimaryColor(themeColor);
  }

  handlePageEvent(e: PageEvent) {
    this.pagination.pageNumber = e.pageIndex;
    this.pagination.pageSize = e.pageSize;
    this.getCompetence();
  }
}
