<mat-card class="h-100">
  <mat-card-header class="text-black">
    <mat-card-title>
      <a class="menu-icon" (click)="mailnav.toggle()">
        <mat-icon>menu</mat-icon>
      </a>
    </mat-card-title>
  </mat-card-header>
  <mat-sidenav-container
    class="email-box"
    [ngClass]="{ 'side-panel-opened': sidePanelOpened, 'side-panel-closed': !sidePanelOpened }"
  >
    <mat-sidenav
      #mailnav
      [mode]="isOver() ? 'over' : 'side'"
      [opened]="!isOver()"
      (open)="sidePanelOpened = true"
      (close)="sidePanelOpened = false"
      class="mail-sidebar"
      class="email-sidebar"
    >
    <mat-card-content>
      <div *ngIf="hasPermission('pdfGeneratorCreateView')" class="compose-btn m-b-5">
        <button
        mat-raised-button
        class="btn-block"
        color="warning"
        type="button"
        (click)="goToCreateMessage()"
        >
          <span>{{'GENERATOR.NEW' | translate }}</span>
        </button>
      </div>

      <div *ngFor="let mail of mailboxes; let i = index">
        <div *ngIf="hasPermission('certificateTabView')" class="d-flex align-items-center inbox-list" [class.bg-light]="mail.active" (click)="getInvitationFileGenerator()" >
          <mat-icon class="m-r-10 font-20">{{ mail.icon }} </mat-icon>
          <span class="font-medium menu-text">{{ mail.name | translate }}</span>
          <div class="ml-auto" *ngIf="mail.count">
            <span class="label label-primary" color="accent" selected>
              {{ mail.count }}
            </span>
          </div>
        </div>
      </div>
    </mat-card-content>
  </mat-sidenav>
    <div fxLayout="row wrap" class="oh h-100 bg-white">
      <div fxFlex.lg="40" fxFlex.gt-lg="30" fxFlex.sm="100" fxFlex="100" class="b-r">
        <div class="p-12 h-3 b-b d-flex align-items-center">
          <h5 class="font-medium m-0">{{ title | translate }}</h5> 
          <div class="ml-auto">
            <pagination-controls 
            *ngIf="pagination.totalPages > 1" 
            (pageChange)="pageChanged($event)" 
            maxSize="4"
            responsive="true" 
            previousLabel="" 
            nextLabel=""
            >
            </pagination-controls>
          </div>
        </div>

        <div class=" mail-list-section" *ngIf="mailList?.length; else noDataToShow">
          <div class="relative" *ngIf="hasPermission('certificateDetailView')">
            <div
            class="mail-box"
            *ngFor="
            let mail of mailList | paginate: { itemsPerPage: pagination.pageSize, currentPage: currentPage, totalItems: pagination.totalRecords };
            let i = index; 
            "
            >
              <mb-course-list [mailList]="mail" (selectedMailEvent)="goToSelectedMail(mail,i)"></mb-course-list>
            </div>
          </div>
        </div>
        <ng-template #noDataToShow>
          <h4 class="text-muted text-center">{{ 'GENERAL.NO_DATA' | translate }}</h4>
        </ng-template>
      </div>

      <div
        *ngIf="courseService.addClass"
        fxFlex.lg="60"
        fxFlex.gt-lg="70"
        fxFlex.sm="100"
        fxFlex="100"
        class="d-flex detail-part"
        [class.movetodetail]="courseService.addClass"
      >
        <div class="w-100">
          <div class="w-100">
            <mb-course-details [mailDetail]="selectedMail" (updateCourseList)="getInvitationFileGenerator()"></mb-course-details>
          </div>
        </div>
      </div>
    </div>
  </mat-sidenav-container>
</mat-card>
<div *ngIf="hasPermission('pdfGeneratorCreateView')" class="create-message-icon">
  <button mat-fab (click)="goToCreateMessage()">
    <mat-icon>create</mat-icon>
  </button>  
</div>
