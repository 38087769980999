import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ImageService {

  constructor(
    private domSanitizer: DomSanitizer,
  ) {
  }

  loadImage(data: string, base64PrefixText: string = '') {
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`${base64PrefixText}${data}`);
  }
}

