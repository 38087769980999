<div class="bg-white h-100">
  <div class="progressbar-wrapper b-b">
    <mat-progress-bar mode="determinate" value="100"></mat-progress-bar>
  </div>

  <div class="page-content p-20">
    <form (ngSubmit)="onSubmit()">
      <ng-container *ngIf="(createMessagePrevStepDetails?.messageType !== documentType.SMS)">
        <h5 class="m-0">{{'GENERATOR.TITLE' | translate}}</h5>
        <mat-form-field>
          <input [formControl]="form.controls['title']" type="text" matInput>
          <mat-error *ngIf="form.get('title').invalid">{{ 'VALIDATION_MSG.REQUIRED' | translate }}</mat-error>
        </mat-form-field>
      </ng-container>
      <section class="attachment-section d-flex align-items-center">
        <!-- Upload Image or PDF Certificate-->
        <mb-file-upload #fileUpload (fileChangeEvent)="getFileByType($event)"></mb-file-upload>
        <div class="attachement-icon img-icon" (click)="onClickAttachmentFile()">
          <mat-icon>attach_file</mat-icon>
        </div>
      </section>

      <mat-form-field>
        <mat-label>{{ 'GENERATOR.DOCUMENT_ASPECT' | translate }}</mat-label>
        <mat-select [formControl]="form.controls['pagePaperSize']">
          <mat-option *ngFor="let item of documentSizeList" [value]="item">{{item.label}}</mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('pagePaperSize').invalid">{{ 'VALIDATION_MSG.REQUIRED' | translate }}</mat-error>
      </mat-form-field>
      <div class="course-details">
        <mat-chip-listbox aria-label="dynamic-fields" class="dynamic-fields">
          <ng-container *ngFor="let label of dynamicFiledList; let i = index;">
            <mat-chip class="filters-chip" (click)="addFieldToContainer(label)">
              {{label.key | translate}}
            </mat-chip>
          </ng-container>
        </mat-chip-listbox>
        <!--Class certificate-container used create Page to get certificate layout and also required inline style -->
        <div class="certificate-container">
          <section class="page-certificate" style="margin: 0 auto;" [style.width]="paperSize?.width"
            [style.height]="paperSize?.height" >
            <div class="modal-content" #docContainer>
              <div id="doc-container" class="doc-container" cdkDropListData="dyncamicLabelList"
                (cdkDropListDropped)="drop($event)" style="position: relative;">
                <img *ngIf="isImage" height="auto" width="100%" [src]="imageUrl" alt="Image View">

                <div *ngIf="!isImage" class="pdf-container">
                  <pdf-viewer [src]="imageUrl" [page]="1" [render-text]="true" [original-size]="true"
                    [zoom-scale]="'page-fit'" [zoom]="0.9" [show-all]="true" [stick-to-page]="true"
                    style="width: 100%; height: 150vh;" (page-rendered)="pdfPageRendered($event)"></pdf-viewer>
                </div>

                <div style="position: absolute; left:0; top: 0;">
                  <div class="dynamic-field-box" [attr.id]="'dynamic-field-box' + i" *ngFor="let dyncamicLabel of dyncamicLabelList; let i = index;" cdkDrag
                    cdkDragBoundary=".doc-container" style="display: inline-block;">
                    <div class="dynamic-field" [attr.id]="'dynamic-field' + i">
                      <div class="dynamic-field-value" [attr.id]="'dynamic-field-value' +i" style="font-size: 16px;">
                        {{dyncamicLabel.value}}
                      </div>
                      <div class="edit-custom-tool" [attr.id]="'custom-toolbox' + i">
                        <mat-icon class="edit-btn" (click)="openToolbox(i)" aria-hidden="false" aria-label="edit button"
                          fontIcon="edit"></mat-icon>
                        <ul *ngIf="isMenuOpen[i]" class="edit-menu" [style.z-index]="i+1" [attr.id]="'editMenu' + i">
                          <li>
                            color
                            <input type="color" class="color-picker" [attr.id]="'colorPicker' + i"
                              (change)="pickColor($event, i)">
                          </li>
                          <li>
                            font
                            <select class="font-picker" (change)="changeFontFamily($event, i)">
                              <option *ngFor="let font of fonts" [ngStyle]="fontStyle(font)">
                                <link [href]="getFontCSS(font)" rel="stylesheet">
                                {{ fontName(font) }}
                              </option>
                            </select>
                          </li>
                          <li>
                            size
                            <input class="size-picker"
                            matInput
                            type="text"
                            pattern="^[0-9]{0,2}$"
                            [value]="getFontSize(i)"
                            min="16"
                            max="40"
                            length="2"
                            (change)="changeFontSize($event, i)"
                            />
                          </li>
                          <li (click)="removeDynamicLabel(i)">
                            Delete
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="m-t-20">
        <div *ngIf="editCertificateContent">{{editCertificateContent?.id}}</div>
        <div class="submit-btn align-items-center justify-content-end">
          <button mat-raised-button color="primary" type="submit">
            {{ (editCertificateId) ? 'GENERAL.EDIT' : 'GENERAL.SAVE' | translate }}
          </button>
        </div>
      </div>
    </form>
  </div>

  <ng-template #loader>
    <mat-spinner [diameter]="50"></mat-spinner>
  </ng-template>
</div>
  