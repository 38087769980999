import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

export class PaginatorI18n {

    constructor(private readonly translate: TranslateService) { }

    rangeLabel = (page: number, pageSize: number, length: number) => {
      if (length == 0 || pageSize == 0) {
        return `0 van ${length}`;
      }
    
      length = Math.max(length, 0);
    
      const startIndex = page * pageSize;
    
      // If the start index exceeds the list length, do not try and fix the end index to the end.
      const endIndex =
        startIndex < length
          ? Math.min(startIndex + pageSize, length)
          : startIndex + pageSize;
    
      return `${startIndex + 1} - ${endIndex} ${this.translate.instant('LIST.NUMBER_OF')} ${length}`;
    };
    
    getPaginatorIntl() {
      const paginatorIntl = new MatPaginatorIntl();
    
      paginatorIntl.itemsPerPageLabel = this.translate.instant('GENERAL.PER_PAGE');
      paginatorIntl.nextPageLabel = 'Volgende pagina';
      paginatorIntl.previousPageLabel = 'Vorige pagina';
      paginatorIntl.getRangeLabel = this.rangeLabel;
    
      return paginatorIntl;
    }
}
