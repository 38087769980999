import { Component, OnInit } from '@angular/core';
import { BootstrapService } from '@core/services/BootstrapService';

@Component({
  templateUrl: 'root-layout.component.html',
  styleUrls: ['root-layout.component.scss'],
})
export class RootLayoutComponent implements OnInit {

  public ready = false;
  public error: any = null;

  constructor(
    private bootstrapService: BootstrapService,
  ) { }

  async ngOnInit() {
    try {
      await this.bootstrapService.bootstrapData();
      this.ready = true;
    } catch (err) {
      this.error = err;
    }
  }

}
