<div *ngIf="hasPermission('competanseListView')" >
  <div class="competence" [ngClass]="{'competence-pagintion': pagination?.totalPages > 1}" *ngIf="!loading; else loader">
    <mb-competence-list [competences]="competences" (getCompetenceEmit)="setCompetence($event)"></mb-competence-list>
  </div>
  <div class="pagination m-0" *ngIf="pagination?.totalPages > 1">
    <mat-paginator
      [pageSizeOptions]="[5, 10, 25]"
      showFirstLastButtons
      (page)="handlePageEvent($event)"
      [pageSize]="pagination.pageSize"
      [length]="pagination.totalRecords"
      [pageIndex]="pagination.pageNumber"
    ></mat-paginator>
  </div>

  <ng-template #loader>
    <mat-spinner [diameter]="50"></mat-spinner>
  </ng-template>
</div>