import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, ValidatorFn, Validators} from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileUploadComponent } from '@components/file-upload/file-upload.component';
import { FILE_EXTENSIONS } from '@core/constants/constants';
import { StorageEnum } from '@core/constants/storage.enum';
import { ConfirmationDialog } from '@core/services/ConfirmationDialog';
import { PermissionService } from '@core/services/PermissionService';
import { StorageService } from '@core/services/StorageService';
import { CompetenceModel, ICompetence, ICompetenceModel, ICompetencyType, IUpdatecompetency, IcompetencyCategoryList } from '@modules/Competence/competence.model';
import { CompetenceService } from '@modules/Competence/services/competence.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { find, uniqBy } from 'lodash';
import { map, Observable, startWith } from 'rxjs';
import  * as moment  from 'moment';
import { Location } from "@angular/common";

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'mb-competence-add-edit-modal',
  templateUrl: './competence-add-edit-modal.component.html',
  styleUrls: ['./competence-add-edit-modal.component.scss'],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class CompetenceAddEditModalComponent implements OnInit {

  @ViewChild(FileUploadComponent) fileUpload: FileUploadComponent;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  public competenceForm: FormGroup;
  public file: File;
  public acceptableFileType = `${FILE_EXTENSIONS.toString()}`;
  public loading = false;
  public filteredOptions: Observable<ICompetenceModel[]>;
  public applicationCVList: ICompetenceModel[];
  public competencyTypeList: ICompetencyType[];
  public selectedCompetencyType: ICompetencyType;
  public competencyCategoryList: IcompetencyCategoryList[];
  public competencyData: ICompetence;
  public fileDisabled: boolean = false;
  public location: Location;

  constructor(
    public dialogRef: MatDialogRef<CompetenceAddEditModalComponent>,
    private formBuilder: RxFormBuilder,
    private competenceService: CompetenceService,
    private storage: StorageService,
    private confirmationDialog: ConfirmationDialog,
    @Inject(MAT_DIALOG_DATA) public data: ICompetence,
    private permissionService: PermissionService,
  ) {}

  async ngOnInit() {
    await this.getCompetencyType();

    if (this.data) {
      this.competenceForm = this.formBuilder.formGroup(CompetenceModel, this.data);

      const  competencyTypeObj = await this.getCompetencyTypeObj(this.data?.competencyTypeId);
      this.selectedCompetencyType = competencyTypeObj;
      this.getCompetencyCategory(this.data?.competencyTypeId);
      
    } else {
      this.competenceForm = this.formBuilder.formGroup(CompetenceModel, {});
      this.selectedCompetencyType = this.competencyTypeList[0];
    }

    this.requiredField(this.selectedCompetencyType);  
    this.setDateBasedOnMonths();
    await this.getApplicationCVList();

    this.filteredOptions = this.competenceForm.get('competencyName').valueChanges.pipe(
      startWith(''),
      map(name => (name ? this.filter(name) : this.applicationCVList))
    );
  }

  async downloadFile() {
    this.fileDisabled = true;

    let id = this.competenceForm.get('id').value;
    try {
      const competencyData = await this.competenceService.getCompetencyById(id);
      const data = competencyData.result;

      window.location.href = data?.fileUrl;
      this.fileDisabled = false;

    } catch (error){}
    this.fileDisabled = false;
  }

  onClick() {
    this.fileUpload.fileInput.nativeElement.click();
  }

  getFile(file: File) {
    this.file = file;
  }

  dateChange(event: MatDatepickerInputEvent<any>, control: AbstractControl) {
    if (event.value) {
      control.patchValue(event.value.format('yyyy-MM-DDThh:mm:ss'));
    }
  }

  async onSubmit() {
    let formData: FormData = new FormData();
    if(this.competenceForm.value.competencyCategoryId){
      this.changeCompetencyCategoryId(this.competenceForm.value.competencyCategoryId);
    }

    formData.append('formFile', this.file);
    formData.append('competencyName', this.competenceForm.value.competencyName?this.competenceForm.value.competencyName:'');
    formData.append('organizationName', this.competenceForm.value.organizationName?this.competenceForm.value.organizationName:'');
    formData.append('fromDate', this.competenceForm.value.fromDate?this.competenceForm.value.fromDate:'');
    formData.append('toDate', this.competenceForm.value.toDate?this.competenceForm.value.toDate:'');
    formData.append('notes', this.competenceForm.value.notes?this.competenceForm.value.notes:'' );
    formData.append('competencyTypeId', this.competenceForm.value.competencyTypeId?this.competenceForm.value.competencyTypeId:'');
    formData.append('competencyCategoryId', this.competenceForm.value.competencyCategoryId?this.competenceForm.value.competencyCategoryId:'');

    const userId = this.storage.getItem(StorageEnum.UserId);
    
    let result: any
    try {

      this.loading = true;
      if (this.data) {
        let model = this.competenceForm.value;
        const id  = this.data?.id;
        delete model.id;
        const data = await this.updateCompetence(id, model);
        result = data.result;
      } else {
        const data = await this.addCompetence(userId, formData);
        result = data.result;
      }
    } catch (error) {}
    finally {
      this.loading = false;
    }
    this.close(result);
  }

  async updateCompetence(id: string, formData: IUpdatecompetency) {
    return await this.competenceService.updateCompetence(id, formData);
  }

  async addCompetence(userId: number, formData: FormData) {
    const arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    const params = {
      arrangementId,
      userId,
    };
    return await this.competenceService.addCompetence(formData, params);
  }

  openConfirmationModal() {
    const title = 'COMPETENCE.DELETE_CONFIRMATION_MSG';
    this.confirmationDialog.confirm(title, false).then(async (result: boolean) => {
      if (result) {
        await this.competenceService.deleteCompetence(this.data?.id);
        this.close(this.data);
      }
    });
  }

  close(result: ICompetence = null) {
    //reset query prams name, in user-profile url using replace history state 
    let userId = this.storage.getItem(StorageEnum.UserId);
    let url = '/user-profile/'+userId;
    history.replaceState({ userCompetenceList: history.state.userCompetenceList, selectedCompetenceIndex: null}, '',url)    
    this.dialogRef.close(result);
  }
  
  hasPermission(type:string) {
    return this.permissionService.hasPermission(type);
  }

  inputClick() {
    this.autocomplete.closePanel();
  }

  private filter(name: string) {
    if(!name){
      return;
    }
    const filterValue = name.toLowerCase();
    return this.applicationCVList.filter(option => option.competencyName.toLowerCase().includes(filterValue));
  }
  
  getSelectedOption(value: string) {
    if (value == '' || value == null) {
      return;
    }
    this.getApplicationCVList();
  }

  async getApplicationCVList() {
    const params = {
      arrangementId: this.storage.getItem(StorageEnum.ArrangementId),
    };

    try {
      const data = await this.competenceService.getCompetences(params);
      this.applicationCVList = data.result;
      const filterArray = uniqBy(data?.result,'competencyName');  

      this.applicationCVList = filterArray;  
    } catch (error) {}
  }

  
  async getCompetencyType() {
    this.competencyTypeList = [];
    const data = await this.competenceService.getCompetencyType();
    if(data) {
      this.competencyTypeList = data.result;
    }
  }

  changeCompetencyCategoryId(competencyCategoryId: string){
    const competenceName = this.competencyCategoryId(competencyCategoryId);
   this.competenceForm.controls.competencyName.patchValue(competenceName);
  }

  competencyCategoryId(id) {
    return find(this.competencyCategoryList, ele => ele.id === id).name
  }

  getCompetencyTypeObj(id) {
    return find(this.competencyTypeList, ele => ele.id === id)
  }

  changeCompetencyType(competencyTypeId: string) {
    const  competencyTypeObj = this.getCompetencyTypeObj(competencyTypeId);
    let competencyType = this.competenceForm?.value?.competencyTypeId;

    this.competenceForm.reset();
    this.file = null;
    this.competenceForm.controls.competencyTypeId.setValue(competencyType);
    this.selectedCompetencyType = competencyTypeObj;
    this.setDateBasedOnMonths();
    this.getCompetencyCategory(competencyTypeId);
    this.requiredField(competencyTypeObj);
  }

  async getCompetencyCategory(id: string) {
    this.competencyCategoryList=[];
    const data = await this.competenceService.getCompetencyCategory(id);
    if(data) {
      this.competencyCategoryList= data.result;
    }
  }

  requiredField(competencyTypeObj: ICompetencyType) {
    const competencyCategoryId = this.competenceForm.controls.competencyCategoryId;
    const competencyName = this.competenceForm.controls.competencyName;
    const toDate = this.competenceForm.controls.toDate;
    const fromDate = this.competenceForm.controls.fromDate;

    if(competencyTypeObj?.isTitleDropDownView) {
      this.updateValidators(competencyCategoryId, Validators.required);
      this.updateValidators(competencyName, null, true);
    } else {
      this.updateValidators(competencyName, Validators.required);
      this.updateValidators(competencyCategoryId, null, true);
    }

    if(competencyTypeObj?.dateVisible && competencyTypeObj?.dateRequired) {
      this.updateValidators(toDate, Validators.required);
      this.updateValidators(fromDate, Validators.required);
    } else {
      this.updateValidators(toDate, null, true);
      this.updateValidators(fromDate, null, true);
    }
  }
  
  updateValidators(control: AbstractControl, validators: ValidatorFn | ValidatorFn[] | null, clearValidators: boolean = false) {
    if(clearValidators) {
      control.clearValidators();
    } else {
      control.setValidators(validators);
    }
    control.updateValueAndValidity({ onlySelf: true });
  }

  disableForm() {
    if(this.data) {
      return this.competenceForm.invalid || this.loading ;
    } else {
      return this.competenceForm.invalid || this.loading || (this.selectedCompetencyType?.uploadRequired && !this.file)
    }
  }

  setDateBasedOnMonths() {
    const months = this.selectedCompetencyType?.durationInMonth;
    const toDate = this.competenceForm.controls?.toDate;
    const fromDate = this.competenceForm.controls?.fromDate;

    if(months > 0 && !toDate.value && !fromDate.value) {
      let todaysDate = moment(new Date()).format("yyyy-MM-DDThh:mm:ss");
      let endDate = new Date().setMonth(months);
      const endDateDuration = moment(endDate).subtract(1, 'd').format("yyyy-MM-DDThh:mm:ss");

      fromDate.patchValue(todaysDate);
      toDate.setValue(endDateDuration);
    }
  }

}
