import { CompetenceStatusEnum } from "@modules/Competence/competence.model";

export const AUTO_SAVE_TIMOUT = 1800000; // in ms

export const LANGUAGES = {
   nb: {
    name: 'NORWEGIAN',
    codes: {
     base: 'nb-NO',
     dnt: 'nb-NO_dnt',
     tvaksjonen: 'nb-NO_tvaksjonen',
    },
   },
   en: {
    name: 'ENGLISH',
    codes: {
     base: 'en-GB',
     dnt: 'en-GB_dnt',
     tvaksjonen: 'en-GB_tvaksjonen',
    }
   },
   se: {
    name: 'SWEDISH',
    codes: {
     base: 'sv-SE',
     dnt: 'sv-SE_dnt',
     tvaksjonen: 'sv-SE_tvaksjonen',
    }
   },
   dk: {
    name: 'DANISH',
    codes: {
     base: 'da-DK',
     dnt: 'da-DK_dnt',
     tvaksjonen: 'da-DK_tvaksjonen',
    }
   }
  };

export const COMPETENCE_STATUS_COLOR = {
  [CompetenceStatusEnum.Green]: '#55ce63',
  [CompetenceStatusEnum.Yellow]: '#ffeb3b',
  [CompetenceStatusEnum.Red]: '#e83232',
};

export const OTPLENGTH = 6;

export const MAX_FILE_SIZE_TO_UPLOAD = 5 * 1024 * 1024; // 5mb

export const FILE_EXTENSIONS = [
  '.pdf',
  '.jpeg',
  '.png',
  '.jpg',
];