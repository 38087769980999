<div class="mail-details-wrapper w-100">
    <div  class="d-flex align-items-center h-3 b-b p-15 m-b-5">
      <button mat-icon-button (click)="removeClass()" class="d-lg-none btn-back">
        <mat-icon>arrow_back</mat-icon>
      </button>
      <div class="ml-auto d-flex">
        <button
          mat-icon-button
          [matMenuTriggerFor]="menu1"
          aria-label="Example icon-button with a menu"
        >
          <mat-icon>more_vert</mat-icon>
        </button>
        <mat-menu #menu1="matMenu">
          <span class="btn-delete" mat-menu-item (click)="deleteMessage('Trash')">
            <mat-icon>delete</mat-icon>Trash
          </span>
        </mat-menu>
      </div>
    </div>
    <div class="mail-details">
      <div class="p-20 ">
        <mat-list class="file-list m-b-10">
          <ng-container *ngFor="let file of mailDetail?.files; let i = index;">
            <mat-list-item class="p-0">
              <mat-icon *ngIf="isFileTypeImage(file.fileName); else otherFileType" class="vm">image</mat-icon>
              <ng-template #otherFileType>
                <mat-icon class="vm">picture_as_pdf</mat-icon>
              </ng-template>
              <span class="vm"> {{file.fileName}}</span>  
            </mat-list-item>
            <mat-divider></mat-divider>
          </ng-container>
        </mat-list>
          <div class="d-flex align-items-start">
              <img
              *ngIf="mailDetail && mailDetail.fileUrl"
              [src]="mailDetail.fileUrl"
              class="img-circle"
              alt="userimg"
              width="50px"
              height="50px"
              />
              <div class="m-l-10">
              <div class="d-flex align-items-center">
                  <h5 *ngIf="mailDetail && mailDetail.createdByName" class="m-0 font-bold created-name">{{ mailDetail.createdByName }}</h5>
              </div>
              </div>
              <div class="ml-auto text-right">
              <span *ngIf="mailDetail && mailDetail.created" class="mail-created text-muted font-14 text-nowrap">{{
                  mailDetail.created | date: 'dd-MMM yy'
              }}</span>
              </div>
          </div>
          <p *ngIf="mailDetail && mailDetail.documentTitle" class="mail-title m-b-0 font-bold text-truncate">
              {{ mailDetail.documentTitle }}
          </p>
          <p>
              <span *ngIf="mailDetail && mailDetail.documentContent" class="font-medium mail-content">
              {{ mailDetail.documentContent }}
              </span>
          </p>
      </div>
    </div>
</div>
  