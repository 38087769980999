import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { CourseDetailsComponent } from './component/course-details/course-details.component';
import { CourseListComponent } from './component/course-list/course-list.component';

@NgModule({
  declarations: [
    CourseDetailsComponent,
    CourseListComponent,
  ],
  imports: [
    CoreModule,
  ],
  exports: [
    CourseDetailsComponent,
    CourseListComponent,
  ],
})
export class CourseModule {
}
