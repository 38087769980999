<div class="user-profile">
  <ng-container *ngIf="!loading; else loader">
    <mb-profile-info *ngIf="hasPermission('profileView') "[userDetails]="userDetails" [isCompetenceListAvailable]="competenceList?.length" (getProfileInfoEmit)="getUserDetails()"></mb-profile-info>
    <mb-profile-group
      *ngIf="showProfileGroup() && !competenceList?.length"
      [profileGroupInfo]="profileGroupInfo"
      (profileGroupEmitChanges)="getProfileGroupInfo()"
    ></mb-profile-group>
    <div *ngIf="competenceList?.length">
      <mb-competence-list
        *ngIf="hasPermission('profileCompetanseView')"
        [competences]="competenceList"
        [isCompetenceImage]="true"
        [showStatus]="true"
        [selectedCompetenceIndex]="selectedCompetenceIndex"
        (getCompetenceEmit)="setCompetence($event)"
      ></mb-competence-list>
    </div>
  </ng-container>
</div>

<ng-template #loader>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>
