import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FILE_EXTENSIONS, MAX_FILE_SIZE_TO_UPLOAD } from '@core/constants/constants';

@Component({
  selector: 'mb-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {

  public files: FileList;
  @Input() acceptableFileType: string;
  @Input() imageResize: boolean;
  @Output() fileChangeEvent = new EventEmitter<File>();

  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
  ) {}

  ngOnInit() {
    console.log('imageResize',this.imageResize);

  }

  async fileChange(event: Event) {
    let resultFile: File;
    const target = event.target as HTMLInputElement;
    this.files = target.files;
    if (this.files?.length) {

      if (this.isFileIsImage(this.files[0]) ) {
        const config = {
          file: this.files[0],
          maxSize: 500,
        };

        if(this.imageResize) {
          const resizedImage: any = await this.resizeImage(config)
          resultFile  = new File([resizedImage], this.files[0].name, { type:"image/jpeg", lastModified: new Date().getTime() });
          console.log(resultFile);

          const fileExt = this.files[0].name.substring(this.files[0].name.lastIndexOf('.'));
          const fileSize = (this.isFileIsImage(this.files[0])) ? resultFile.size : this.files[0].size;
          if ((fileSize > MAX_FILE_SIZE_TO_UPLOAD) || !FILE_EXTENSIONS.includes(fileExt.toLowerCase())) {
            this.files = null;
            return;
          }
        } else {
          resultFile  = this.files[0];
          const fileExt = this.files[0].name.substring(this.files[0].name.lastIndexOf('.'));
          const fileSize = (this.isFileIsImage(this.files[0])) ? resultFile.size : this.files[0].size;

          if ( !fileSize || !FILE_EXTENSIONS.includes(fileExt.toLowerCase())) {
            this.files = null;
            return;
          }
        
        }
      }      
    }

    this.fileChangeEvent.emit(this.isFileIsImage(this.files[0]) ? resultFile : this.files[0]);
  }

  isFileIsImage(file: File) {
    return file.type.split('/')[0] === 'image';
  }

  resizeImage(settings: IResizeImageOptions) {
    const file = settings.file;
    const maxSize = settings.maxSize;
    const reader = new FileReader();
    const image = new Image();
    const canvas = document.createElement('canvas');
    // const dataURItoBlob = (dataURI: string) => {
    //   const bytes = dataURI.split(',')[0].indexOf('base64') >= 0 ?
    //       atob(dataURI.split(',')[1]) :
    //       unescape(dataURI.split(',')[1]);
    //   const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
    //   const max = bytes.length;
    //   const ia = new Uint8Array(max);
    //   for (var i = 0; i < max; i++) ia[i] = bytes.charCodeAt(i);
    //   return new Blob([ia], {type:mime});
    // };
    const resize = async () => {
      let width = image.width;
      let height = image.height;
  
      if (width > height) {
          if (width > maxSize) {
              height *= maxSize / width;
              width = maxSize;
          }
      } else {
          if (height > maxSize) {
              width *= maxSize / height;
              height = maxSize;
          }
      }
  
      canvas.width = width;
      canvas.height = height;
      canvas.getContext('2d').drawImage(image, 0, 0, width, height);
      let dataUrl = canvas.toDataURL('image/jpeg');
      return await (await fetch(dataUrl)).blob(); ;
      // return dataURItoBlob(dataUrl);
    };
  
    return new Promise((ok, no) => {
        if (!file.type.match(/image.*/)) {
          no(new Error("Not an image"));
          return;
        }
  
        reader.onload = (readerEvent: any) => {
          image.onload = () => ok(resize());
          image.src = readerEvent.target.result;
        };
        reader.readAsDataURL(file);
    })    
  };

}

interface IResizeImageOptions {
  maxSize: number;
  file: File;
}