import { MatSnackBar, MatSnackBarConfig, MatSnackBarRef } from '@angular/material/snack-bar';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavigationEnd, Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})

export class SnackbarService {

  private snackbarRef: MatSnackBarRef<any>;

  constructor(
    private snackBar: MatSnackBar,
    private translate: TranslateService,
    private router: Router,
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.snackBar.dismiss();
      }
    });
  }

  open(message: string, action: string = 'Ok', config?: MatSnackBarConfig<any>) {
    config = {
      horizontalPosition: 'left',
      duration: 5000,
      ...config,
    };
    if (message) {
      this.translate.get(message).subscribe((msg: string) => {
        this.snackbarRef = this.snackBar.open(msg, action, config);
      });
    }
  }

  afterDismissed() {
    return this.snackbarRef.afterDismissed();
  }
}
