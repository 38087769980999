import { Injectable } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { IFileModel, IServerResponse } from '@core/core.interface';
import { IUserDetails } from '@core/models/user-response.model';
import { HttpService } from '@core/services/HttpService';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { IProfileGroup, IProfileGroupOption, IProfileGroupSection, ProfileGroup, ProfileModel } from '../profile.model';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {

  public userProfileImgSub = new BehaviorSubject<SafeUrl>(null);

  constructor(
    private http: HttpService,
  ) { }

  async getUserDetailById(id: number) {
    return await firstValueFrom(this.http.get(`users/${id}`)) as IServerResponse<IUserDetails>;
  }

  async updateUserDetail(arrangementId: number, userId: number, model: ProfileModel) {
    return await firstValueFrom(this.http.post(`Users/UpdateUser/${arrangementId}/${userId}`, model));
  }

  async updateProfileImage(arrangementId: number, userId: number, formData: FormData) {
    return await firstValueFrom(this.http.post(`Users/UploadProfileFile/${arrangementId}/${userId}`, formData)) as IServerResponse<IFileModel>;
  }

  async getProfileGroupInfo(userId: number) {
    return await firstValueFrom(this.http.get(`Category/GetUserCategorySectionRole/${userId}`)) as IServerResponse<IProfileGroup[]>;
  }

  async getCategory(arrangementId: number) {
    return await firstValueFrom(this.http.get(`Category/GetCategory/${arrangementId}`)) as IServerResponse<IProfileGroupOption[]>;
  }

  async getCategorySection(arrangementId: number, categoryId: number) {
    return await firstValueFrom(this.http.get(`Section/GetCategorySection/${arrangementId}/${categoryId}`)) as IServerResponse<IProfileGroupSection>;
  }

  async getProfileRoles(arrangementId: number, groupId: number) {
    return await firstValueFrom(this.http.get(`Role/GetSectionRole/${arrangementId}/${groupId}`)) as IServerResponse<IProfileGroupOption[]>;
  }

  async addNewProfileGroup(arrangementId: number, userId: number, model: ProfileGroup) {
    return await firstValueFrom(this.http.post(`Section/CreateUserSectionGroup/${arrangementId}/${userId}`, model)) as boolean;
  }
  
  async UpdateEmailForumNotification(arrangementId: number, userId: number, emailNotification: boolean) {
    return await firstValueFrom(this.http.post(`Users/UpdateEmailForumNotification/${arrangementId}/${userId}`, {emailNotification})) ;
  }

}
