import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { SafeUrl } from '@angular/platform-browser';
import { FileUploadComponent } from '@components/file-upload/file-upload.component';
import { StorageEnum } from '@core/constants/storage.enum';
import { IUserDetails } from '@core/models/user-response.model';
import { ImageService } from '@core/services/ImageService';
import { StorageService } from '@core/services/StorageService';
import { ProfileModel } from '@modules/Profile/profile.model';
import { ProfileService } from '@modules/Profile/services/profile.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { GDPRModalComponent } from '../gdpr-modal/gdpr-modal.component';

@Component({
  selector: 'mb-profile-info',
  templateUrl: './profile-info.component.html',
  styleUrls: ['./profile-info.component.scss'],
})
export class ProfileInfoComponent implements OnInit, OnChanges {

  @Input() userDetails: IUserDetails;
  @Input() isCompetenceListAvailable = false;
  @Output() getProfileInfoEmit = new EventEmitter();

  @ViewChild(FileUploadComponent) fileUpload: FileUploadComponent;

  public showProfileEditForm = false;
  public profileImage: SafeUrl;
  public acceptableFileType = `${['.png', '.jpg', '.jpeg'].toString()}`;
  public profileInfoForm: FormGroup;
  public arrangementId: number;
  public userId: number;
  public loading = false;
  public profileImgLoading = false;
  public forumEmailNotification = new FormControl();

  constructor(
    private imageService: ImageService,
    private formBuilder: RxFormBuilder,
    private profileService: ProfileService,
    private storage: StorageService,
    public dialog: MatDialog,
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.userDetails.currentValue !== changes.userDetails.previousValue) {
      this.profileImage = this.imageService.loadImage(this.userDetails.fileUrl);
    }
  }

  ngOnInit() {
    this.profileInfoForm = this.formBuilder.formGroup(ProfileModel, this.userDetails);
    this.arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    this.userId = this.storage.getItem(StorageEnum.UserId);
    const user = this.storage.getItem(StorageEnum.User);
    this.forumEmailNotification.setValue(user?.forumEmailNotification);

    this.forumEmailNotification.valueChanges.subscribe((emailNotification)=>{
      this.profileService.UpdateEmailForumNotification(this.arrangementId, this.userId, emailNotification);
      this.userDetails.forumEmailNotification = emailNotification;
      this.storage.setItem(StorageEnum.User, this.userDetails);
    });

  }

  onClick() {
    this.fileUpload.fileInput.nativeElement.click();
  }

  async getFiles(file: File) {
    if (!file) {
      return;
    }

    try {
      this.profileImgLoading = true;
      let formData: FormData = new FormData();
  
      formData.append('formFile', file);
  
      const data = await this.profileService.updateProfileImage(this.arrangementId, this.userId, formData);
      this.profileImage = this.imageService.loadImage(data.result.fileDataUrl);
      const storeUserData = this.storage.getItem(StorageEnum.User);
      storeUserData.profileImage = data.result.fileDataUrl;
      this.storage.setItem(StorageEnum.User, storeUserData);
      this.profileService.userProfileImgSub.next(this.profileImage);
    } catch (error) {}
    finally {
      this.profileImgLoading = false;
    }
  }

  async onSubmit() {
    try {
      this.loading = true;
      const model = this.profileInfoForm.value;
      model.name = `${model.lastName}, ${model.firstName}`;
      await this.profileService.updateUserDetail(this.arrangementId, this.userId, model);
      this.showProfileEditForm = false;
      this.getProfileInfoEmit.emit();
    } catch (error) { }
    finally {
      this.loading = true;
    }
  }

  openGdprModal() {
    const dialogRef = this.dialog.open(GDPRModalComponent);
    dialogRef.afterClosed().subscribe((res: boolean) => {
    });
  }

}
