import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularMaterialModule } from '@core/lib/angular-material.module';
import { ComponentsModule } from '@components/components.module';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';

import { CourseCreateStepOnePageComponent } from './course-action/course-create-step1/course-create-step1.page';
import { CourseCreateStepTwoPageComponent } from './course-action/courses-create-step2/course-create-step2.page';
import { CourseCreatePageComponent } from './course-action/course-create/course-create.page';

import { CourseRoutes } from './courses.routing';
import { CoursePageComponent } from './course/course.page';
import { MatMenuModule } from '@angular/material/menu';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CourseModule } from '@modules/Course/course.module';
import { MessageModule } from '@modules/Message/message.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMaterialModule,
    ComponentsModule,
    NgxPaginationModule,
    MessageModule,
    CourseModule,
    MatMenuModule,
    PdfViewerModule,
    RouterModule.forChild(CourseRoutes),
  ],
  declarations: [
    CoursePageComponent,
    CourseCreatePageComponent,
    CourseCreateStepOnePageComponent,
    CourseCreateStepTwoPageComponent,
  ],
})
export class CoursesModule {}
