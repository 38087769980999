<mat-card *ngIf="competences?.length; else noDataToShow">
  <div mat-dialog-title class="text-center title" *ngIf="showStatus">
    <h3 class="text-center font-bold">{{ 'MAIN_MENU.COMPETENCE' | translate }}</h3>
  </div>
  <mat-card-content class="p-0">
    <ng-container *ngFor="let competence of competences; let i = index">
      <div id="competence{{i}}" class="competence-info">
        <div fxLayoutAlign="space-between">
          <div>
            <h3 class="m-0 align-items-center">
              <img *ngIf="isCompetenceImage && competence.fileUrl" [src]="competence.fileUrl" class="competence-img" alt="competence-img">
              {{competence.competencyName}}
            </h3>
            <p>{{ 'COMPETENCE.ISSUED_BY' | translate }} {{competence.organizationName}}</p>
            <p class="m-b-0" *ngIf="competence?.toDate">
              {{ 'COMPETENCE.EXPIRY_DATE' | translate }} {{competence.toDate | date: 'dd.MM.yyyy'}}
            </p>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-between center">
            <div *ngIf="hasPermission('competanseAttachmentView')" class="attachement-icon" [style.visibility]="competence.fileName ? 'visible' : 'hidden'" (click)="getAttachment(competence?.id)">
              <mat-icon *ngIf="isFileTypeImage(competence.fileName); else otherFileType">image</mat-icon>
              <ng-template #otherFileType>
                <mat-icon>picture_as_pdf</mat-icon>
              </ng-template>
            </div>
            <div class="mat-icon-action">
              <mat-icon *ngIf="hasPermission('competanseUpdate')" class="cursor-pointer" (click)="openCompetenceFormModal(competence)">edit</mat-icon>
              <mat-icon *ngIf="showWarnIcon(competence)" [style.color]="iconColor[competence.iconColor]">error_outline</mat-icon>
              <mat-icon *ngIf="showApprovedIcon(competence)" [style.color]="iconColor[competence.iconColor]">check_circle_outline</mat-icon>
            </div>
          </div>
        </div>
      </div>
      <mat-divider class="divider"></mat-divider>
    </ng-container>
  </mat-card-content>
</mat-card>

<button *ngIf="hasPermission('competanseCreate')" class="add-competence" mat-fab color="primary" aria-label="add competence" (click)="openCompetenceFormModal()">
  <mat-icon>add</mat-icon>
</button>

<ng-template #noDataToShow>
  <h4 class="text-muted text-center">{{ 'GENERAL.NO_DATA' | translate }}</h4>
</ng-template>