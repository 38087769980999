import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { ComponentsModule } from '@components/components.module';
import { RouterModule } from '@angular/router';
import { DashboardPageComponent } from './dashboard/dashboard.page';
import { ContactsPageComponent } from './contacts/contacts.page';
import { DashboardModule } from '@modules/Dashboard/dashboard.module';
import { DatePipe } from '@angular/common';
import { PageNotFoundComponent } from './page-not-found/page-not-found.page';
import { UserProfilePageComponent } from './user-profile/user-profile.page';
import { ContactModule } from '@modules/Contact/contact.module';
import { CompetencePageComponent } from './competence/competence.page';
import { CompetenceModule } from '@modules/Competence/competence.module';
import { ProfileModule } from '@modules/Profile/profile.module';
import { CompetenceSearchPageComponent } from './competence-search/competence-search.page';
import { CollectorSiteComponent } from './collector-site/collector-site.component';
import { MessageModule } from '@modules/Message/message.module';
import { CoursesModule } from './course-and-certificates/courses.module';

@NgModule({
  declarations: [
    DashboardPageComponent,
    ContactsPageComponent,
    PageNotFoundComponent,
    UserProfilePageComponent,
    CompetencePageComponent,
    CompetenceSearchPageComponent,
    CollectorSiteComponent,
  ],
  imports: [
    CoreModule,
    ComponentsModule,
    RouterModule,
    DashboardModule,
    ContactModule,
    CompetenceModule,
    ProfileModule,
    MessageModule,
    CoursesModule,
  ],
  providers: [
    DatePipe,
  ],
})

export class PageModule {
}
