import { Component, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { IMailList } from '@modules/Message/message.model';
import { MessageService } from '@modules/Message/services/message.service';

@Component({
  selector: 'mb-message-list',
  templateUrl: './message-list.component.html',
  styleUrls: ['./message-list.component.scss']
})
export class MessageListComponent {
  @Input() mailList: IMailList;

  @Output() selectedMailEvent = new EventEmitter<IMailList>();

  constructor(
    public messageService: MessageService,
    ) {

  }

  getselectedMail(mail: IMailList) {
    this.messageService.addClass = true;
    this.selectedMailEvent.emit(mail);
  }
}