import {
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageEnum } from "@core/constants/storage.enum";
import { StorageService } from "@core/services/StorageService";
import { RxFormBuilder } from "@rxweb/reactive-form-validators";
import { DocumentType, TemplateType } from "@modules/Message/message.model";
import { CreateCourse, IDynamicfieldsList, IDyncamicLabel, IPaperSizeRation } from "@modules/Course/course.model";
import { MessageService } from "@modules/Message/services/message.service";
import { FileUploadComponent } from "@components/file-upload/file-upload.component";
import { Subscription } from "rxjs";
import { DashboardService } from "@modules/Dashboard/services/dashboard.service";
import { ThemeColorService } from "@modules/Dashboard/services/theme-color.service";
import { CdkDragDrop } from "@angular/cdk/drag-drop";
import { CourseService } from "@modules/Course/services/course.service";
import { Location } from "@angular/common";
import { DomSanitizer } from "@angular/platform-browser";
import { FONTS } from "../../../../../core/constants/font-list";
import html2canvas from 'html2canvas';

@Component({
  selector: "mb-course-create-step2",
  templateUrl: "./course-create-step2.page.html",
  styleUrls: ["./course-create-step2.page.scss"],
})
export class CourseCreateStepTwoPageComponent implements OnInit, OnDestroy {
  form: FormGroup;
  @ViewChild(FileUploadComponent) fileUpload: FileUploadComponent;
  @ViewChild("docContainer") draggableConatiner: ElementRef;

  public acceptableFileType = `${[".pdf", ".png", ".jpg", ".jpeg"].toString()}`;
  public acceptableImageType = `${[".png", ".jpg", ".jpeg"].toString()}`;
  public files: File[] = [];
  public templateEmailList: any = [];
  public templateSmsList: any = [];
  public formSubscription: Subscription[] = [];
  public createMessagePrevStepDetails;
  public documentType = DocumentType;
  public templateType = TemplateType;
  public isSubmitBtnVisible: boolean = true;
  public dyncamicLabelList: IDyncamicLabel[];
  public dynamicFiledList: IDynamicfieldsList[];
  public imageUrl: any; //used as string or ArrayBuffer type
  public reSize: boolean = false;
  public typeId: number;
  public documentSizeList: IPaperSizeRation[];
  public fonts = FONTS;
  public isImage: boolean;
  public paperSize: any;
  public fontUrl = "https://fonts.googleapis.com/css?family=";
  public fontStyleList = ['Poppins'];
  public isMenuOpen = [];
  public selectedFontToolIndex: number;
  public editCertificateContent: any;
  public modelFontSize: string;
  public editCertificateId: string;

  constructor(
    private formBuilder: RxFormBuilder,
    private storage: StorageService,
    private sanitizer: DomSanitizer,
    public router: Router,
    public route: ActivatedRoute,
    public messageService: MessageService,
    public dashboardService: DashboardService,
    public themeColorService: ThemeColorService,
    public elementRef: ElementRef,
    public courseService: CourseService,
    public location: Location,
  ) {
    this.dyncamicLabelList = [];
    this.dynamicFiledList = [];
    this.imageUrl = "";
    this.reSize = false;
    this.sanitizer = sanitizer;
    this.documentSizeList = [
      // { id: 0, label: "A0", width: '84.1cm', height: '118.8cm' },
      // { id: 1, label: "A1", width: '59.4cm', height: '84.1cm' },
      // { id: 2, label: "A2", width: '42cm', height: '59.4cm' },
      { id: 3, label: "A3", width: '29.7cm', height: '42cm' },
      { id: 4, label: "A4", width: '21cm', height: '29.7cm' },
      { id: 5, label: "A5", width: '14.8cm', height: '21cm' },
      // { id: 6, label: "A6", width: '10.5cm', height: '14.8cm' },
      // { id: 7, label: "A7", width: '7.4cm', height: '10.5cm' },
      // { id: 8, label: "A8", width: '5.2cm', height: '7.4cm' },
      { id: 9, label: "1:1", width: '14.8cm', height: '14.8cm' },

    ];
    this.modelFontSize = "16";
  }

  ngOnInit(): void {
    this.createForm();

    const userModuleDetails =this.storage.getItem(StorageEnum.DashboardModuleDetails) || null;
    this.dashboardService.setDashboardModule(
      userModuleDetails.permissionReadDto
    );
    this.dashboardService.setDashboardCollectorModule(
      userModuleDetails.collectorPermissionReadDto
    );
    this.changeTheme(userModuleDetails.menuColorCode);
    this.getDynamicFields();
    this.route.queryParams.subscribe((params) => (this.typeId = params.typeId));
    this.editCertificateContent = {};
    this.route.queryParams.subscribe((params) => {
      if(params?.id){
        this.editCertificateId = params.id;
        this.getCourseCertificate(params.id);        
      } else {
        this.editCertificateId = '';
      }
    });

    if(this.paperSize == null) {
      this.paperSize = this.documentSizeList[0];
      this.form.get("pagePaperSize").setValue(this.documentSizeList[0]);
    }

    this.onChange();
  }

  onChange() {
    this.form.get("pagePaperSize").valueChanges.subscribe((value) => {
      if(value) {
        this.paperSize = value;
      } 
    });
  }

  createForm() {
    this.form = this.formBuilder.formGroup(new CreateCourse());
    this.createMessagePrevStepDetails = this.storage.getItem(StorageEnum.CreateMessageDetails) || {};
  }

  getDynamicFields() {
    const arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    this.courseService.getDynamicFileds(arrangementId).then((res: any) => {
      this.dynamicFiledList = res.result;
    });
  }

  ngOnDestroy() {
    this.formSubscription.map(
      (subscriptionItem) => subscriptionItem.unsubscribe
    );
  }

  changeTheme(primary: string) {
    this.themeColorService.savePrimaryColor(primary);
  }

  drop(event: CdkDragDrop<string[]>) {
    //TO DO dropped element to change styling.. etc
  }

  addFieldToContainer(label) {
    let index = this.dyncamicLabelList.length + 1;
    let dynamicLabelObj: IDyncamicLabel = { id: index, value: label.value };
    this.dyncamicLabelList.push(dynamicLabelObj);
  }

  onClickAttachmentFile() {
    const fileInputEle = <HTMLElement>this.fileUpload.fileInput.nativeElement;
    fileInputEle.setAttribute("accept", this.acceptableFileType);
    this.fileUpload.fileInput.nativeElement.click();
  }

  /* TO DO Call to get Image attachment */
  onClickAttachmentImage() {
    const fileInputEle = <HTMLElement>this.fileUpload.fileInput.nativeElement;
    fileInputEle.setAttribute("accept", this.acceptableImageType);
    this.fileUpload.fileInput.nativeElement.click();
  }

  loadPDF(event: any) {
    const file: File = event;
    const reader = new FileReader();
    reader.onload = (e: any) => {
      this.imageUrl = e.target.result;
    };
    reader.readAsArrayBuffer(file)

    this.files = [];
    this.files.push(this.imageUrl);
  }

  pdfPageRendered(event) {
    setTimeout(()=>{
      html2canvas(document.querySelector("pdf-viewer .page") as HTMLElement).then((canvas: any) => {
        this.getCanvasToImage(canvas)
      });
    }, 400);

  }

  /* covert pdf to image */
  getCanvasToImage(canvas:any){
    let ctx = canvas.getContext('2d');
    ctx.scale(3, 3);
    let image = canvas.toDataURL("image/png").replace("image/png", "image/png");
    this.isImage = true;
    this.imageUrl = image;
  }

  getFileByType(event: any) {
    var mimeType = event.type;

    if(mimeType == 'application/pdf'){
      this.isImage = false;
      this.loadPDF(event);
    }

    if (mimeType.match(/image\/*/) != null) {
      this.isImage = true;
      this.getFiles(event);
    } else {
      return;
    }
  }
  
  /* Get image files */
  getFiles(file: any) {
    if (!file) {
      return;
    }

    var mimeType = file.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }

    let reader = new FileReader();
    reader.onload = () => {
      // when file has loaded
      var img = new Image();

      img.src = reader.result as string; // This is the data URL
      this.imageUrl = reader.result;
    };
    reader.readAsDataURL(file);
    this.files.push(file);
  }

  async onSubmit() {
    if (this.form.invalid) {
      return;
    }

    let modelContent = document.getElementsByClassName("modal-content");
    let modelHtml = (document.querySelector(".modal-content") as HTMLElement);
    modelHtml.style.width = modelContent[0].clientWidth+'px';
    modelHtml.style.height = modelContent[0].clientHeight+'px';
    modelHtml.style.margin = '0 auto';

    //Remove edit style tool of labels
    let editorElements = document.querySelectorAll(".edit-custom-tool");
    const rows = Array.from(editorElements) as HTMLElement[];
    rows.forEach((item, index)=>{
      item.innerHTML='';
    });

    let certificateContent = document.getElementsByClassName("page-certificate");
    let dynamicFields = document.querySelectorAll(".dynamic-field-box");

    const dynamicFieldsRows = Array.from(dynamicFields) as HTMLElement[];
    dynamicFieldsRows.forEach((item, index)=>{
      if( index > 0){
        item.style.margin='0 0 0 16px'; //edit icon size should add margin to labels
      }
    });

    let htmlContent = '<html><head><meta charset="utf-8">';
    htmlContent += '<link rel="stylesheet" href="'+this.fontUrl;
    this.fontStyleList.forEach((item, index)=>{
      if( index > 0 ){
        htmlContent +='|';
      }
      htmlContent += item+'';
    });

    htmlContent += '&display=swap></head><body style="font-family: Poppins, sans-serif"; font-weight: "400";>';
    htmlContent += certificateContent[0].outerHTML+'</body></html>';
    
    const model = this.form.value;
    model.courseCertificatePdfTemplate = htmlContent;
    model.pagePaperSize = model.pagePaperSize.id;

    this.isSubmitBtnVisible = false;
    if(!this.editCertificateId){
      this.createCourseCertificate(model);
    } else {
      model.id = this.editCertificateId;
      this.updateCourseCertificate(model)
    }
  }

  async createCourseCertificate(model) {
    try {
      const arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
      const res = await this.courseService.createCourseCertificate(
        arrangementId,
        this.typeId,
        model
      );

      if (res) {
        this.isSubmitBtnVisible = true;
        this.form.reset();
        this.router.navigate(["course"]);
        this.location.replaceState("/dashboard", "", null);
      } else {
        this.isSubmitBtnVisible = true;
      }
  
    } catch (error) { }
  }

  pickColor(event, i) {
    const dynamicObject = document.getElementById(`dynamic-field-value${i}`);
    dynamicObject.style.color = event.target.value;
  }

  removeDynamicLabel(i: number) {
    this.isMenuOpen[i] = false;
    document.getElementById(`dynamic-field${i}`).remove();
    this.dyncamicLabelList.splice(i, 1);
    this.selectedFontToolIndex = null;
  }

  getFontCSS(font: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.fontUrl + font);
  }

  fontName(font: string) {
    return font.replaceAll("+", " ").replaceAll(":", " ");
  }

  fontStyle(font: string) {
    const idx = font.indexOf(":");
    const style = {
      "font-family": font.replaceAll("+", " "),
    };
    if (idx !== -1) {
      style["font-weight"] = font.slice(idx).replaceAll(":", " ");
    }
    return style;
  }

  changeFontFamily(event, i) {
    //Add link style of font family in to the html document 
    this.fontStyleList= Array.from(new Set(this.fontStyleList));
    
    if (!this.fontStyleList.includes(event.target.value)) {
      this.fontStyleList.push(event.target.value);
      const node = document.createElement('link');
      node.rel = "stylesheet";
  
      node.href = `${this.fontUrl}${event.target.value}`;
      document.body.prepend(node);    
    };
    
    //Addition of font family to html label
    const dynamicObject = document.getElementById(`dynamic-field-value${i}`);
    const style = this.fontStyle(event.target.value);
    dynamicObject.style.fontFamily = style["font-family"];
    if (style["font-weight"]) {
      dynamicObject.style.fontWeight = style["font-weight"];
    }
  }

  getFontSize(i: number) {
    return +document
      .getElementById(`dynamic-field-value${i}`)
      .style.fontSize.replace("px", "");
  }

  changeFontSize(event, i) {
   if( event.target.value < 16 || event.target.value > 40) {
    document.getElementById(`dynamic-field-value${i}`).style.fontSize =
     "16px";
     event.target.value = 16;
   } else {
    document.getElementById(`dynamic-field-value${i}`).style.fontSize =
      event.target.value.toString() + "px";
   }
  }

  openToolbox(i) {  
    this.dyncamicLabelList.forEach((item, index)=>{
       this.isMenuOpen[index] = false;
    });
    this.isMenuOpen[i] = true;
    this.selectedFontToolIndex = i;
  }

  @HostListener('document:click', ['$event'])
  documentClick(event: Event) {
    const clickedElement = event.target as HTMLElement;
  // Get the Font toolbox div element by its ID
    if(this.selectedFontToolIndex >= 0 && clickedElement) {
      const fontToolEle = document.getElementById('custom-toolbox'+this.selectedFontToolIndex);
      // Check if the clicked element is inside the specific fontToolEle div
      const isClickedInside:boolean = fontToolEle.contains(clickedElement);
      // // Toggle the flag based on the click location
      if(isClickedInside) { 
        this.isMenuOpen[this.selectedFontToolIndex] = true;
      } else {
        this.isMenuOpen[this.selectedFontToolIndex] = false;
      };
    }
  }

  async getCourseCertificate(id: string) {
    const model = {isTemplate: true};
    const data = await this.courseService.getCourseCertificate(id, model);
    this.editCertificateContent  = data.result;
    this.editCertificateId = data.result.id;
    this.form.patchValue(this.editCertificateContent);
    if(data.result){
        this.documentSizeList.forEach((item, i)=>{
          if(item.id === this.editCertificateContent.pagePaperSize){
            this.paperSize = this.documentSizeList[i];
            this.form.get("pagePaperSize").setValue(this.documentSizeList[i]);
          }
        });

        let parser = new DOMParser();
        const doc = parser.parseFromString(this.editCertificateContent.courseCertificatePdfTemplate, 'text/html');
        doc.querySelectorAll('link').forEach(item=>{
          item.remove();
        });
        this.editCertificateContent = doc.getElementsByTagName('section')[0]?.innerHTML;
        this.isImage = true;
        if(doc.getElementsByTagName('img').length > 0){
          this.imageUrl = doc.getElementsByTagName('img')[0].getAttribute('src');
        }

        const dynamicLabelsEle  = doc.querySelectorAll('.dynamic-field-value');
        dynamicLabelsEle.forEach((element, index) => {
        if(element){
          let labelContainer = doc.querySelectorAll('.dynamic-field-box')[index];
          this.addFieldToContainer(element.innerHTML);
          setTimeout(()=>{
            let styleTransform = labelContainer.getAttribute('style');
            if(styleTransform) {
              document.querySelectorAll('.dynamic-field-box')[index].setAttribute('style',styleTransform);
              document.querySelectorAll('.dynamic-field-box .dynamic-field-value')[index].innerHTML = element.innerHTML;
            }
          }, 50);

        }
      });
      this.editCertificateContent = this.sanitizer.bypassSecurityTrustHtml(this.editCertificateContent.courseCertificatePdfTemplate);
    }
  
  }

  async updateCourseCertificate(model) {
    const certificateId = model.id;
    delete model.id;
    try {
      const res = await this.courseService.updateCourseCertificate(certificateId, 
        model
      );

      if (res) {
        this.isSubmitBtnVisible = true;
        this.form.reset();
        this.router.navigate(["course"]);
        this.location.replaceState("/dashboard", "", null);
      } else {
        this.isSubmitBtnVisible = true;
      }
  
    } catch (error) { }
  }


}
