export enum StorageEnum {
  User = 'user',
  AccessToken = 'accessToken',
  UserId = 'userId',
  ArrangementId = 'arrangementId',
  ArrangementList = 'arrangementList',
  Arrangement = 'arrangementList',
  DashboardModuleDetails = 'dashboardModules',
  CompetenceSearchFilters = 'competenceSearchFilters',
  MessageModuleInfo = 'MessageModuleInfo',
  CreateMessageDetails = 'createMessageDetails',
  AppInfo = 'appInfo',
  AppThemeColor = 'themeColor',
  CourseCertificateDetails = 'courseCertificateDetails'
}
