import { CourseListEnum } from "../course.model";

export interface Category {
  type: CourseListEnum;
  name: string;
  icon: string;
  count: number;
  color?: string;
  active: boolean;
}

export const mailbox = [
  {
    type: CourseListEnum.CourseCertificate,
    name: 'GENERATOR.COURSECERTIFICATE',
    icon: 'mail_outline',
    count: 0,
    active: true,
  },
];

  