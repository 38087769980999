import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IArrangementList } from '@core/models/modules.model';
import { CompetenceSearchFilter, ICompetence, ICompetencyType } from '@modules/Competence/competence.model';
import { CompetenceService } from '@modules/Competence/services/competence.service';
import { IProfileGroupOption } from '@modules/Profile/profile.model';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'mb-competence-search-filter-modal',
  templateUrl: './competence-search-filter-modal.component.html',
  styleUrls: ['./competence-search-filter-modal.component.scss'],
})

export class CompetenceSearchFilterModalComponent implements OnInit {

  public formSnapshot: FormGroup;
  public competenceCheckboxFilters = [
    { name: 'COMPETENCE.VALID_COMPETENCE', controlKey: 'isValid' },
    { name: 'COMPETENCE.EXPIRE_COMPETENCE', controlKey: 'isExpire' },
    // { name: 'COMPETENCE.APPROVED_COMPETENCE', controlKey: 'isApproved' }, // 
    { name: 'COMPETENCE.PENDDING_COMPETENCE', controlKey: 'isNotApproved' },
  ];

  constructor(
    public dialogRef: MatDialogRef<CompetenceSearchFilterModalComponent>,
    public competenceService: CompetenceService,
    @Inject(MAT_DIALOG_DATA) public data: { 
      competencyTypeList: ICompetencyType[],
      competenceList: ICompetence[], 
      groups: IProfileGroupOption[], 
      form: FormGroup, 
      arrangementList: IArrangementList[] },
  ) {}

  ngOnInit() {
    this.formSnapshot = cloneDeep(this.data?.form);
    const isExpiryAfterDate = this.formSnapshot.get('isExpiryAfterDate').value;
    this.changeIsExpiryAfterDateField(isExpiryAfterDate);

    this.formSnapshot.get('isExpiryAfterDate').valueChanges.subscribe((value: boolean) => {
      this.changeIsExpiryAfterDateField(value);
    });
  }

  changeIsExpiryAfterDateField(value:boolean) {
    if(value) {
      this.formSnapshot.get('expireAfterDate').enable();
    } else {
      this.formSnapshot.get('expireAfterDate').disable();
    }
    this.formSnapshot.get('expireAfterDate').updateValueAndValidity();
  }

  applyFilter() {
    this.data.form = cloneDeep(this.formSnapshot);
    this.close(this.data.form.value)
  }

  close(formValue?: CompetenceSearchFilter) {
    this.dialogRef.close(formValue);
  }
}
