import { Injectable } from '@angular/core';
import { IFileExcelModel, IFileModel, IGenericReqParams, IServerResponse } from '@core/core.interface';
import { HttpService } from '@core/services/HttpService';
import { firstValueFrom } from 'rxjs';
import { CompetenceSearchFilter, CompetenceStatusEnum, DeletOption, ICompetence, ICompetenceModel, ICompetenceSearch, ICompetencyType, IUpdatecompetency, IcompetencyCategoryList } from '../competence.model';

@Injectable({
  providedIn: 'root',
})
export class CompetenceService {

  public iconColor = {
    [CompetenceStatusEnum.Green]: '#55ce63',
    [CompetenceStatusEnum.Yellow]: 'yellow',
    [CompetenceStatusEnum.Red]: '#e83232',
  };

  constructor(
    private http: HttpService,
  ) { }

  async getCompetences(params: IGenericReqParams) {
    return await firstValueFrom(this.http.get('Competency/GetCompetencyList', params)) as IServerResponse<ICompetenceModel[]>;
  }

  async addCompetence(model: FormData, params: { arrangementId: number, userId: number}) {
    return await firstValueFrom(this.http.post(`Competency/CreateCompetency/${params.arrangementId}/${params.userId}`, model)) as IServerResponse<ICompetence>;
  }

  async updateCompetence(id: string, model: IUpdatecompetency) {
    return await firstValueFrom(this.http.put(`Competency/UpdateCompetency/${id}`, model, null)) as IServerResponse<IUpdatecompetency>;
  }

  async deleteCompetence(id: string) {
    return await firstValueFrom(this.http.delete(`Competency/DeleteCompetency/${id}`, null, {option: DeletOption.SoftDelete} ));

  }

  async getCompetenceAttachment(applicationCVLineId: number) {
    return await firstValueFrom(this.http.get(`Application/DownloadApplicationCvLineFile/${applicationCVLineId}`)) as IServerResponse<IFileModel>;
  }

  async getApplicationCVList(arrangementId: number) {
    return await firstValueFrom(this.http.get(`Application/GetApplicationCVList/${arrangementId}`)) as IServerResponse<ICompetence[]>;
  }

  async getCompetenceSearchList(model: CompetenceSearchFilter, params: IGenericReqParams) {
    const data = { ...model, ...params };
    return await firstValueFrom(this.http.get('Competency/GetRegisterCompetency', data)) as IServerResponse<ICompetenceSearch[]>;
  }

  async GetCompetenceUsersReport(model: CompetenceSearchFilter, params: IGenericReqParams) {
    const data = { ...model, ...params };
    return await firstValueFrom(this.http.get('Application/ExportCompetenceUsers', data)) as IServerResponse<IFileExcelModel>;
  }

  async getCompetencyType() {
    return await firstValueFrom(this.http.get('Competency/GetCompetencyType')) as IServerResponse<ICompetencyType[]>;
  }

  async getCompetencyCategory(id: string) {
    return await firstValueFrom(this.http.get(`Competency/GetCompetencyCategory/${id}`)) as IServerResponse<IcompetencyCategoryList[]>;
  }

  async getCompetencyById(id: string) {
    return await firstValueFrom(this.http.get(`Competency/GetCompetency/${id}`)) as IServerResponse<ICompetence>;
  }

}
