import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { CourseTypeModel, DocumentType } from '@modules/Course/course.model';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { StorageEnum } from '@core/constants/storage.enum';
import { StorageService } from '@core/services/StorageService';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { DashboardService } from '@modules/Dashboard/services/dashboard.service';
import { ThemeColorService } from '@modules/Dashboard/services/theme-color.service';
import { CourseService } from '@modules/Course/services/course.service';

@Component({
  selector: 'mb-course-create-step1',
  templateUrl: './course-create-step1.page.html',
  styleUrls: ['./course-create-step1.page.scss'],
})
export class CourseCreateStepOnePageComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  
  public documentType = DocumentType;
  public messageTypes = [];
  public courseDetailsForm: FormGroup;

  public allDataLoding = false;
  public form: FormGroup;
  public courseTypeModel: CourseTypeModel;

  constructor(
    private formBuilder: RxFormBuilder,
    private storage: StorageService,

    public router: Router,
    public dialog: MatDialog,
    public courseService: CourseService,
    public dashboardService: DashboardService,
    public themeColorService: ThemeColorService,
  ) {
  }

  ngOnInit() {
    this.messageTypes = [
      {label: 'GENERATOR.COURSECERTIFICATE', value: DocumentType.CourseCertificate},
    ];

    this.form = this.formBuilder.formGroup(new CourseTypeModel());

    const userModuleDetails = this.storage.getItem(StorageEnum.DashboardModuleDetails) || null;
    this.dashboardService.setDashboardModule(userModuleDetails.permissionReadDto);
    this.changeTheme(userModuleDetails.menuColorCode);
  }

  submit() {
    const data = this.form.value;
    data.documentReceiverRequests = [];
  
    this.router.navigate(['course/create-course/course-create-step-2'],{queryParams: {typeId: this.form.value.messageType}});
  }

  changeTheme(primary: string) {
    this.themeColorService.savePrimaryColor(primary);
  }
}