import { NgModule } from '@angular/core';
import { CoreModule } from '@core/core.module';
import { ThemeColorService } from './services/theme-color.service';
import { RejectedCheckInComponent } from './component/rejected-checkin/rejected-checkin.component';
import { ResourcesComponent } from './component/resources/resources.component';
import { StaffingComponent } from './component/staffing/staffing.component';
import { MessageResponsesComponent } from './component/message-responses/message-responses.component';

@NgModule({
  declarations: [
    RejectedCheckInComponent,
    ResourcesComponent,
    StaffingComponent,
    MessageResponsesComponent
  ],
  imports: [
    CoreModule,
  ],
  exports: [
    RejectedCheckInComponent,
    ResourcesComponent,
    StaffingComponent,
    MessageResponsesComponent,
  ],
  providers: [
    ThemeColorService,
  ],
})
export class DashboardModule {
}
