import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { FileUploadComponent } from '@components/file-upload/file-upload.component';
import { StorageEnum } from '@core/constants/storage.enum';
import { IArrangementList } from '@core/models/modules.model';
import { StorageService } from '@core/services/StorageService';
import { IProfileGroupOption, ProfileGroup } from '@modules/Profile/profile.model';
import { ProfileService } from '@modules/Profile/services/profile.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'mb-add-group-modal',
  templateUrl: './add-group-modal.component.html',
  styleUrls: ['./add-group-modal.component.scss'],
})
export class AddGroupModalComponent implements OnInit {

  @ViewChild(FileUploadComponent) fileUpload: FileUploadComponent;
  @ViewChild('sucessTemplate', { read: TemplateRef }) sucessTemplate: TemplateRef<any>;

  public profileGroupForm: FormGroup;
  public arrangementList: IArrangementList[];
  public categories: IProfileGroupOption[];
  public groups: IProfileGroupOption[];
  public roles: IProfileGroupOption[];
  public loading = false;
  public isMultipleRoleSelection = false;
  public isGroupAdded = false;

  constructor(
    public dialogRef: MatDialogRef<AddGroupModalComponent>,
    private formBuilder: RxFormBuilder,
    private storage: StorageService,
    private profileService: ProfileService,
  ) {}

  ngOnInit() {
    this.profileGroupForm = this.formBuilder.formGroup(ProfileGroup, {});
    this.arrangementList = this.storage.getItem(StorageEnum.ArrangementList) || [];
  }

  async onArrangementSelect(value: number) {
    if (value) {
      const data = await this.profileService.getCategory(value);
      this.categories = data.result;
    }
  }

  async onCategorySelect(event: MatSelectChange) {
    if (event.value) {
      const data = await this.profileService.getCategorySection(this.profileGroupForm.get('arrangement').value, event.value);
      this.groups = data.result.groupResponse;
      this.isMultipleRoleSelection = data.result.sectionMultiRoleMode;
    }
  }

  async onGroupSelect(event: MatSelectChange) {
    if (event.value) {
      const data = await this.profileService.getProfileRoles(this.profileGroupForm.get('arrangement').value, this.profileGroupForm.get('sectionId').value);
      this.roles = data.result;
    }
  }

  isMultipleSelection() {
    return this.isMultipleRoleSelection;
  }

  async onSubmit() {
    try {
      const arrangementId = this.profileGroupForm.get('arrangement').value;
      const userId = this.storage.getItem(StorageEnum.UserId);
      const roleControl = this.profileGroupForm.get('roleIds');
      roleControl.patchValue(this.isMultipleRoleSelection ? roleControl.value : [ roleControl.value ]);
      await this.profileService.addNewProfileGroup(arrangementId, userId, this.profileGroupForm.value);
      this.isGroupAdded = true;
    } catch (error) { }
    finally {}
  }

  close() {
    this.dialogRef.close();
  }
}
