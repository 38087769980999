import { TranslateService } from '@ngx-translate/core';
import { DefaultValuesEnum } from '@core/constants/default-values.enum';
import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import { StorageService } from '@core/services/StorageService';
import { HttpService } from './HttpService';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  constructor(
    private translate: TranslateService,
    private storageService: StorageService,
    private http: HttpService,
  ) {
  }

  async setLanguagePreference() {
    const language = this.getCurrentLanguage();
    this.translate.use(language);
    this.translate.setDefaultLang(language);
    await this.importCoreLocaleFile();
  }

  async setLanguage(language: string) {
    await this.translate.currentLoader.getTranslation(language);
    this.storageService.setItem(DefaultValuesEnum.Language, language);
    this.translate.use(language);
  }

  getCurrentLanguage() {
    return this.storageService.getItem(DefaultValuesEnum.Language) || 'nb-NO';
  }

  importCoreLocaleFile() {
    return Promise.all([
      import('@angular/common/locales/nb').then(lang => registerLocaleData(lang.default)),
      import('@angular/common/locales/es').then(lang => registerLocaleData(lang.default)),
      import('@angular/common/locales/sv').then(lang => registerLocaleData(lang.default)),
    ]);
  }

  async setTranslationLanguage(userId: number, arrangementId: number, language: string) {
    await firstValueFrom(this.http.post(`Users/UpdateUserLanguage/${arrangementId}/${userId}`, null, {langCode: language}));
  }
}
