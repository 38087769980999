<div mat-dialog-title class="text-right">
  <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
</div>
<form [formGroup]="formSnapshot" *ngIf="formSnapshot" (ngSubmit)="applyFilter()">
  <div mat-dialog-content class="form-content">
    <h3 class="text-center">{{ 'COMPETENCE.FILTER_SEARCH' | translate }}</h3>

    <p *ngFor="let data of competenceCheckboxFilters">
      <mat-checkbox [formControl]="formSnapshot.controls[data.controlKey]">{{ data.name | translate }}</mat-checkbox>
    </p>

    <div class="date" fxLayout="row" fxLayoutGap.md="1em" fxLayoutGap="6%">
      <div fxFlex="47%" fxFlex.md="50%">
        <mat-checkbox [formControl]="formSnapshot.get('isExpiryAfterDate')">{{ 'COMPETENCE.EXPIRY_AFTER' | translate }}</mat-checkbox>
      </div>
      <div fxFlex="47%" fxFlex.md="50%">
        <mb-date-month-picker
        [dateFormControl]="formSnapshot.controls['expireAfterDate']"
        matLabel="COMPETENCE.EXPIRY_DATE"
        placeholder="DD.MM.YYYY"
          ></mb-date-month-picker>
      </div>
    </div>
    <mat-form-field *ngIf="data?.competencyTypeList?.length > 0"><!--competence type-->
      <mat-label>{{ 'COMPETENCE.COMPETENCE_TYPE' | translate }}</mat-label>
      <mat-select
        [formControl]="formSnapshot.get('competencyType')"
        [disabled]="!data?.competencyTypeList?.length"
      >
        <mat-option 
          *ngFor="let competenceType of data?.competencyTypeList" 
          [value]="competenceType.id">
          {{competenceType.name}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'GENERAL.GROUP' | translate }}</mat-label>
      <mat-select
        multiple
        [formControl]="formSnapshot.get('sectionFilters')"
        [disabled]="!data?.groups?.length"
      >
        <mat-option *ngFor="let group of data?.groups" [value]="group.id">{{group.name}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'MAIN_MENU.COMPETENCE' | translate }}</mat-label>
      <mat-select
        multiple
        [formControl]="formSnapshot.get('competenceFilters')"
        [disabled]="!data?.competenceList?.length"
      >
        <mat-option *ngFor="let competence of data?.competenceList" [value]="competence.functionName">
          {{competence.functionName}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field>
      <mat-label>{{ 'GENERAL.ARRANGEMENT' | translate }}</mat-label>
      <mat-select
        [formControl]="formSnapshot.get('arrangementId')"
        [disabled]="!data?.arrangementList?.length"
      >
        <mat-option *ngFor="let option of data.arrangementList" [value]="option.arrangementId">{{ option.name }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  
  <div mat-dialog-actions class="justify-content-center">
    <button mat-raised-button color="primary" class="modal-btn" type="submit">
      {{ 'COMPETENCE.SHOW_HITS_BUTTON' | translate }}
    </button>
  </div>
</form>