import { notEmpty, prop } from "@rxweb/reactive-form-validators";

export enum CVType {
  CurrentArrangement,
  OtherArrangement,
  OfficialCVCompaitancy,
  Other,
}

export class CompetenceModel {

  constructor() { }

  @prop()
  id: string;

  @prop()
  competencyName: string;

  @prop()
  competencyTypeId: string;

  @prop()
  competencyCategoryId: string;

  @notEmpty()
  organizationName: string;

  @prop()
  fromDate: Date;

  @prop()
  toDate: Date;

  @prop({ defaultValue: false })
  isApproved: boolean;

  @prop()
  notes: string;
}

export class CompetenceSearchFilter {

  constructor() {}

  @prop({ defaultValue: []})
  filters: string[];

  @prop({ defaultValue: true })
  isValid: boolean;

  @prop({ defaultValue: false })
  isExpire: boolean;

  // @prop({ defaultValue: false })
  // isApproved: boolean;

  @prop({ defaultValue: false })
  isNotApproved: boolean;

  @prop({ defaultValue: [] })
  sectionFilters: number[];

  @prop({ defaultValue: [] })
  competenceFilters: string[];

  @prop({ defaultValue: '' })
  expireAfterDate: string;

  @prop({ defaultValue: [] })
  competencyType: string[];

  @prop({ defaultValue: false })
  isExpiryAfterDate: boolean;

  @prop({ defaultValue: -1 })
  arrangementId: number
}

export interface ICompetence {
  applicationCVLineId: number;
  applicationId: number;
  cvLineType: string;
  cvTypeEnum: number;
  functionName: string;
  fileName: string;
  fileUrl: string;
  iconColor: CompetenceStatusEnum;
  links: Array<any>;
  organizationName: string;
  isApproved: boolean;
  type: string;
  yearFrom: number;
  yearTo: number;
  notes: string;
  fromDate: string;
  toDate: string;
  competencyCategoryId: string;
  competencyTypeId: string;
  id: string;
  competencyName: string;
  arrangementId: number;
  arrangementName: string;
  userId: number;

}

export interface ICompetenceSearch {
  applicationId: number;
  fileUrl: string;
  firstName: string;
  lastName: string;
  name: string;
  profileImage: string;
  userId: number;
  // applicationCVs: ICompetence[];
  userCompetency:ICompetence[];
  sortType: number;
  sortBy: string;
}

export enum CompetenceStatusEnum {
  None,
  Red,
  Green,
  Yellow,
}

export enum SortType {
 Asc = 1,
 Desc,
}

export interface ICompetencyType {
  id: string,
  name: string,
  content: string,
  imageFileName: string,
  uploadVisible: boolean,
  uploadRequired: boolean,
  dateVisible: boolean,
  dateRequired: boolean,
  approveVisible: boolean,
  noteVisible: boolean,
  isTitleDropDownView: boolean,
  sortOrder: number,
  durationInMonth: number,
}

export interface IcompetencyCategoryList {
  id: string,
  name: string,
  competencyTypeId: string,
}

export enum DeletOption {
  None,
  SoftDelete,
  Harddelete,
}

export interface IUpdatecompetency {
  organizationName: string,
  competencyName: string,
  competencyTypeId: string,
  competencyCategoryId: string,
  fromDate: string,
  toDate: string,
  notes: string,
}

export interface ICompetenceModel {
  id: string,
  organizationName: string,
  competencyName: string,
  competencyTypeId: string,
  competencyCategoryId: string,
  fromDate: string,
  toDate: string,
  notes: string,
  userId: number,
  arrangementId: number,
  isApproved: boolean,
  approvedDate: string,
  approvedBy: number,
  modifyApproved: string,
  modifyApprovedBy: number,
  isDeleted: boolean,
  created: string,
  createdBy: number,
  modified: string,
  modifiedBy: number,
  arrangementName: string,
  iconColor: number,
  fileUrl: string,
  competencyTypeIcon: string,
}
