import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageEnum } from '@core/constants/storage.enum';
import { IAuthResult } from '@core/models/auth.model';
import { IPermissionModel, IUserModuleDetail } from '@core/models/modules.model';
import { PermissionService } from '@core/services/PermissionService';
import { StorageService } from '@core/services/StorageService';
import { DashboardService } from '@modules/Dashboard/services/dashboard.service';
import { ThemeColorService } from '@modules/Dashboard/services/theme-color.service';

@Component({
  selector: 'mb-collector-site',
  templateUrl: './collector-site.component.html',
  styleUrls: ['./collector-site.component.scss']
})
export class CollectorSiteComponent implements OnInit{
  public permissionModel: IPermissionModel;
  public type: number;
  public userModuleDetails: IUserModuleDetail;
  public token: IAuthResult;

  constructor(
    private storage: StorageService,
    public sanitizer: DomSanitizer,
    public themeColorService: ThemeColorService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public dashboardService: DashboardService,
    public permissionService: PermissionService,

  ) {

  }
  
  ngOnInit() {
    //Set Menu Details
    this.userModuleDetails = this.storage.getItem(StorageEnum.DashboardModuleDetails) || null;
    this.setModules();
    
    this.token = this.storage.getItem(StorageEnum.AccessToken) || null;
    this.changeTheme();
    this.activatedRoute.queryParams.subscribe(params=>{
      this.type = parseInt(params?.id);
    });
  }

  setModules() {
    this.dashboardService.setDashboardModule(this.userModuleDetails?.permissionReadDto);
    this.dashboardService.setDashboardCollectorModule(this.userModuleDetails?.collectorPermissionReadDto);
  }

  changeTheme() {
    let themeColor: string;
    themeColor =  this.storage.getItem(StorageEnum.AppThemeColor);
    this.themeColorService.savePrimaryColor(themeColor);
  }

}
