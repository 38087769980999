import { Component } from '@angular/core';

@Component({
  selector: 'mb-staffing',
  templateUrl: './staffing.component.html',
  styleUrls: ['./staffing.component.scss']
})
export class StaffingComponent {

}
