<mat-dialog-content *ngIf="competenceForm">
  <div fxLayoutAlign="end center">
    <mat-icon class="cursor-pointer" (click)="close()">close</mat-icon>
  </div>
  <form [formGroup]="competenceForm">
    <div class="competenceForm">
      <mat-form-field><!--competence type-->
        <mat-select
          [formControl]="competenceForm.controls['competencyTypeId']"
          (selectionChange)="changeCompetencyType($event.value)"
          placeholder="{{ 'COMPETENCE.COMPETENCE_TYPE' | translate}}"
        >
          <ng-container *ngFor="let item of competencyTypeList">
            <mat-option [value]="item.id">{{item?.name}}</mat-option>
          </ng-container>
        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="selectedCompetencyType?.isTitleDropDownView"> <!-- Title Drop Down View true then show competency category-->
        <mat-select
          [formControl]="competenceForm.controls['competencyCategoryId']"
          (selectionChange)="changeCompetencyCategoryId($event.value)"
          placeholder="{{ 'COMPETENCE.COMPETENCE_CATEGORY' | translate}}"
        >
          <ng-container *ngFor="let item of competencyCategoryList">
            <mat-option [value]="item?.id">{{item?.name}}</mat-option>
          </ng-container>
        </mat-select>
        <mat-error 
          *ngIf="competenceForm.get('competencyCategoryId').errors && competenceForm.get('competencyCategoryId').errors.required"
        >
          {{'VALIDATION_MSG.REQUIRED' | translate}}
        </mat-error>
      </mat-form-field>
      <mat-form-field *ngIf="!(selectedCompetencyType?.isTitleDropDownView)"> <!--auto complete function name-->
        <input
          type="text"
          #trigger="matAutocompleteTrigger" matInput
          [formControl]="competenceForm.controls['competencyName']"
          placeholder="{{ 'COMPETENCE.COURSE_NAME' | translate}}"
          [matAutocomplete]="auto"
        >
        <mat-autocomplete
          #auto="matAutocomplete"
          (optionSelected)="getSelectedOption($event.option.value)"
        >
          <mat-option *ngFor="let option of filteredOptions | async" [value]="option.competencyName">
            {{option.competencyName}}
          </mat-option>
        </mat-autocomplete>
        <mat-error *ngIf="competenceForm.get('competencyName').errors && competenceForm.get('competencyName').errors.required">
          {{'VALIDATION_MSG.REQUIRED' | translate}}
        </mat-error>
      </mat-form-field>
      <mb-form-input
        [inputFormControl]="competenceForm.controls['organizationName']"
        matLabel="COMPETENCE.ISSUED_BY"
      ></mb-form-input>
    
      <div
        *ngIf="selectedCompetencyType?.dateVisible"
        class="date"
        fxLayout="row"
        fxLayoutGap="3%"
      >
        <div fxFlex="48%">
          <mb-date-month-picker
            [dateFormControl]="competenceForm.controls['fromDate']"
            matLabel="COMPETENCE.ISSUE_DATE"
            placeholder="DD.MM.YYYY"
          ></mb-date-month-picker>
        </div>
        <div fxFlex="48%">
          <mb-date-month-picker
            [dateFormControl]="competenceForm.controls['toDate']"
            matLabel="COMPETENCE.EXPIRY_DATE"
            placeholder="DD.MM.YYYY"
          ></mb-date-month-picker>
        </div>
      </div>

      <p *ngIf="selectedCompetencyType?.approveVisible" [class.disabled]="!hasPermission('competanseUpdateApproveCheckBoxView')" class="justify-content-space-between checkbox-wrapper">
        <label>{{ 'COMPETENCE.APPROVED_CHECKBOX_TEXT' | translate }}</label>
        <mat-checkbox [formControl]="competenceForm.controls['isApproved']"></mat-checkbox>
      </p>
      
      <!-- as per marcus mobilise suggestion this will be hide in future we will enable it -->
      <!--<p *ngIf="selectedCompetencyType?.approveVisible" class="justify-content-space-between checkbox-wrapper">
        <label>{{ 'COMPETENCE.APPROVED_CHECKBOX_TEXT' | translate }}</label>
        <mat-checkbox [formControl]="competenceForm.controls['isApproved']"></mat-checkbox>
      </p> -->

      <p>
        <!--show file name if form is updating-->
        <a *ngIf="data" class="link" [class.disabled]="fileDisabled" (click)="downloadFile()">
          <mat-icon class="download-icon">file_download</mat-icon>
        </a>
      </p>

      <ng-container *ngIf="!data">
        <div *ngIf="selectedCompetencyType?.uploadVisible" class="file-upload">
          <mat-form-field>
            <p *ngIf="selectedCompetencyType?.uploadRequired && !file?.name" class="text-danger support-text">{{'VALIDATION_MSG.FILE_REQUIRED'}}</p>
            <mat-toolbar>
              <button mat-mini-fab color="accent" aria-label="upload" (click)="onClick()">
                <mat-icon>attach_file</mat-icon>
              </button>
              <input matInput readonly name="name" />
            </mat-toolbar>
            <mb-file-upload #fileInput [acceptableFileType]="acceptableFileType" (fileChangeEvent)="getFile($event)"></mb-file-upload>
          </mat-form-field>
          <div class="attachments-name" *ngIf="file">
            <span>{{file.name}}</span>
            <span class="reset-icon"><i class="fas fa-times"></i></span>
          </div>
        </div>
      </ng-container>

      <mb-form-input
        *ngIf="selectedCompetencyType?.noteVisible"
        [inputFormControl]="competenceForm.get('notes')"
        matLabel="GENERAL.NOTES"
        [isTextAreaInput]="true"
        [rowSize]="1"
      ></mb-form-input>
    </div>
  
    <mat-dialog-actions fxLayoutAlign="end center">
      <ng-container *ngIf="hasPermission('competanseDelete')">
        <button mat-stroked-button color="warn" *ngIf="data" (click)="openConfirmationModal()">
          {{ 'GENERAL.DELETE' | translate }}
        </button>
      </ng-container>
      <button
        mat-raised-button
        cdkFocusInitial
        color="primary"
        class="m-t-20 m-b-20"
        (click)="onSubmit()"
        [disabled]="disableForm()"
      >
        <span>{{ 'GENERAL.STORE' | translate }}</span>
      </button>
    </mat-dialog-actions>
  </form>
</mat-dialog-content>