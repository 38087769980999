<div *ngIf="dateFormControl">
  <label fxFlex *ngIf="label"
         [class.disableText]="isFieldDisabled()"
         [innerHTML]="label | translate"></label>
  <mat-form-field>
    <mat-label *ngIf="matLabel">{{ matLabel | translate }}</mat-label>
    <input matInput [matDatepicker]="date"
          [formControl]="dateFormControl"
          [(ngModel)]="selectedDate"
          [disabled]="isFieldDisabled()"
          [placeholder]="placeholder"
          (click)="date.open()"
          (dateChange)="onDateChange($event)" readonly>
    <mat-datepicker-toggle matSuffix [for]="date" [disabled]="isFieldDisabled()">
      <mat-icon matDatepickerToggleIcon>keyboard_arrow_down</mat-icon>
    </mat-datepicker-toggle>
    <mat-datepicker touchUi #date startView="year"></mat-datepicker>
    <mat-error *ngIf="showErrorMsg(dateFormControl)" class="text-danger support-text">{{dateFormControl['errorMessage'] | translate}}</mat-error>
  </mat-form-field>
</div>

