import { Injectable } from '@angular/core';
import { IGenericReqParams, IServerResponse } from '@core/core.interface';
import { IUserDetails } from '@core/models/user-response.model';
import { HttpService } from '@core/services/HttpService';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContactService {

  constructor(
    private http: HttpService,
  ) { }

  async getContacts(params: IGenericReqParams) {
    return await firstValueFrom(this.http.get('Users/GetContactUsers', params)) as IServerResponse<IUserDetails[]>;
  }

}
