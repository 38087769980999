<mat-card *ngIf="hasPermission('profileSectionView')">
  <mat-card-header class="flex-column">
    <mat-card-title class="text-center">{{ 'PROFILE.GROUP.HEADING' | translate }}</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <ng-container *ngIf="profileGroupInfo; else noDataToShow">
      <div class="groups" *ngFor="let data of profileGroupInfo">
        <div fxLayoutAlign="space-between center">
          <h3 class="font-bold">{{data.arrangementName}}</h3>
        </div>
        <table class="w-100">
          <tr class="text-left">
            <th class="w-50">{{ 'GENERAL.GROUP' | translate }}</th>
            <th class="w-50">{{ 'GENERAL.ROLE' | translate }}</th>
          </tr>
          <tr *ngFor="let group of data?.groups">
            <td>{{group.name}}</td>
            <td>{{getRoles(group?.roles)}}</td>
          </tr>
        </table>
      </div>
    </ng-container>
  </mat-card-content>
  <mat-card-actions *ngIf="hasPermission('profileSectionCreate')" class="justify-content-center">
    <div class="text-center search-group-btn">
      <button mat-stroked-button class="add-group" (click)="addNewGroup()">
        <mat-icon>add</mat-icon>
        <span>{{ 'PROFILE.GROUP.NEW_GROUP' | translate }}</span>
      </button>
    </div>
  </mat-card-actions>
</mat-card>

<ng-template #noDataToShow>
  <h4 class="text-muted text-center">{{ 'GENERAL.NO_DATA_TO_SHOW' | translate }}</h4>
</ng-template>
