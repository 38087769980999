import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FilePreviewModalComponent } from '@components/file-preview-modal/file-preview-modal.component';
import { COMPETENCE_STATUS_COLOR } from '@core/constants/constants';
import { PermissionService } from '@core/services/PermissionService';
import { ICompetence } from '@modules/Competence/competence.model';
import { CompetenceService } from '@modules/Competence/services/competence.service';
import { CompetenceStructureService } from '@modules/Competence/services/competenceStructureService';
import { CompetenceAddEditModalComponent } from '../competence-add-edit-modal/competence-add-edit-modal.component';
import { IPagination } from '@core/core.interface';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs/internal/Subscription';

@Component({
  selector: 'mb-competence-list',
  templateUrl: './competence-list.component.html',
  styleUrls: ['./competence-list.component.scss'],
})
export class CompetenceListComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input() competences: ICompetence[];
  @Input() isCompetenceImage = false;
  @Input() showStatus = false;
  @Input() selectedCompetenceIndex: number = null;
  @Output() getCompetenceEmit = new EventEmitter();
  @Input() pagination: IPagination;

  public iconColor = COMPETENCE_STATUS_COLOR;
  public isDialogOpened = false;
  public routeSubscription: Subscription;

  constructor(
    public dialog: MatDialog,
    public router: ActivatedRoute,
    private competenceService: CompetenceService,
    private competenceStructureService: CompetenceStructureService,
    private permissionService: PermissionService,
  ) {}
  
  ngOnInit(){
    //if name is userCompetence in queryParams then only open competenceForm modal once 
    this.routeSubscription = this.router.queryParams.subscribe(params => {
      if (this.hasPermission('profileCompetanseView') && params.name === 'userCompetence') {
        const data = this.competences[this.selectedCompetenceIndex];
        this.openCompetenceFormModal(data);
      }
    });
  }

  ngAfterViewInit() {
    const ele: HTMLElement = document.getElementById(`competence${this.selectedCompetenceIndex}`);
    (ele) ? ele.scrollIntoView({behavior: 'smooth'}) : null;
  }

  isFileTypeImage(fileName: string) {
    return ['png', 'jpg', 'jpeg'].includes(fileName?.split('.').pop());
  }

  openCompetenceFormModal(competence?: ICompetence) {
    const dialogRef = this.dialog.open(CompetenceAddEditModalComponent, {
      panelClass: 'competence-modal',
      data: competence,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getCompetenceEmit.emit(res);
      }
    });
  }

  async getAttachment(applicationCVLineId: number) {
    if (this.isDialogOpened) {
      return;
    }

    try {
      this.isDialogOpened = true;
      const fileDetails = await this.competenceService.getCompetenceAttachment(applicationCVLineId);
      const dialogRef = this.dialog.open(FilePreviewModalComponent, {
        data: fileDetails.result,
      });

      dialogRef.afterClosed().subscribe(() => {
        this.isDialogOpened = false;
      });
    } catch (error) {
      this.isDialogOpened = false;
    }
  }

  showWarnIcon(competence: ICompetence) {
    return this.competenceStructureService.showWarnIcon(competence);
  }

  showApprovedIcon(competence: ICompetence) {
    return this.competenceStructureService.showApprovedIcon(competence);
  }
  
  hasPermission(type:string) {
    return this.permissionService.hasPermission(type);
  }

  ngOnDestroy() {
    this.routeSubscription.unsubscribe();
  }
}
