<!-- <div class="sort-icon-wrapper">
  <span class="material-icons-outlined sort-icon" (click)="sortCompetenceList()">
    sync_alt
    </span>
</div> -->
<!--Mobile view-->
<div class="mobile-view">
  <div class="text-right">
    <div class="sort-icon-wrapper">
      <button class="sort-icon" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
        <span class="material-icons-outlined vm p-5">
          <mat-icon>sync_alt</mat-icon>
        </span>
      </button>
    </div>
    <mat-menu #menu="matMenu">
      <button mat-menu-item *ngFor="let menu of mobileSortMenuList; let i=index;">
        <ng-container *ngIf="menu.sortType === sortTypeEnum.Asc">
          <div (click)="sortAscMobileview(menu.name, i)">
            <mat-icon #descSection class="vm">arrow_upward</mat-icon>
            <span class="vm">{{ menu.label | translate}}</span>
          </div>
        </ng-container>
        <ng-container *ngIf="menu.sortType === sortTypeEnum.Desc">
          <div (click)="sortDescMobileview(menu.name, i)">
            <mat-icon #descSection class="vm">arrow_downward</mat-icon>
            <span class="vm">{{ menu.label | translate}}</span>
          </div>
        </ng-container>
      </button>
    </mat-menu>
  </div>
  <ng-container *ngFor="let data of competenceSearchList">
    <div class="d-flex competence-detail-list">
      <img [src]="data.fileUrl" class="mat-card-avatar img-circle" alt="image">
      <div class="flex-full-width">
        <h3 class="m-0 font-bold">{{data.name}}</h3>
        <div class="justify-content-space-between competence-list" *ngFor="let competence of data.userCompetency; let i = index">
          <div>
            <div *ngIf="competence.fileUrl" class="competence-img"><img [src]="competence.fileUrl"  alt="competence-img"/></div>
            <a class="cursor-pointer" (click)="goToUserProfilePage(data, i)">
              {{competence.competencyName}}
            </a>
          </div>
          <span>
            <mat-icon *ngIf="showWarnIcon(competence)" [style.color]="iconColor[competence.iconColor]">error_outline</mat-icon>
            <mat-icon *ngIf="showApprovedIcon(competence)" [style.color]="iconColor[competence.iconColor]">check_circle_outline</mat-icon>
          </span>
        </div>
      </div>
    </div>
  </ng-container>
</div>
<!--Desktop view-->
<div class="table-responsive desktop-view m-b-20 ">
  <table mat-table [dataSource]="dataSource" matSort (matSortChange)="sortData($event)" class="competence-detail-list">
  
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GENERAL.NAME' | translate }} </th>
      <td mat-cell *matCellDef="let element; let i = index"> 
        <a class="cursor-pointer" (click)="goToProfileWithNoPopup(element)">
          {{element.name}} 
          </a>
        </td>
    </ng-container>
  
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'GENERAL.EMAIL' | translate }} </th>
      <td mat-cell *matCellDef="let element; let i = index;"> 
        <a class="cursor-pointer" (click)="goToProfileWithNoPopup(element)">
          {{element.email}} 
        </a>
      </td>
    </ng-container>
  
    <ng-container matColumnDef="competencyName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'COMPETENCE.COMPETENCE_NAME' | translate }} </th>
      <td mat-cell *matCellDef="let element"> 
        <table *ngIf="element?.userCompetency">
          <tr *ngFor="let item of element.userCompetency; let i = index;">
            <td>
              <div class="competence-name">
                <div *ngIf="item.fileUrl" class="competence-img vm"><img [src]="item.fileUrl"  alt="competence-img" class="competence-img"/></div>
                <a class="cursor-pointer competency-name-link vm" (click)="goToUserProfilePage(element, i)">
                  {{item.competencyName}}
                </a>
              </div>  
        
            </td>
          </tr>
        </table>
      </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        <div class="status-col"> {{ 'COMPETENCE.COMPETENCE_STATUS' | translate }} </div>
      </th>
      <td mat-cell *matCellDef="let element" class="status-col"> 
        <table *ngIf="element?.userCompetency">
          <tr *ngFor="let item of element.userCompetency; let i = index;">
            <td>
              <div class="competence-status status-icon-wrapper">
                <div class="">
                  <mat-icon *ngIf="showWarnIcon(item)" [style.color]="iconColor[item.iconColor]">error_outline</mat-icon>
                  <mat-icon *ngIf="showApprovedIcon(item)" [style.color]="iconColor[item.iconColor]">check_circle_outline</mat-icon>
                </div>
              </div>  
    
            </td>
          </tr>
        </table>
      </td>
    </ng-container>

    <ng-container matColumnDef="arrangementName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'COMPETENCE.ARRANGEMENT_NAME' | translate }} </th>
      <td mat-cell *matCellDef="let element">
        <table *ngIf="element?.userCompetency">
          <tr *ngFor="let item of element.userCompetency; let i = index;">
            <td >
              <div class="arrangement-name">
                <a class="cursor-pointer" (click)="goToUserProfilePage(item, i)">
                  {{item.arrangementName}}
                </a>
              </div>  
            </td>
          </tr>
        </table>
      </td>
    </ng-container>

    <ng-container matColumnDef="toDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ 'COMPETENCE.EXPIRY_DATE' | translate }} </th>
      <td mat-cell *matCellDef="let element"> 
        <table *ngIf="element?.userCompetency">
          <tr *ngFor="let item of element.userCompetency; let i = index;">
            <td >
              <div class="competence-date" ><a (click)="goToUserProfilePage(element, i)" class="cursor-pointer">{{ item.toDate | date: 'dd.MM.yyyy' }}</a></div>  
            </td>
          </tr>
        </table>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</div>
<div class="pagination" *ngIf="pagination?.totalPages >= 1">
  <mat-paginator
    [pageSizeOptions]="[5, 10, 25]"
    showFirstLastButtons
    (page)="handlePageEvent($event)"
    [pageSize]="pagination.pageSize"
    [length]="pagination.totalRecords"
    [pageIndex]="pagination.pageNumber"
  ></mat-paginator>
</div>

