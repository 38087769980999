<div (click)="getselectedMail(mailList)" class="mail-list p-20 b-b">
  <span class="read-status" [ngClass]="{'unread': !mailList?.isRead}"></span>
  <div class="d-flex align-items-start">
    <img
      [src]="mailList.fileUrl"
      class="img-circle"
      alt="userimg"
      width="50px"
      height="50px"
    />
    <div class="m-l-10 name-wrapper">
      <div class="d-flex align-items-center">
        <h5 class="m-0 user-name" [ngClass]="{'font-bold': !mailList.isRead}">{{ mailList.createdByName }}</h5>
      </div>
      <p class="mail-title m-b-0 text-truncate" [ngClass]="{'font-bold': !mailList.isRead}">
        {{ mailList.documentTitle | slice: 0:20 }}<span *ngIf="mailList?.documentTitle">..</span>
      </p>
    </div>
    <div class="ml-auto text-right">
      <span class="text-muted font-14 text-nowrap">{{
        mailList.created | date: 'dd-MMM yyyy'
      }}</span>
    </div>
  </div>
  <p>
    <span class="font-medium document-content">
      {{ mailList.documentContent | slice: 0:70 }}
    </span>
    <span *ngIf="mailList?.documentContent">..</span>
  </p>
</div>