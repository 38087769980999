
<form (ngSubmit)="onSubmit()" *ngIf="form">
  <div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <mat-form-field>
        <input
          #password
          matInput
          type="password"
          placeholder="{{'SIGN_IN.PASSWORD' | translate}}"
          [formControl]="form.controls['password']"
        />
      </mat-form-field>
      <small class="text-danger support-text">
        <span *ngIf="isRequiredError()">{{ 'VALIDATION_MSG.PASSWORD_REQ' | translate }}</span>
        <span *ngIf="isPasswordMinLengthError">{{ 'VALIDATION_MSG.PASSWORD_LENGTH' | translate }}</span>
        <span *ngIf="isPasswordRegexError">{{ 'VALIDATION_MSG.PASSWORD_CONTAIN' | translate }}</span>
      </small>
    </div>
  
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <mat-form-field>
        <input
          #confirmPassword
          matInput
          type="password"
          placeholder="{{'RESET_PASSWORD.CONFIRM_PASSWORD' | translate}}"
          [formControl]="form.controls['confirmPassword']"
        />
      </mat-form-field>
      <small *ngIf="isRequiredConfirmPwdError()" class="text-danger support-text">
        {{ 'VALIDATION_MSG.CONFIRM_PASSWORD_REQUIRED' | translate }}
      </small>

      <small *ngIf="isConfirmPasswordError() && !isRequiredConfirmPwdError()" class="text-danger support-text">
        {{ 'VALIDATION_MSG.PASSWORD_DOESNT_MATCH' | translate }}
      </small>
    </div>
  
    <div class="submit-btn" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <button
        mat-raised-button
        color="primary"
        class="btn-block btn-lg m-t-0 m-b-20"
        type="submit"
        [disabled]="form.invalid"
      >
        <span *ngIf="!loading; else loader">{{ 'GENERAL.SAVE' | translate }}</span>
      </button>
    </div>
  </div>
</form>

<ng-template #loader>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>
