import { Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
import { FieldAndLabelWidthType } from '@components/form-input/form-input.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'mb-form-input',
  templateUrl: './form-input.component.html',
  styleUrls: ['./form-input.component.scss'],
})
export class FormInputComponent implements OnInit {

  @Input() inputFormControl: FormControl;

  @Input() isReadOnly: boolean;
  @Input() isDisabled = false;
  @Input() isTextAreaInput = false;

  @Input() label: string;
  @Input() matLabel: string;
  @Input() placeholder: string;
  @Input() hint: string;
  @Input() iconName: string;
  @Input() inputValue: string | number;
  @Input() iconToolTipText: string;

  @Input() inputType = 'text';
  @Input() fieldWidth: FieldAndLabelWidthType = '100%';
  @Input() labelWidth: FieldAndLabelWidthType = '1 1 0%';
  @Input() digitInfo = '1.2-2';
  @Input() rowSize = '5';

  public showError = false;
  public activeControl: AbstractControl;

  constructor(
    public translateService: TranslateService,
  ) {
  }

  ngOnInit() {
    // Update validator if lang change
    this.translateService.onLangChange.subscribe(() => {
      if (this.inputFormControl) {
        this.inputFormControl.updateValueAndValidity();
      }
    });
  }

  isLabelDisabled() {
    return this.inputFormControl && this.inputFormControl.disabled || this.isDisabled;
  }

  showErrorMsg(control: AbstractControl) {
    return control['errorMessage'] && control.touched;
  }
}
