import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'mb-gdpr-modal',
  templateUrl: './gdpr-modal.component.html',
  styleUrls: ['./gdpr-modal.component.scss'],
})
export class GDPRModalComponent {

  constructor(
    public dialogRef: MatDialogRef<GDPRModalComponent>,
  ) {}

  close() {
    this.dialogRef.close();
  }
  
}