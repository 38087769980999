<div *ngIf="hasPermission('competanseRegisterView')" class="competence-search bg-white">
  <div *ngIf="hasPermission('competanseRegisterFilterView')">
    <div>
      <form class="competence-search-form" (ngSubmit)="submit(searchValue.value)">
        <mat-form-field>
          <mat-icon matPrefix>search</mat-icon>
          <input
            type="text"
            #searchValue
            #trigger="matAutocompleteTrigger" matInput [matAutocomplete]="auto"
            [formControl]="competenceName"
            placeholder="Search"
            (click)="inputClick()"
          >
          <mat-autocomplete
            #auto="matAutocomplete"
            [displayWith]="displayFn"
            (optionSelected)="getSelectedOption($event.option.value)"
          >
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option.functionName">
              {{option.functionName}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
    </div>

    <div class="filters" *ngIf="!allDataLoding">
      <mat-chip-listbox aria-label="filters">
        <ng-container *ngFor="let control of competenceSearchFilterForm.value | keyvalue; let i = index">
          <ng-container *ngIf="isTypeArray(control.value); else otherTypeValue">
            <ng-container *ngIf="control.value?.length">
              <mat-chip class="filters-chip" *ngFor="let data of control.value; let j = index">
                {{ (control.key === 'sectionFilters') ? getGroupName(data) : data }}
                <button matChipRemove [attr.aria-label]="'remove' + j" (click)="removeArrayFilters(control.key, j)">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip>
            </ng-container>
          </ng-container>
          <ng-template #otherTypeValue>
            <mat-chip class="filters-chip" *ngIf="control.value && isCompetencyType(control)">
              {{ getCompetencyTypeName(control.value) | translate }}
              <button matChipRemove [attr.aria-label]="'remove' + i" (click)="removeFilter(control)">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>

            <mat-chip class="filters-chip" *ngIf="control.value > 0 && getFilterName(control)">
              {{ getFilterName(control) | translate }}
              <ng-container *ngIf="(control.key === 'isExpiryAfterDate') && competenceSearchFilterForm.get('expireAfterDate').value">
                {{ getExpireDate() | date: 'dd.MM.yyyy'}}
              </ng-container>
              <button matChipRemove [attr.aria-label]="'remove' + i" (click)="removeFilter(control)">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
          </ng-template>
        </ng-container>
      </mat-chip-listbox>
    </div>

    <div class="d-flex align-items-center m-b-20">
      <button mat-raised-button class="filter-btn" (click)="openFilterModal()">
        <span class="material-icons">filter_alt</span>
        <span class="filter-text">Filter</span>
      </button>
      <a class="clear-filter" (click)="clearFilter()"> {{ 'GENERAL.CLEAR_FILTER' | translate }} </a>
    </div>
  </div>
  <div *ngIf="hasPermission('competanseRegisterListView')">
    <ng-container *ngIf="!loading; else loader">
      <mb-competence-search-list *ngIf="competenceSearchList?.length; else noDataToShow" [competenceSearchList]="competenceSearchList"></mb-competence-search-list>
    </ng-container>
  </div>
  <div  class="export-competence align-items-center justify-content-center">
    <a class="export-link vm m-r-15" (click)="downloadCompetenceUsersReport()">
      {{ 'GENERAL.EXPORT_REPORT' | translate}}     
      <span class="material-icons-outlined icon-color vm">save_alt</span>    
    </a>
  </div>
</div>

<ng-template #noDataToShow>
  <h4 class="text-muted text-center">{{ 'GENERAL.NO_DATA' | translate }}</h4>
</ng-template>

<ng-template #loader>
  <mat-spinner [diameter]="50"></mat-spinner>
</ng-template>