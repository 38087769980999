import { Injectable } from '@angular/core';
import { IServerResponse } from '@core/core.interface';
import { HttpService } from '@core/services/HttpService';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { IDynamicfieldsList, IMailDetails, DeletOption } from '../course.model';

@Injectable({
  providedIn: 'root',
})
export class CourseService {
  public addClass = true;

  public selectedMail = new BehaviorSubject<any>(null);

  constructor(
    private http: HttpService,
  ) { }

  async getDynamicFileds(arrangementId: number) {
    return await firstValueFrom(this.http.get(`PDFGeneratorCertificate/GetDynamicFileds`)) as IServerResponse<IDynamicfieldsList[]>;
  }

  async createCourseCertificate(arrangementId: number, typeId: number, model: any) {
    return await firstValueFrom(this.http.post(`PDFGeneratorCertificate/CreateCourseCertificate/${arrangementId}/${typeId}`, model)) as IServerResponse<IMailDetails>;
  }

  async updateCourseCertificate(certififacteId: string, model: any) {
    return await firstValueFrom(this.http.post(`PDFGeneratorCertificate/UpdateCourseCertificate/${certififacteId}`, model));
  }

  async getInvitationFileGenerator(arrangementId: number, userId: number, typeId: number,pageNumber: number, pageSize: number) {
    return await firstValueFrom(this.http.get(`PDFGeneratorCertificate/GetInvitationFileGenerator/${arrangementId}/${userId}`, {type: typeId, pageNumber: pageNumber, pageSize: pageSize}))
  }

  async getCourseCertificate(certififacteId: string, model: {isTemplate: boolean}) {
    return await firstValueFrom(this.http.get(`PDFGeneratorCertificate/GetCourseCertificate/${certififacteId}`,model)) as IServerResponse<IMailDetails>;
  }

  async deleteCourseCertificate(certififacteId: string) {
    return await firstValueFrom(this.http.get(`Message/DeleteDocument/${certififacteId}/${DeletOption.SoftDelete}`, null));
  }
}
