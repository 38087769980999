import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
// import { StorageEnum } from '@core/constants/storage.enum';
import { StorageService } from '@core/services/StorageService';
import { MessageService } from '@modules/Message/services/message.service';
// import { notEmpty, RxFormBuilder } from '@rxweb/reactive-form-validators';
import { Subscription } from 'rxjs';

@Component({
  selector: 'mb-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.scss']
})
export class MessageDetailsComponent {
  @Input() mailDetail: any;
  public form: FormGroup;
  public mailSubscription: Subscription;
  public showMessageTextForm: Boolean = false;
  
  constructor(
    public messageService: MessageService,
    public storage: StorageService,
    // private rxFormBuilder: RxFormBuilder,
    ) {

  }


  get isValidMessageError() {
    return this.form.get('message').hasError('required') && !this.isMessageRequiredError;
  }

  get isMessageRequiredError() {
    return this.form.get('message').hasError('notEmpty') && this.form.get('message').touched;
  }

  getMessageTextForm() {
    this.showMessageTextForm = true;
    return this.showMessageTextForm;
  }
  //TO DO Send Message
  onSubmit() {

  }
  //TO DO Delete Message API Call
  deleteMessage(type: any) {

  }
  
  removeClass(): void {
    this.messageService.addClass = false;
  }

  isFileTypeImage(file: string) {
    const fileName = file.toLowerCase();
    return ['png', 'jpg', 'jpeg'].includes(fileName?.split('.').pop());
  }
}

// export class mailDetailModel {
//   @notEmpty()
//   message = '';
// }