import { Component, Input } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import { CourseService } from '@modules/Course/services/course.service';
import { IMailList } from '@modules/Message/message.model';

@Component({
  selector: 'mb-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.scss']
})
export class CourseListComponent {
  @Input() mailList: IMailList;

  @Output() selectedMailEvent = new EventEmitter<IMailList>();

  constructor(
    public courseService: CourseService,
    ) {
  }

  getselectedMail(mail: IMailList) {
    this.courseService.addClass = true;
    this.selectedMailEvent.emit(mail);
  }
}