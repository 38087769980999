import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { StorageService } from '@core/services/StorageService';
import { CourseService } from '@modules/Course/services/course.service';
import { Subscription } from 'rxjs';
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from '@angular/router';
import html2canvas from 'html2canvas';
import { ConfirmationDialog } from '@core/services/ConfirmationDialog';
import { IMailList } from '@modules/Message/message.model';
import { StorageEnum } from '@core/constants/storage.enum';

@Component({
  selector: 'mb-course-details',
  templateUrl: './course-details.component.html',
  styleUrls: ['./course-details.component.scss']
})
export class CourseDetailsComponent implements OnChanges {
  @Input() mailDetail: any;
  @Output() updateCourseList = new EventEmitter<IMailList[]>();

  public form: FormGroup;
  public mailSubscription: Subscription;
  public showMessageTextForm: Boolean = false;
  public certificateContent: any;
  // public imageUrl: string;

  constructor(
    private confirmationDialog: ConfirmationDialog,
    public courseService: CourseService,
    public storage: StorageService,
    public sanitizer: DomSanitizer,
    public router: Router,

    ) {
      this.sanitizer = sanitizer;
  }

  ngOnChanges() {
    this.certificateContent = '';
    if(this.mailDetail && this.mailDetail.courseCertificatePdfTemplate){
      let parser = new DOMParser();
      const doc = parser.parseFromString(this.mailDetail.courseCertificatePdfTemplate, 'text/html');
      this.certificateContent = doc.getElementsByTagName('img')[0].outerHTML;
      this.getHtmlToImage();
    }
  }

  get isValidMessageError() {
    return this.form.get('message').hasError('required') && !this.isMessageRequiredError;
  }

  get isMessageRequiredError() {
    return this.form.get('message').hasError('notEmpty') && this.form.get('message').touched;
  }

  getMessageTextForm() {
    this.showMessageTextForm = true;
    return this.showMessageTextForm;
  }
  
  removeClass(): void {
    this.courseService.addClass = false;
  }

  isFileTypeImage(file: string) {
    const fileName = file.toLowerCase();
    return ['png', 'jpg', 'jpeg'].includes(fileName?.split('.').pop());
  }

  editCertificate(data) {
    this.router.navigate(['course/create-course/course-create-step-2'],{queryParams: {id: data.id}});
  }

  getHtmlToImage(){
    setTimeout(()=>{
      const cetificateViewEle = document.querySelector(".cetificate-view") as HTMLElement;
      if(cetificateViewEle){
        html2canvas(cetificateViewEle).then((canvas: any) => {
          if(canvas) {
            // this.getCanvasToImage(canvas);
          }
        });
      }
    }, 400);
  }

  getCanvasToImage(canvas:any) { 
    let ctx = canvas.getContext('2d');
    ctx.scale(0, 0);
    // this.imageUrl= canvas.toDataURL("image/png");
  }

  openConfirmationModal(mailDetail) {
    const title = 'CERTIFICATE.DELETE_CONFIRMATION_MSG';
    this.confirmationDialog.confirm(title, false).then(async (result: boolean) => {
      if (result) {
        await this.courseService.deleteCourseCertificate(mailDetail?.id);
        this.updateCourseList.emit();
        this.mailDetail='';
        this.storage.removeItem(StorageEnum.CourseCertificateDetails);
        this.certificateContent = '';
      }
    });
  }

}
