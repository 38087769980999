import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { IPagination } from '@core/core.interface';
import { IAccessType, IUserDetails } from '@core/models/user-response.model';
import { ImageService } from '@core/services/ImageService';

@Component({
  selector: 'mb-desktop-contact-view',
  templateUrl: './desktop-contact-view.component.html',
  styleUrls: ['./desktop-contact-view.component.scss'],
})
export class DesktopContactViewComponent implements OnInit {

  @Input() contacts: IUserDetails[];
  @Input() pagination: IPagination;

  public displayedColumns: string[] = [
    'name',
    'email',
    'mobile',
    'roleNames',
  ];

  public dataSource: MatTableDataSource<IUserDetails>;

  constructor(
    private router: Router,
    private imageService: ImageService,
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.contacts);
  }

  getProfileImage(fileUrl: string) {
    return this.imageService.loadImage(fileUrl)
  }

  getUserRole(role: IAccessType[]) {
    return role.map(ele => ele.accessTypeName).toString();
  }

  getPhoneNumber(contact: IUserDetails) {
    return `${contact.mobilePrefix}${contact.mobile}`;
  }

  selectContact(contact: IUserDetails) {
    this.router.navigate(['/user-profile', contact.userId], { state: { profileInfo: contact } });
  }
}
