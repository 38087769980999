<mat-card class="example-card">
  <mat-card-header>
    <mat-card-title>Shiba Inu</mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <div class="checkin-dropdown">
      <mat-form-field>
        <mat-select>
          <mat-option value="option 1">option 1</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div class="d-flex " fxLayout="row wrap" fxLayoutAlign="space-between center">
      <div class="d-flex flex-start">
        <button mat-mini-fab class="bg-white menu-icon">
          <img class="user-icon" src="assets/images/users/5.jpg">
        </button>
  
        <div class="m-l-15">
          <p class="font-medium lh-15 m-b-0 m-t-0">admin@mobilise.no</p>
          <a class="font-12 text-primary-dark">+1234567892</a>
        </div>  
      </div>
      <div class="align-items-end center">
        <button
          mat-raised-button
          color="primary"
          class="t-20 m-b-20 bg-primary-dark text-white checkin-btn"
          type="button"
        >
          In
        </button>
      </div>
    </div>
    <p>
      The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
      A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
      bred for hunting.
    </p>
  </mat-card-content>
  <mat-card-actions>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions>
</mat-card>