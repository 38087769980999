import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { IPagination } from '@core/core.interface';
import { IUserDetails } from '@core/models/user-response.model';
import { ImageService } from '@core/services/ImageService';

@Component({
  selector: 'mb-mobile-contact-view',
  templateUrl: './mobile-contact-view.component.html',
  styleUrls: ['./mobile-contact-view.component.scss'],
})
export class MobileContactViewComponent {

  @Input() contacts: IUserDetails[];
  @Input() pagination: IPagination;

  constructor(
    private router: Router,
    private imageService: ImageService,
  ) {}
  
  getPhoneNumber(contact: IUserDetails) {
    return `${contact.mobilePrefix}${contact.mobile}`;
  }
  
  getProfileImage(fileUrl: string) {
    return this.imageService.loadImage(fileUrl)
  }

  selectContact(contact: IUserDetails) {
    this.router.navigate(['/user-profile', contact.userId], { state: { profileInfo: contact } });
  }
}
