import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { ContactService } from '@modules/Contact/services/contact.service';
import { IPagination } from '@core/core.interface';
import { IUserDetails } from '@core/models/user-response.model';
import { StorageService } from '@core/services/StorageService';
import { StorageEnum } from '@core/constants/storage.enum';
import { PageEvent } from '@angular/material/paginator';
import { ThemeColorService } from '@modules/Dashboard/services/theme-color.service';
import { DashboardService } from '@modules/Dashboard/services/dashboard.service';

@Component({
  selector: 'mb-contacts',
  templateUrl: './contacts.page.html',
  styleUrls: ['./contacts.page.scss'],
})
export class ContactsPageComponent implements OnInit {

  public pagination: IPagination = {
    pageNumber: 0,
    pageSize: 10,
  };

  public searchText = '';
  public contacts: IUserDetails[];
  public loading = false;
  public debounceTime = 1000;

  public mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;

  constructor(
    private storage: StorageService,
    private contactService: ContactService,
    public datePipe: DatePipe,
    public themeColorService: ThemeColorService,
    public dashboardService: DashboardService,
    media: MediaMatcher,
    changeDetectorRef: ChangeDetectorRef,
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 769px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addEventListener('change', this._mobileQueryListener);
  }

  async ngOnInit() {
    const userModuleDetails = this.storage.getItem(StorageEnum.DashboardModuleDetails) || null;
    this.changeTheme();
    this.dashboardService.setDashboardModule(userModuleDetails?.permissionReadDto);
    this.dashboardService.setDashboardCollectorModule(userModuleDetails?.collectorPermissionReadDto);
    await this.getContacts();
  }

  async getContacts() {
    const params = {
      pageNumber: this.pagination.pageNumber,
      pageSize: this.pagination.pageSize,
      searchText: this.searchText.trim() || '',
      // sectionId: null,
      userId: this.storage.getItem(StorageEnum.UserId),
      // arrangementId: null,
      arrangementId: this.storage.getItem(StorageEnum.ArrangementId),
    };

    try {
      this.loading = true;
      const data = await this.contactService.getContacts(params);
      this.pagination = { ...this.pagination, ...data.pageInfo };
      this.contacts = data.result;
    } catch (error) { }
    finally {
      this.loading = false;
    }
  }

  onContactSearch() {
    this.getContacts();
  }

  resetSearchText() {
    this.searchText = '';
    this.getContacts();
  }

  handlePageEvent(e: PageEvent) {
    this.pagination.pageNumber = e.pageIndex;
    this.pagination.pageSize = e.pageSize;
    this.getContacts();
  }

  changeTheme() {
    const themeColor = this.storage.getItem(StorageEnum.AppThemeColor);
    this.themeColorService.savePrimaryColor(themeColor);
  }
}
