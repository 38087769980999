import { Injectable } from '@angular/core';
import { DialogSetting } from '@components/components.interface';
import { DialogComponent } from '@components/dialog/dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root',
})
export class ConfirmationDialog {

  constructor(
    private dialog: MatDialog,
  ) {
  }

  confirm(title: string, isAlert: boolean = false, subTitle?: string): Promise<boolean> {
    const data: DialogSetting = {
      title: title,
      subTitle: subTitle,
      isAlert: isAlert,
    };
    const dialogRef = this.dialog.open(DialogComponent, {
      data: data,
    });

    return new Promise(resolve => {
      dialogRef.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }
}
