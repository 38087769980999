import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { StorageEnum } from '@core/constants/storage.enum';
import { IPagination } from '@core/core.interface';
import { IArrangementList } from '@core/models/modules.model';
import { PermissionService } from '@core/services/PermissionService';
import { StorageService } from '@core/services/StorageService';
import { CompetenceSearchFilter, ICompetence, ICompetenceSearch, ICompetencyType } from '@modules/Competence/competence.model';
import { CompetenceSearchFilterModalComponent } from '@modules/Competence/component/competence-search-filter-modal/competence-search-filter-modal.component';
import { CompetenceService } from '@modules/Competence/services/competence.service';
import { DashboardService } from '@modules/Dashboard/services/dashboard.service';
import { ThemeColorService } from '@modules/Dashboard/services/theme-color.service';
import { IProfileGroupOption } from '@modules/Profile/profile.model';
import { ProfileService } from '@modules/Profile/services/profile.service';
import { RxFormBuilder } from '@rxweb/reactive-form-validators';
import { cloneDeep, find } from 'lodash';
import { map, Observable, startWith } from 'rxjs';

@Component({
  selector: 'mb-competence-search',
  templateUrl: './competence-search.page.html',
  styleUrls: ['./competence-search.page.scss'],
})
export class CompetenceSearchPageComponent implements OnInit {

  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  public filterNameAlias = {
    'isValid': 'COMPETENCE.VALID_COMPETENCE',
    'isExpire': 'COMPETENCE.EXPIRE_COMPETENCE',
    // 'isApproved': 'COMPETENCE.APPROVED_COMPETENCE',// as per requirement commented, need in future 
    'isNotApproved': 'COMPETENCE.PENDDING_COMPETENCE',
    'isExpiryAfterDate': 'COMPETENCE.IS_EXPIRE_AFTER_DATE',
  };

  public pagination: IPagination = {
    pageNumber: 0,
    pageSize: 10,
  };
  public competenceSearchList: ICompetenceSearch[];
  public loading = false;
  public allDataLoding = false;
  public applicationCVList: ICompetence[];
  public filteredOptions: Observable<ICompetence[]>;
  public competenceName = new FormControl(null);
  public applicationCVListSnapshot: ICompetence[];
  public groups: IProfileGroupOption[];
  public competenceSearchFilterForm: FormGroup;
  public arrangementList: IArrangementList[];
  public competencyTypeList: ICompetencyType[];

  constructor(
    public dialog: MatDialog,
    private storage: StorageService,
    private competenceService: CompetenceService,
    private profileService: ProfileService,
    private formBuilder: RxFormBuilder,
    public permissionService: PermissionService,
    public dashboardService: DashboardService,
    public themeColorService: ThemeColorService,
  ) { }

  async ngOnInit() {
    this.competencyTypeList = [];
    this.createForm();
    const userModuleDetails = this.storage.getItem(StorageEnum.DashboardModuleDetails) || null;
    this.setTheme(userModuleDetails?.menuColorCode);
    this.dashboardService.setDashboardModule(userModuleDetails?.permissionReadDto);
    this.dashboardService.setDashboardCollectorModule(userModuleDetails?.collectorPermissionReadDto);
    await this.getAllData();
    this.arrangementList = this.storage.getItem(StorageEnum.ArrangementList) || [];
    this.filteredOptions = this.competenceName.valueChanges.pipe(
      startWith(''),
      map(value => {
        return this.filter(value || '')
      }),
    );
  }

  async createForm() {
    const competenceSearchFilterModel = this.storage.getItem(StorageEnum.CompetenceSearchFilters) || {};
    this.competenceSearchFilterForm = this.formBuilder.formGroup(CompetenceSearchFilter, competenceSearchFilterModel);
  }

  filter(name: string): ICompetence[] {
    if (!name) {
      // this.autocomplete.closePanel();
      return;
    }

    const filterValue = name.toLowerCase();
    return this.applicationCVList.filter(option => option.functionName.toLowerCase().includes(filterValue));
  }

  displayFn(name: string): string {
    return '';
  }

  getGroupName(id: number) {
    return find(this.groups, ele => ele.id === id)?.name || '';
  }

  getCompetencyTypeName(id: string) {
    return find(this.competencyTypeList, ele => ele.id === id)?.name || '';
  }

  isCompetencyType(control: { key: string, value: any }) {
    return control.key === 'competencyType';
  }

  getExpireDate(){
    const date = this.competenceSearchFilterForm.get('expireAfterDate').value;
    return new Date(date) || '';
  }

  isTypeArray(value: string[] | boolean | number) {
    return value instanceof Array;
  }

  getFilterName(control: { key: string, value: any }) {
    return this.filterNameAlias[control.key];
  }

  removeFilter(control: { key: string, value: any }) {
    if (this.isCompetencyType(control)) {
      this.competenceSearchFilterForm.get(control.key).patchValue([]);
    }

    if (typeof(control.value) === typeof(true)) {
      this.competenceSearchFilterForm.get(control.key).patchValue(false);
    }
    this.getCompetenceSearchList();
  }

  removeArrayFilters(key: string, index: number) {
    this.competenceSearchFilterForm.value[key].splice(index, 1);
    this.getCompetenceSearchList();
  }

  async getAllData() {
    try {
      this.allDataLoding = true;
      await Promise.all([
        this.getApplicationCVList(),
        this.getCompetenceSearchList(),
        this.getCategorySection(),
        this.getCompetencyType(),
      ]);
    } catch (error) { }
    finally {
      this.allDataLoding = false;
    }
  }

  async getCompetencyType() {
    const data = await this.competenceService.getCompetencyType();
    this.competencyTypeList = data?.result || [];
  }

  async getApplicationCVList() {
    const arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    const data = await this.competenceService.getApplicationCVList(arrangementId);
    this.applicationCVList = data.result;
    this.applicationCVListSnapshot = cloneDeep(this.applicationCVList);
  }

  async getCategorySection() {
    const arrangementId = this.storage.getItem(StorageEnum.ArrangementId);
    const data = await this.profileService.getCategorySection(+arrangementId, -1);
    this.groups = data.result.groupResponse;
  }

  submit(value: string) {
    if (!value.trim() || this.competenceSearchFilterForm.get('filters').value.includes(value.trim())) {
      return;
    }
    this.competenceSearchFilterForm.get('filters').value.push(value);
    this.competenceName.patchValue('');
    this.getCompetenceSearchList();
  }

  async getCompetenceSearchList() {
    
    try {
      this.loading = true;
      this.storage.setItem(StorageEnum.CompetenceSearchFilters, this.competenceSearchFilterForm.value);
      const params = {
        pageNumber: this.pagination.pageNumber,
        pageSize: this.pagination.pageSize,
        userId: -1,
      };

      const competenceSearchFilterData =  this.competenceSearchFilterForm.value;
      if(!competenceSearchFilterData.isExpiryAfterDate) {
        competenceSearchFilterData.expireAfterDate = '';
      }

      const data = await this.competenceService.getCompetenceSearchList(competenceSearchFilterData, params);
      this.competenceSearchList = data.result;
    } catch (error) {}
    finally {
      this.loading = false;
    }
  }

  getSelectedOption(value: string) {
    if (this.competenceSearchFilterForm.get('filters').value.includes(value.trim())) {
      return;
    }
    this.competenceSearchFilterForm.get('filters').value.push(value);
    this.getCompetenceSearchList();
  }
  
  inputClick() {
    this.autocomplete?.closePanel();
  }

  openFilterModal() {
    const dialogRef = this.dialog.open(CompetenceSearchFilterModalComponent, {
      data: {
        competencyTypeList: this.competencyTypeList,
        competenceList: this.applicationCVList,
        groups: this.groups,
        form: this.competenceSearchFilterForm,
        arrangementList: this.arrangementList,
      },
    });

    dialogRef.afterClosed().subscribe((res: CompetenceSearchFilter) => {
      if (res) {
        this.competenceSearchFilterForm.patchValue(res);
        this.getCompetenceSearchList();
      }
    })
  }
  
  hasPermission(type:string) {
    return this.permissionService.hasPermission(type);
  }

  async downloadCompetenceUsersReport() {
    try {
      this.storage.setItem(StorageEnum.CompetenceSearchFilters, this.competenceSearchFilterForm.value);
      const params = {
        pageNumber: this.pagination.pageNumber,
        pageSize: this.pagination.pageSize,
        userId: -1,
      };
  
      await this.competenceService.GetCompetenceUsersReport(this.competenceSearchFilterForm.value, params);
    } catch (error) {}
    finally {
    }
  }

  async clearFilter() {
    this.competenceSearchFilterForm.reset();
    this.storage.setItem(StorageEnum.CompetenceSearchFilters,{});
    this.createForm();
    await this.getAllData();
  }

  setTheme(primary: string) {
    this.themeColorService.savePrimaryColor(primary);
  }
  
}
