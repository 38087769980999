<div class="table-responsive">
  <table mat-table [dataSource]="dataSource" matSort class="table contact-list no-wrap">
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'GENERAL.NAME' | translate}}</th>
      <td mat-cell *matCellDef="let element">
          <div class="d-flex">
          <img class="mat-card-avatar img-circle" [src]="getProfileImage(element.fileUrl)" />
          <p class="m-b-0 m-t-0 user-name">{{ element.name }}</p>
          </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'GENERAL.EMAIL' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <a href="mailto:{{element.email}}" (click)="$event.stopPropagation()">
          {{ element.email }}
        </a>
      </td>
    </ng-container>

    <ng-container matColumnDef="mobile">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'GENERAL.MOBILE' | translate}}</th>
      <td mat-cell *matCellDef="let element">
        <a href="tel:+{{getPhoneNumber(element)}}" (click)="$event.stopPropagation()">
          {{ element.mobile }}
        </a>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>{{'GENERAL.ACCESS_TYPE' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ getUserRole(element.accessType) | translate }}</td>
    </ng-container> -->

    <ng-container matColumnDef="roleNames">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>{{'GENERAL.ROLE' | translate}}</th>
      <td mat-cell *matCellDef="let element">{{ element.roleNames }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr class="cursor-pointer" mat-row *matRowDef="let row; columns: displayedColumns" (click)="selectContact(row)"></tr>
  </table>
</div>
