<div class="dialog-container">
  <h3 mat-dialog-title> {{ (data.isAlert ? 'GENERAL.ALERT' : 'GENERAL.CONFIRM') | translate }}</h3>

  <mat-dialog-content>
    <p [innerHTML]="data.title | translate"></p>
    <p *ngIf="data.subTitle">{{ data.subTitle | translate }}</p>
  </mat-dialog-content>

  <mat-dialog-actions align="end">
    <button mat-flat-button type="button" color="warn" #btn [autofocus]="btn.focus()" (click)="onBtnClick(true)">
      {{ ( data.isAlert ? 'GENERAL.OK' : 'GENERAL.YES') | translate }}
    </button>
    <button mat-flat-button type="button" color="primary" *ngIf="!data.isAlert"
            (click)="onBtnClick(false)">
      {{'GENERAL.NO' | translate}}
    </button>
  </mat-dialog-actions>
</div>
