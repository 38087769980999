import { Component } from '@angular/core';

@Component({
  selector: 'mb-message-responses',
  templateUrl: './message-responses.component.html',
  styleUrls: ['./message-responses.component.scss']
})
export class MessageResponsesComponent {

}
