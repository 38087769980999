import { Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { COMPETENCE_STATUS_COLOR } from '@core/constants/constants';
import { StorageEnum } from '@core/constants/storage.enum';
import { IPagination } from '@core/core.interface';
import { StorageService } from '@core/services/StorageService';
import { CompetenceStatusEnum, ICompetence, ICompetenceSearch, SortType } from '@modules/Competence/competence.model';
import { CompetenceService } from '@modules/Competence/services/competence.service';
import { CompetenceStructureService } from '@modules/Competence/services/competenceStructureService';

@Component({
  selector: 'mb-competence-search-list',
  templateUrl: './competence-search-list.component.html',
  styleUrls: ['./competence-search-list.component.scss'],
})
export class CompetenceSearchListComponent implements OnInit {

  @Input() loading: boolean;
  @Input() competenceSearchList: ICompetenceSearch[];

  public competenceStatus = CompetenceStatusEnum;
  public iconColor = COMPETENCE_STATUS_COLOR;
  public dataSource: MatTableDataSource<ICompetenceSearch>;
  public displayedColumns: string[] = [
    'name',
    'email',
    'competencyName',
    'status',
    'arrangementName',
    'toDate'
  ];
  public sortedData: ICompetenceSearch[];
  public sortTypeEnum = SortType;
  public sortType: number = SortType.Asc;
  public sortBy: string ;
  public mobileSortMenuList = [
    { name: 'name', sortType: SortType.Asc, label: 'GENERAL.NAME'},
    { name: 'email', sortType: SortType.Asc, label: 'GENERAL.EMAIL' },
    { name: 'competencyName', sortType: SortType.Asc, label: 'GENERAL.FUNCTION_NAME'},
    { name: 'status', sortType: SortType.Asc, label: 'GENERAL.STATUS'},
    { name: 'arrangementName', sortType: SortType.Asc, label: 'COMPETENCE.ARRANGEMENT_NAME'},
    { name: 'toDate', sortType: SortType.Asc , label: 'GENERAL.To_DATE'},
  ];
  public pagination: IPagination = {
    pageNumber: 0,
    pageSize: 10,
  };

  private asendingSort: boolean = true;

  constructor(
    private router: Router,
    private competenceStructureService: CompetenceStructureService,
    private storage: StorageService,
    private competenceService: CompetenceService,

  ) {}

  ngOnInit() {
    this.sortedData = this.competenceSearchList.slice();
    this.getCompetenceSearchList();
  }

  showWarnIcon(competence: ICompetence) {
    return this.competenceStructureService.showWarnIcon(competence);
  }

  showApprovedIcon(competence: ICompetence) {
    return this.competenceStructureService.showApprovedIcon(competence);
  }

  goToUserProfilePage(data: ICompetenceSearch, index: number) {
    if (!data.userCompetency?.length) {
      return;
    }

    this.router.navigate(['/user-profile', data.userId], { state: { userCompetenceList: data.userCompetency, selectedCompetenceIndex: index }, queryParams: { name: 'userCompetence' } });
  }

  goToProfileWithNoPopup(data: ICompetenceSearch) {
    this.router.navigate(['/user-profile', data.userId], { state: { userCompetenceList: data.userCompetency } });
  }

  sortCompetenceList() {
    if(this.asendingSort) {
      this.asendingSort = false;
      this.competenceSearchList.sort((a,b)=> (a.name > b.name ? 1 : -1));
      this.dataSource = new MatTableDataSource(this.competenceSearchList);

    } else {
      this.asendingSort = true;
      this.competenceSearchList.sort((a,b)=> (a.name < b.name ? 1 : -1));
      this.dataSource = new MatTableDataSource(this.competenceSearchList);
    }
  }

  sortAscMobileview(type, index) {
    this.mobileSortMenuList.forEach((item, i)=>{
      item.sortType = (i === index) ? SortType.Desc : SortType.Asc;
    });
    this.getSortTable(type, index);
  }

  sortDescMobileview(type, index) {
    this.mobileSortMenuList.forEach((item, i)=> {
      if(i == index){
        item.sortType = 1;
      } 
    });
    this.getSortTable(type, index);
  }

  getSortTable(type, index) {
    this.sortType = this.mobileSortMenuList[index].sortType;
    this.sortBy = type;
    
    console.log(this.mobileSortMenuList[index])
    this.getCompetenceSearchList();
  }

  sortData(sort: Sort) {
    const data = this.competenceSearchList.slice();
    if (!sort.active || sort.direction === '') {
      this.sortedData = data;
      return;
    }
    this.sortType = sort.direction === 'asc'? SortType.Asc : SortType.Desc;
    this.sortBy = sort.active;
    this.getCompetenceSearchList();
  }
  
  async getCompetenceSearchList() {
    try {
      this.loading = true;
      const competenceSearchFilterData= this.storage.getItem(StorageEnum.CompetenceSearchFilters);
      competenceSearchFilterData.sortType = this.sortType;
      competenceSearchFilterData.sortBy = this.sortBy;
      const params = {
        pageNumber: this.pagination.pageNumber,
        pageSize: this.pagination.pageSize,
        userId: -1,
      };

      if(!competenceSearchFilterData.isExpiryAfterDate) {
        competenceSearchFilterData.expireAfterDate = '';
      }

      const data = await this.competenceService.getCompetenceSearchList(competenceSearchFilterData, params);
      this.competenceSearchList = data.result;
      this.pagination = { ...this.pagination, ...data.pageInfo };

      this.dataSource = new MatTableDataSource(this.competenceSearchList);
    } catch (error) {}
    finally {
      this.loading = false;
    }
  }

  handlePageEvent(e: PageEvent) {
    this.pagination.pageNumber = e.pageIndex;
    this.pagination.pageSize = e.pageSize;
    this.getCompetenceSearchList();
  }
}
