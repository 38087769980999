import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { AbstractControl, FormControl } from '@angular/forms';
// import * as moment from 'moment';
import { Moment } from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

export const MY_DATE_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  },
};

@Component({
  selector: 'mb-date-month-picker',
  templateUrl: './date-month-picker.component.html',
  styleUrls: ['./date-month-picker.component.scss'],
  // providers: [CUSTOM_DATE_MONTH_PICKER_FORMAT_PROVIDER],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_DATE_FORMATS }
  ]
})
export class DateMonthPickerComponent implements OnChanges, OnDestroy {

  @Input() dateFormControl: FormControl;

  @Input() label: string;
  @Input() matLabel: string;
  @Input() placeholder: string;
  @Input() isDisabled: boolean;

  public selectedDate: Date;
  private translateSubscription: Subscription;
  public showError = false;

  constructor(
    private dateAdapter: DateAdapter<Date>,
    private changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,
  ) { }

  ngOnChanges() {
    if (this.dateFormControl.value) {
      this.selectedDate = this.dateFormControl.value;
    }
    this.dateAdapter.setLocale(this.translateService.currentLang);
    this.translateSubscription = this.translateService.onLangChange.subscribe(({lang}) => {
      this.dateAdapter.setLocale(lang);
      this.changeDetectorRef.detectChanges();
    });
  }

  isFieldDisabled() {
    return this.dateFormControl && this.dateFormControl.disabled || this.isDisabled;
  }

  onDateChange(dateInput: MatDatepickerInputEvent<any>) {
    const date: Moment = dateInput.value;
    this.selectedDate = date.toDate();
    this.dateFormControl.patchValue(date.format('yyyy-MM-DDThh:mm:ss'));
  }

  showErrorMsg(control: AbstractControl) {
    return control['errorMessage'] && control.touched;
  }

  ngOnDestroy() {
    this.translateSubscription.unsubscribe();
  }
}
