import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TranslationService } from '@core/services/TranslationService';
import { SessionService } from './auth/session.service';

@Injectable()
export class HttpRequestInterceptor implements HttpInterceptor {

  constructor(
    private sessionService: SessionService,
    private translationService: TranslationService,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.interceptRequest(req, next));
  }

  async interceptRequest(req: HttpRequest<any>, next: HttpHandler) {
    const accessToken = this.sessionService.getAccessToken();
    const language = this.translationService.getCurrentLanguage();
    
    req = req.clone({
      headers: req.headers.set('Accept-Language', `${language}`)
    });      
    
    if (accessToken) {
      const headers = req.headers.get('authorization');
      if (!headers) {
        req = req.clone({
          setHeaders: {
            'Authorization': `Bearer ${accessToken}`,
          },
          body: req.body,
          // body: this.modifyRequestBody(req.body),
        });
      }
    }

    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.handleError(httpError);
        return throwError(httpError);
      })).toPromise();
  }

  async handleError(httpError: HttpErrorResponse) {
    if (!navigator.onLine) {
    } else if (httpError.status === 401) {
      await this.sessionService.logout();
    } else if (httpError.status === 500) {
      // this.snackBar.open('GENERAL.INTERNAL_SERVER');
      await this.sessionService.logout();
    } else if (httpError.status === 419) {
      // this.snackBar.open('SIGN_IN.USER_BLOCKED');
      // this.snackBar.afterDismissed().subscribe(async () => await this.sessionService.logout());
    } else if (httpError.status === 403) {
      // this.snackBar.open('GENERAL.NO_PERMISSION');
      // this.snackBar.afterDismissed().subscribe(() => this.sessionService.navigateToDashboard());
    } else if (httpError.status === 0) {
      // this.snackBar.open('GENERAL.UNKNOWN_ERROR');
    } else if (httpError?.error) {
      const message = httpError.error?.errors ? Object.values(httpError.error?.errors)[0].toString() :
      httpError.error?.exceptionMessage || httpError?.message;
      // this.snackBar.open(message);
      return message;
    }
  }

  // private modifyRequestBody(body: any) {
  //   return this.translationService.getCurrentLanguage() !== languages.en.code ?
  //     CustomHelper.convertCommaToDot(body, [], false) : body;
  // }
}
