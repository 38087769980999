import { NgModule } from '@angular/core';
import { ComponentsModule } from '@components/components.module';
import { CoreModule } from '@core/core.module';
import { AddGroupModalComponent } from './component/add-group-modal/add-group-modal.component';
import { ProfileGroupComponent } from './component/profile-group/profile-group.component';
import { ProfileInfoComponent } from './component/profile-info/profile-info.component';
import { GDPRModalComponent } from './component/gdpr-modal/gdpr-modal.component'

@NgModule({
  declarations: [
    ProfileInfoComponent,
    ProfileGroupComponent,
    AddGroupModalComponent,
    GDPRModalComponent,
  ],
  imports: [
    CoreModule,
    ComponentsModule,
  ],
  exports: [
    ProfileInfoComponent,
    ProfileGroupComponent,
    AddGroupModalComponent,
    GDPRModalComponent,
  ],
})

export class ProfileModule {
}
