<div *ngIf="!inputFormControl">
  <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="infoIconTemplate"></ng-container>

  <mat-form-field [fxFlex]="fieldWidth">
    <input type="text" matInput
           [value]="inputValue"
           [readonly]="isReadOnly"
           [disabled]="isDisabled"
           autocomplete="off">
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
  </mat-form-field>
</div>

<div *ngIf="inputFormControl">
  <ng-container *ngTemplateOutlet="labelTemplate"></ng-container>
  <ng-container *ngTemplateOutlet="infoIconTemplate"></ng-container>

  <mat-form-field [fxFlex]="fieldWidth">
    <mat-label *ngIf="matLabel">{{ matLabel | translate }}</mat-label>
    <input matInput
           [type]="inputType"
           [value]="inputValue"
           [formControl]="inputFormControl"
           [placeholder]="placeholder | translate"
           [readonly]="isReadOnly"
           [attr.disabled]="isDisabled"
           autocomplete="off">
    <textarea matInput *ngIf="isTextAreaInput" [rows]="rowSize" [formControl]="inputFormControl"></textarea>
    <mat-hint *ngIf="hint">{{ hint | translate }}</mat-hint>
    <mat-icon *ngIf="iconName" class="mat-button-no-color" matPrefix>{{ iconName }}</mat-icon>
    <mat-error *ngIf="showErrorMsg(inputFormControl)" class="text-danger support-text">{{inputFormControl['errorMessage'] | translate}}</mat-error>
  </mat-form-field>
</div>

<ng-template #labelTemplate>
  <label [fxFlex]="labelWidth"
         *ngIf="label"
         [innerHTML]="label | translate"
         [class.disableText]="isLabelDisabled()"></label>
</ng-template>

<ng-template #infoIconTemplate>
  <mat-icon *ngIf="iconToolTipText"
            [matTooltip]="iconToolTipText | translate"
            [class.pointer-none]="isLabelDisabled()"
            class="info-btn">info
  </mat-icon>
</ng-template>
