<ng-container *ngFor="let contact of contacts; let i = index">
  <div fxLayoutAlign="space-between center" class="user-info-section">
    <div fxLayoutAlign="start center" (click)="selectContact(contact)">
      <img [src]="getProfileImage(contact.fileUrl)" class="mat-card-avatar img-circle">
      <h4 class="m-0 name">{{contact.name}}</h4>
    </div>
    <div fxLayoutAlign="start center" fxLayoutGap="1em">
      <a href="tel:+{{getPhoneNumber(contact)}}">
        <button mat-mini-fab mat-card-icon class="shadow-none contact-btn">
          <mat-icon>call</mat-icon>
        </button>
      </a>
      <a href="mailto:{{contact.email}}">
        <button mat-mini-fab mat-card-icon class="shadow-none mail-btn">
          <mat-icon>mail_outline</mat-icon>
        </button>
      </a>
    </div>
  </div>
</ng-container>