import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { compare, minLength, pattern, required, RxFormBuilder } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'mb-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent implements OnInit {

  @Input() loading = false;

  @Output() formSubmitEventEmitter = new EventEmitter<FormGroup>();

  public form: FormGroup;

  constructor(
    private rxFormBuilder: RxFormBuilder,
  ) { }

  ngOnInit() {
    this.form = this.rxFormBuilder.formGroup(new ChangePasswordModel());
  }

  isRequiredError() {
    return this.form.get('password').hasError('required');
  }

  isRequiredConfirmPwdError() {
    return this.form.get('confirmPassword').hasError('required') && this.form.get('confirmPassword').touched;
  }

  isConfirmPasswordError() {
    return (this.form.get('password').valid &&
    this.form.get('confirmPassword').value.length >= 4 && 
    this.form.get('confirmPassword').hasError('compare'));
  }

  get isPasswordMinLengthError() {
    return this.form.get('password').hasError('minLength') && !this.isRequiredError();
  }

  get isPasswordRegexError() {
    return this.form.get('password').invalid
      && !this.form.get('password').hasError('minLength')
      && !this.isRequiredError();
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    const password = this.form.get('password').value;
    this.form.patchValue({ password: password, confirmPassword: password });
    this.form.get('password').setErrors(null);
    this.form.get('confirmPassword').setErrors(null);
    this.formSubmitEventEmitter.emit(this.form);
  }
}

export class ChangePasswordModel {
  @required()
  @minLength({ value: 8 })
  @pattern({ expression: { 'password': /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/ } })
  password = '';

  @required()
  @compare({ fieldName: 'password' })
  confirmPassword = '';
}
