
import { TranslateLoader } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { from } from 'rxjs';
/**
 * TranslationLoader override the existing class to implement custom functionality.
 */
export class TranslationLoader implements TranslateLoader {
  constructor(private http: HttpClient) { }

  getTranslation(language: string): Observable<any> {
    return from(this.http.get(`https://cdn.simplelocalize.io/6544831e3fb34148ab6323e003593a57/_latest/${language}`));
  }

}
